import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

// Import layout here
import DefaultLayout from 'layouts/DefaultLayout';
import AppLayout from 'layouts/AppLayout';

// Import pages component here
import Error404 from 'pages/404';
import SignIn from 'pages/sign-in';
import Home from 'pages/home';
import AllAround from 'pages/appreciation/all-around';
import ShiningStar from 'pages/appreciation/shining-star';
import Profile from 'pages/profile';
import CardSent from 'pages/card/sent';
import CardReceived from 'pages/card/received';
import CardPending from 'pages/card/pending';
import CardDetails from 'pages/card/details';
import Employee from 'pages/employee';
import DataSummary from 'pages/data-summary';
import Category from 'pages/category';
import Backup from 'pages/backup';
import Config from 'pages/config';
import Notification from 'pages/notification';

// Global stylesheet
import 'assets/css/style.css';
import 'assets/css/custom.css';

// Define pages component routing here
const defaultLayout = [
  {
    path: '/sign-in',
    title: 'SignIn',
    exact: true,
    Component: SignIn
  }
];
const appLayout = [
  {
    path: '/',
    title: 'Home',
    exact: true,
    Component: Home,
  },
  {
    path: '/profile',
    title: 'Profile',
    exact: true,
    Component: Profile,
  },
  {
    path: '/appreciation/all-around',
    title: 'All-Around',
    exact: true,
    Component: AllAround,
  },
  {
    path: '/appreciation/shining-star',
    title: 'Shining Star',
    exact: true,
    Component: ShiningStar,
  },
  {
    path: '/card/sent',
    title: 'Card Sent',
    exact: true,
    Component: CardSent
  },
  {
    path: '/card/received',
    title: 'Card Received',
    exact: true,
    Component: CardReceived
  },
  {
    path: '/card/pending',
    title: 'Card Pending',
    exact: true,
    Component: CardPending
  },
  {
    path: '/card/details/:id',
    title: 'Card Details',
    exact: true,
    Component: CardDetails
  },
  {
    path: '/employee',
    title: 'Employee',
    exact: true,
    Component: Employee
  },
  {
    path: '/summary',
    title: 'Data Summary',
    exact: true,
    Component: DataSummary
  },
  {
    path: '/Category',
    title: 'Category',
    exact: true,
    Component: Category
  },
  {
    path: '/backup',
    title: 'Backup',
    exact: true,
    Component: Backup
  },
  {
    path: '/config',
    title: 'Config',
    exact: true,
    Component: Config
  },
  {
    path: '/notification',
    title: 'Notification',
    exact: true,
    Component: Notification
  },
];

class Layouts extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {
            defaultLayout.map((route, index) => {
              const { Component, path, exact } = route;

              return (
                <Route
                  key={index}
                  path={path}
                  exact={exact}
                  render={props => (
                    <DefaultLayout {...props}>
                      <Component {...props} />
                    </DefaultLayout>
                  )}
                />
              );
            })
          }
          {
            appLayout.map((route, index) => {
              const { Component, path, exact } = route;

              return (
                <Route
                  key={index}
                  path={path}
                  exact={exact}
                  render={(props) => (
                    <AppLayout {...props}>
                      <Component {...props} />
                    </AppLayout>
                  )}
                />
              );
            })
          }
          <Route component={Error404}/>
        </Switch>
      </Router>
    );
  }
}

export default Layouts;
