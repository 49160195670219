import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AppDrawer from '../drawers/app-drawer';

import styles from './styles';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
  }
  listenScrollEvent = () => {
    if (window.scrollY > 100) {
      this.setState({cssBtn: 'onscroll',color:'blue'});
      // console.log('scrolling',window.scrollY);
    } else {
      this.setState({cssBtn: '',color:''});
    }
  }

  componentDidMount=()=> {
    window.addEventListener('scroll', this.listenScrollEvent);
  }
  componentWillUnmount=()=>{
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  handleToggleDrawer = () => {
    this.setState((prevState) => ({
      drawerOpen: !prevState.drawerOpen
    }));
  }

  render() {
    const { classes, location } = this.props;
    const { pathname } = location;
    const { drawerOpen } = this.state;

    const buttonColor = () => {
      if (pathname === '/profile') return 'secondary';
      return 'primary';
    };

    return (
      <div className={classes.root}>
        <AppBar className={classes.header}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.icon}>
              <IconButton
                onClick={this.handleToggleDrawer}
                color={buttonColor()}
                aria-label="Menu"
                className={`anim ${this.state.cssBtn}`}
              >
                <MenuIcon className={this.state.color} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <AppDrawer
          open={drawerOpen}
          toggleDrawer={this.handleToggleDrawer}
        />
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default withStyles(styles)(withRouter(Header));
