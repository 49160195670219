import React from 'react';

const Error404 = () => {
  return (
    <div>
      <h3>Error 404 - Page not found.</h3>
    </div>
  );
};

export default Error404;