import { bindActionCreators } from 'redux';
import {
  getHistoryList,
  getAwardCount,
} from 'store/award/actions';

export default dispatch => bindActionCreators({
  getHistoryList,
  getAwardCount,
}, dispatch);
