const styles = theme => ({
  container: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 0,
  },
  header: {
    textAlign: 'center',
    padding: '.6rem',
    '& img': {
      margin: '.4rem',
      width: 30,
      height: 30,
    },
    '& h3': {
      margin: '.5rem',
      color: theme.palette.primary.main,
    },
    '& p': {
      margin: 0,
      fontSize: '.8rem',
    },
    '& span': {
      fontWeight: 600,
    }
  },
  headerLogo: {
    width: '60px !important',
    height: '60px !important',
  },
  personAva: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h5': {
      margin: 0,
      fontSize: '1.2rem',
    },
    '& img': {
      borderRadius: '50%',
      objectFit: 'cover',
    }
  },
  content: {
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '120px',
    overflow: 'hidden',
    overflowY: 'auto',
    '& h4': {
      fontWeight: 600,
      color: theme.palette.text.dark,
    },
    '& p': {
      textAlign: 'left',
      fontWeight: 400,
      color: theme.palette.text.dark,
      fontSize: '.8rem',
    }
  },
  formControl: {
    margin: '.6rem 0',
    '& h5': {
      margin: 0,
      marginBottom: '.5rem',
      fontWeight: 600,
      color: theme.palette.text.dark,
    },
    '& p': {
      margin: '0 1rem',
    }
  },
  actions: {
    backgroundColor: theme.palette.secondary.main,
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'fixed',
    bottom: '0',
    width: '100%',
  },
});

export default styles;
