import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BottomDrawer, CustomButton } from 'components/';
import DeletePrompt from 'components/alerts/prompt';
import Input from 'components/inputs/custom-input';
import TextArea from 'components/inputs/base-text-area';
import InlineSelect from 'components/inputs/base-inline-select';
import ClosePrompt from 'components/alerts/prompt';

import {
  UpdateCategory,
  DeleteCategory,
  Category,
  GetCategoryAward,
} from 'store/category/actions';
import {
  showSuccessAlert,
  showErrorAlert,
  showLoadingProcess,
  closeLoadingProcess,
} from 'store/commons/actions';

class HomeDrawerEditCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idCategory: null,
      inputName: '',
      inputDescription: '',
      inputStatus: '',

      isFetching: false,
      isClickAble: true,
      isErrorName: false,
      isErrorDescription: false,
      isErrorStatus: false,
      showDeletePrompt: false,
      showClosePrompt: false,

      statusList: [
        { value: 'active', name: 'Active' },
        { value: 'inactive', name: 'Inactive' },
      ],
    };
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.tempData !== this.props.tempData) {
      this.setState({
        idCategory: nextProps.tempData.id,
        inputName: nextProps.tempData.name,
        inputDescription: nextProps.tempData.description,
        inputStatus: nextProps.tempData.status,
        isErrorName: false,
        isErrorDescription: false,
      });
    }
  };

  handleSubmit = () => {
    const {
      userToken,
      tempData,
      showErrorAlert,
      showSuccessAlert,
      showLoadingProcess,
      closeLoadingProcess,
    } = this.props;
    const {
      isErrorName,
      isErrorDescription,
      isErrorStatus,
      inputName,
      inputDescription,
      inputStatus,
      idCategory,
    } = this.state;

    this.checkReadySend(() => {
      if (!isErrorName && !isErrorDescription && !isErrorStatus) {
        this.setState({
          isErrorUploudFile: false,
          errorText: null,
        });
        showLoadingProcess();

        UpdateCategory(
          {
            token: userToken,
            data: {
              name: inputName,
              description: inputDescription,
              award_type_id: tempData.award_type_id,
              status: inputStatus,
              id: idCategory,
            },
          },
          ({ data, isFound, isError }) => {
            if (isFound) {
              closeLoadingProcess();
              showSuccessAlert('Category has successfully updated');
              this.onGetCategory();
            } else if (isError) {
              closeLoadingProcess();
              showErrorAlert('There is something error during the process!');
              this.setState({
                errorText: data.message,
              });
            }
          }
        );
      }
    });
  };

  checkReadySend = callback => {
    const { inputName, inputDescription, inputStatus } = this.state;

    this.setState(
      {
        isErrorName: inputName.length >= 3 ? false : true,
        isErrorDescription: inputDescription.length >= 3 ? false : true,
        isErrorStatus: inputStatus.length ? false : true,
      },
      () => {
        callback(true);
      }
    );
  };

  onGetCategory = () => {
    const { userToken, Category, showErrorAlert } = this.props;

    GetCategoryAward(
      {
        token: userToken,
        limit: 10,
        page: 1,
        keyword: '',
      },
      ({ data, isError, isFound, isFetching }) => {
        if (isFound) {
          this.setState({ isFetchUploudFile: false });
          Category({
            data: data.data,
            isFetching,
            isFound,
            isError,
          }).then(() => {
            this.handleClose(true);
          });
        } else if (isError) {
          showErrorAlert('There is something error during the process!');
        }
      }
    );
  };

  onCLosed = () => {
    this.setState({
      isReadeyUploudFile: false,
      isErrorUploudFile: false,
      filePath: '',
      isFetchUploudFile: false,
    });
  };

  handleClose = isTrue => {
    if (isTrue) this.props.onCLosed(false);
    this.setState({ showClosePrompt: false });
  };

  onInputName = e => {
    if (e.target.value.length > 3) {
      this.setState({
        inputName: e.target.value,
        isErrorName: false,
      });
    } else {
      this.setState({
        inputName: e.target.value,
        isErrorName: true,
      });
    }
  };

  onInputDescription = e => {
    if (e.target.value.length > 3) {
      this.setState({
        inputDescription: e.target.value,
        isErrorDescription: false,
      });
    } else {
      this.setState({
        inputDescription: e.target.value,
        isErrorDescription: true,
      });
    }
  };

  onInputStatus = e => {
    if (e.target.value) {
      this.setState({
        inputStatus: e.target.value,
        isErrorStatus: false,
      });
    } else {
      this.setState({
        inputStatus: e.target.value,
        isErrorStatus: true,
      });
    }
  };

  handleDelete = isTrue => {
    const {
      userToken,
      tempData,
      showErrorAlert,
      showSuccessAlert,
      showLoadingProcess,
      closeLoadingProcess,
    } = this.props;

    if (isTrue) {
      showLoadingProcess();
      DeleteCategory(
        {
          token: userToken,
          data: {
            id: tempData.id,
          },
        },
        res => {
          if (res.isFound) {
            closeLoadingProcess();
            showSuccessAlert('Category has been deleted successfully!');
            this.setState({ showDeletePrompt: false });
            this.onGetCategory();
          } else if (res.isError) {
            showErrorAlert('Something wrong happen!');
          }
        }
      );
    } else {
      this.setState({ showDeletePrompt: false });
    }
  };

  render() {
    const { isOpen, tempData } = this.props;
    const {
      inputName,
      inputDescription,
      inputStatus,
      showDeletePrompt,
      isErrorName,
      isErrorDescription,
      isErrorStatus,
      showClosePrompt,
    } = this.state;

    return (
      <BottomDrawer
        fullHeight={true}
        openDialog={isOpen}
        toggleDrawer={() => this.setState({ showClosePrompt: true })}
      >
        <div className="dFlex justSpace alignCenter dirColumn categoryModal bgBlue">
          <ClosePrompt
            className="marB-20"
            open={showClosePrompt}
            toggleDialog={val => this.handleClose(val)}
            text="Are you sure want to cancel?"
            subText="(All draft will be lost)"
          />

          <div className="categoryModalHeader bgBlue">
            <h3 className="white">{tempData.name}</h3>
          </div>
          <div className="bgWhite categoryModalContent">
            <div className="gray categoryModaltextInfo">
              Fill this following field to edit this All-Around Appreciation
              category
            </div>

            <div className="mb-12">
              <div className="imgViewTitle">Category Name</div>
              <Input
                placeholder="Category Name"
                value={inputName}
                onChange={this.onInputName}
                fullWidth={true}
              />
              {isErrorName && (
                <div className="red inputErrorText">
                  You must fill category name , minimal 3 characters!
                </div>
              )}
            </div>

            <div className="mb-12">
              <div className="imgViewTitle">Description</div>
              <TextArea
                id="inputDescription"
                name="inputDescription"
                placeholder="Description"
                value={inputDescription}
                onChange={this.onInputDescription}
                rows={6}
                maxLength={1000}
              />
              {isErrorDescription && (
                <div className="red inputErrorText">
                  You must fill description, minimal 3 characters!
                </div>
              )}
            </div>

            <div>
              <InlineSelect
                name="select-status"
                label="Status"
                value={inputStatus}
                onChange={this.onInputStatus}
                options={this.state.statusList}
              />
              {isErrorStatus && (
                <div className="red inputErrorText">
                  You must select category status!
                </div>
              )}
            </div>

            <div className="pad-5 marT-10">
              <div className="dFlex justCenter alignCenter marT-20">
                <div className="dFlex dirColumn" style={{ width: '100%' }}>
                  <DeletePrompt
                    className="marB-20"
                    open={showDeletePrompt}
                    toggleDialog={val => this.handleDelete(val)}
                    text="Are you sure want to delete this category?"
                  />

                  {!tempData.is_used && (
                    <CustomButton
                      title="Delete"
                      className="categoryModalButton2__delete"
                      onClick={() => this.setState({ showDeletePrompt: true })}
                    />
                  )}

                  <CustomButton
                    title="Save"
                    className="categoryModalButton2 marB-20"
                    onClick={this.handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </BottomDrawer>
    );
  }
}

HomeDrawerEditCategory.defaultProps = {
  onCLosed: () => null,
  isOpen: false,
  userToken: null,
  tempData: {},
  tempDataAward: {},
};

HomeDrawerEditCategory.propTypes = {
  userToken: PropTypes.string,
  tempData: PropTypes.object,
  showErrorAlert: PropTypes.func,
  showSuccessAlert: PropTypes.func,
  showLoadingProcess: PropTypes.func,
  closeLoadingProcess: PropTypes.func,
  onCLosed: PropTypes.func,
  isOpen: PropTypes.bool,
  Category: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  Category: data => dispatch(Category(data)),
  showSuccessAlert: msg => dispatch(showSuccessAlert(msg)),
  showErrorAlert: msg => dispatch(showErrorAlert(msg)),
  showLoadingProcess: () => dispatch(showLoadingProcess()),
  closeLoadingProcess: () => dispatch(closeLoadingProcess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeDrawerEditCategory);
