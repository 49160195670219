import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import {BottomDrawer, CustomButton} from 'components/';
import ClosePrompt from 'components/alerts/prompt';
import Input from 'components/inputs/custom-input';
import TextArea from 'components/inputs/base-text-area';

class HomeDrawerEditMilestone extends Component {
  constructor(props){
    super(props);
    this.state={
      id: 0,
      years: '',
      content: '',
      isActive: true,
      showClosePrompt: false,
      isErrorDescription: false,
      isErrorYears: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps && nextProps.isOpen) {
      const {content, id, is_active, years} = nextProps.isOpen;

      this.setState({
        id: id,
        years: years,
        content: content,
        isActive: is_active === 1? true: false,
      });
    }
  }

  handleClose = isTrue => {
    if (isTrue) this.props.onCLosed(false);
    this.setState({ showClosePrompt: false });
  };

  onInputDescription = e => {
    if (e.target.value.length > 3) {
      this.setState({
        content: e.target.value,
        isErrorDescription: false,
      });
    } else {
      this.setState({
        content: e.target.value,
        isErrorDescription: true,
      });
    }
  };

  onInputYears = e => {
    if(e.target.value) {
      this.setState({
        years: e.target.value,
        isErrorYears: false
      });
    } else {
      this.setState({
        years: e.target.value,
        isErrorYears: true
      });
    }
  }

  render() {
    const {isOpen, onSave} = this.props;
    const {isActive,years,content,showClosePrompt,isErrorYears,isErrorDescription,id} = this.state;

    return (
      <BottomDrawer
        fullHeight={true}
        openDialog={isOpen}
        toggleDrawer={() => this.setState({ showClosePrompt: true })}
      >
        <div className="dFlex justSpace alignCenter dirColumn categoryModal bgBlue">
          <ClosePrompt
            className="marB-20"
            open={showClosePrompt}
            toggleDialog={val => this.handleClose(val)}
            text="Are you sure want to cancel?"
            subText="(All draft will be lost)"
          />
  
          <div className="categoryModalHeader bgBlue">
            <h3 className="white">Edit Milestone</h3>
          </div>
  
          <div className="bgWhite categoryModalContent">
            <div className="gray categoryModaltextInfo">
              Fill this following field to edit this Milestone
            </div>
  
            <div className="mb-12">
              <div className="imgViewTitle">Years</div>
              <Input
                placeholder="1"
                value={years}
                onChange={this.onInputYears}
                fullWidth={true}
                disabled
              />
              {isErrorYears && (
                <div className="red inputErrorText">
                  You must fill field Years!.
                </div>
              )}
            </div>
  
            <div className="mb-12">
              <div className="imgViewTitle">Body Content</div>
              <TextArea
                id="inputDescription"
                name="inputDescription"
                placeholder="Body Content"
                value={content}
                onChange={this.onInputDescription}
                rows={6}
                maxLength={300}
              />
              {isErrorDescription && (
                <div className="red inputErrorText">
                  You must fill category name , minimal 3 characters and maximal 300 characters!
                </div>
              )}
            </div>
  
            <div className="mb-12 dFlex alignCenter">
              <div className="imgViewTitle">Is Active</div>
              <Switch color="primary"
                checked={isActive}
                onChange={(event)=>this.setState({isActive: event.target.checked})}
              />
            </div>
  
            <CustomButton
              title="Save"
              className="categoryModalButton2 marB-20"
              onClick={()=>{
                if(!years) this.setState({isErrorYears: true});
                if(content.length<3) this.setState({isErrorDescription: true});
                
                if(years && content.length >= 3) {
                  onSave({
                    id: Number(id),
                    years: Number(years),
                    content,
                    is_active: isActive ? 1 : 0,
                    _method: 'put'
                  });
                }
              }}
            />
          </div>
        </div>
      </BottomDrawer>
    );
  } 
}

HomeDrawerEditMilestone.defaultProps = {
  onCLosed: ()=> null,
  isOpen: false,
  onSave: ()=>null,
};

HomeDrawerEditMilestone.propTypes = {
  onCLosed: PropTypes.func,
  isOpen: PropTypes.object,
  onSave: PropTypes.func,
};

export default HomeDrawerEditMilestone;
