import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { format, addDays } from 'date-fns';

import LoadingEllipsis from 'components/loadings/loading-ellipsis';
import BaseButton from 'components/buttons/base-button/BaseButton';

import dispatchers from './dispatchers';
import states from './states';
import styles from './styles';

class Backup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    this.handleFetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.id !== prevProps.profile.id) {
      this.handleUserAuth();
    }
  }

  handleUserAuth() {
    const { history, profile } = this.props;
    const privilege = ['recognition_team'];
    if (!privilege.includes(profile.role)) return history.push('/');
  }

  handleFetchData = () => {
    const { getListBackup } = this.props;
    const { page } = this.state;

    getListBackup({
      isLoadMore: (page > 1),
      page,
    });
    this.setState(prevState => ({
      page: prevState.page + 1
    }));
  }

  render() {
    const {
      classes,
      isLoading,
      isLoadingMore,
      listBackup,
    } = this.props;

    const itemName = item => {
      switch (item.award_type) {
      case 'gem': return 'Shining Star';
      case 'tca': return 'All-Around';
      case 'milestone': return 'Milestone';
      default: return item.filename;
      }
    };

    const periodFormat = item => {
      const { executed_date } = item;
      const monthStart = format(addDays(executed_date, -14), 'MMM D');
      const monthEnd = format(executed_date, 'MMM D');
      const year = format(executed_date, 'YYYY');

      return `${monthStart} - ${monthEnd} ${year}`;
    };

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.header}>
            <h3>Backup Data</h3>
          </div>

          <div className={classes.content}>
            { isLoading && <LoadingEllipsis /> }
            { !isLoading &&
              <div className={classes.contentList}>
                { 
                  listBackup.data.map((item, index) => (
                    <div style={{ width: '100%' }} key={index}>
                      <div className={classes.card}>
                        <div className={classes.cardDetail}>
                          <h5>{itemName(item)} Appreciation</h5>
                          <p>Periods: {periodFormat(item)}</p>
                          <p>Backed up on: {format(item.executed_date, 'MMM D, YYYY')}</p>
                        </div>
                        <div className={classes.cardActions}>
                          <a
                            title={item.file_name}
                            href={item.full_path}
                            rel="noopener noreferrer"
                          >
                            <BaseButton label="Backup" size="small" />
                          </a>
                        </div>
                      </div>
                      { (index !== listBackup.data.length - 1) &&
                        <hr style={{ margin: '8px 12px 0px 12px', opacity: .2 }}/> 
                      }
                    </div>
                  ))
                }
              </div>
            }
          </div>

          <div style={{ marginBottom: '2rem' }}>
            { isLoadingMore && <LoadingEllipsis /> }
            { !isLoading && !isLoadingMore && listBackup.next_page_url &&
              <BaseButton
                label="Load more"
                onClick={this.handleFetchData}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

Backup.propTypes = {
  classes: PropTypes.object.isRequired,
  getListBackup: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  listBackup: PropTypes.object,
  history: PropTypes.object,
  profile: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers)
)(Backup);
