import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = () => ({
  root: {
    margin: '.4rem 0',
    border: '1px solid #D5D5D5',
    padding: '6px 10px',
    borderRadius: 2,
    '& input': {
      fontSize: '.9rem',
    }
  }
});

const BaseInput = props => {
  const {
    classes,
    fullWidth,
    placeholder,
    value,
    onChange,
  } = props;

  return (
    <TextField
      className={classes.root}
      fullWidth={fullWidth}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      InputProps={{ disableUnderline: true }}
    />
  );
};

BaseInput.propTypes = {
  classes: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default withStyles(styles)(BaseInput);
