import { bindActionCreators } from 'redux';
import {
  getEmployeeCountries,
} from 'store/country/actions';
import {
  getEmployeeDepartments,
} from 'store/department/actions';
import {
  updateEmployee,
} from 'store/employee/actions';

export default dispatch => bindActionCreators({
  getEmployeeCountries,
  getEmployeeDepartments,
  updateEmployee,
}, dispatch);
