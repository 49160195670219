const styles = theme => ({
  container: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 0,
  },
  header: {
    textAlign: 'center',
    padding: '.6rem',
    '& img': {
      margin: '.4rem',
      width: 30,
      height: 30,
    },
    '& h3': {
      margin: '.5rem',
      color: theme.palette.primary.main,
    },
    '& p': {
      margin: 0,
      fontSize: '.8rem',
    },
    '& span': {
      fontWeight: 600,
    }
  },
  headerImg: {
    width: '70px !important',
    height: '70px !important',
  },
  personAva: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h5': {
      margin: 0,
      fontSize: '1.2rem',
    },
    '& img': {
      borderRadius: '50%',
    }
  },
  content: {
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '120px',
    overflow: 'hidden',
    overflowY: 'auto',
    '& h4': {
      fontWeight: 600,
      color: theme.palette.text.dark,
    },
    '& p': {
      // textAlign: 'left',
      fontWeight: 400,
      color: theme.palette.text.dark,
      fontSize: '.8rem',
    }
  },
  formControl: {
    margin: '.6rem 0',
    '& h5': {
      margin: 0,
      marginBottom: '.5rem',
      fontWeight: 600,
      color: theme.palette.text.dark,
    },
    '& h6': {
      margin: 0,
      fontWeight: 400,
      color: theme.palette.text.dark,
      fontSize: '.8rem',
      textTransform: 'capitalize'
    },
  },
  cardMessage: {
    margin: '0 !important',
  },
  categoryItem: {
    margin: 'auto',
    textTransform: 'none',
    background: '#EEEEEE',
    position: 'relative',
    overflowY: 'hidden',
    padding: '.8rem',
    border: 'none',
    borderRadius: 4,
    boxShadow: '2px 4px 10px 0px rgba(0,0,0,0.1)',
    backgroundColor: '#FFF',
    '& h5': {
      margin: 0,
      fontWeight: 500,
      color: theme.palette.title.main,
      position: 'relative',
      zIndex: 1,
      fontFamily: 'Museo Sans'
    },
  },
});

export default styles;
