import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import MenuButton from 'components/buttons/MenuButton';
import MenuButton2 from 'components/buttons/MenuButton2';
import receivedSvg from 'assets/icons/received-overlay.svg';
import sentSvg from 'assets/icons/sent-overlay.svg';

import styles from '../styles';
import dispatchers from '../dispatchers';
import states from '../states';

class ProfileMenu extends Component {
  componentDidMount() {
    const { getAwardCount } = this.props;
    getAwardCount();
  }

  menuPrivilege = (privilege) => {
    const { profile } = this.props;

    if (privilege.includes(profile.role)) return true;
    return false;
  };

  render() {
    const {
      classes,
      totalPending,
      totalReceived,
      totalSent,
    } = this.props;

    return (
      <div className={classes.container}>
        { this.menuPrivilege(['hod', 'ceo']) &&
          <Link to="/card/pending">
            <MenuButton
              fullWidth={true}
              color="primary"
              count={totalPending}
            />
          </Link>
        }

        <div className={classes.buttonGroup}>
          <div className={classes.buttonMenu1}>
            <Link to="/card/received">
              <MenuButton2
                label="Received"
                fullWidth={true}
                count={totalReceived}
                icon={receivedSvg}
              />
            </Link>
          </div>
          <div className={classes.buttonMenu2}>
            <Link to="/card/sent">
              <MenuButton2
                label="Sent"
                fullWidth={true}
                count={totalSent}
                icon={sentSvg}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

ProfileMenu.defaultProps = {
  getHistoryList: () => {},
  getPendingList: () => {},
  getAwardCount: () => {},
  profile: {},
  totalPending: 0,
  totalReceived: 0,
  totalSent: 0,
};

ProfileMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  getHistoryList: PropTypes.func,
  getPendingList: PropTypes.func,
  getAwardCount: PropTypes.func,
  profile: PropTypes.object,
  totalPending: PropTypes.number,
  totalReceived: PropTypes.number,
  totalSent: PropTypes.number,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers),
)(ProfileMenu);
