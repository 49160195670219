import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { format } from 'date-fns';

import BottomDrawer from 'components/drawers/bottom-drawer';

import ssaSvg from 'assets/icons/shining star appreciation.svg';
import aaaSvg from 'assets/icons/all around appreciation.svg';
import milestoneSvg from 'assets/icons/milestone appreciation.svg';
import { imgAsset } from 'helper/function/AssetImage';
import renderHtml from 'helper/renderHtml';

import styles from './styles';

class DataDetailDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgStyle: {}
    };
  }

  handleMessageStyle = (element) => {
    if (element) {
      if (element.clientHeight > 15) {
        this.setState({
          msgStyle: { textAlign: 'left' }
        });
      } else {
        this.setState({
          msgStyle: { textAlign: 'center' }
        });
      }
    }
  }

  render() {
    const {
      classes,
      isOpen,
      toggleDrawer,
      card,
    } = this.props;
    const {
      msgStyle,
    } = this.state;
  
    const personAva = (file) => {
      if (file) return file.full_path; 
      return imgAsset.noImg;
    };
  
    const categoryImg = () => {
      switch (card.award_type) {
      case 'gem': return ssaSvg;
      case 'tca': return aaaSvg;
      case 'milestone': return milestoneSvg;
      default: return ;
      }
    };

    const getStatus = (state) => {
      switch (state) {
      case 'pending': return 'Pending';
      case 'sent': return 'Sent';
      case 'rejected': return 'Declined';
      case 'approved': return 'Approved';
      case 'received': return 'Received';
      default: return state;
      }
    };
  
    const isSSA = (card.award_type === 'gem');
    const isMilestone = (card.award_type === 'milestone');
  
    return (
      <BottomDrawer
        fullHeight={true}
        openDialog={isOpen}
        toggleDrawer={toggleDrawer}
      >
        <div className={classes.container}>
          <div className={classes.header}>
            <img className={classes.headerImg} src={categoryImg()} alt="icon" />
            
            { card.sender_detail &&
              <Fragment>
                <p>From
                  <span> {isOpen && card.sender_detail.name} </span> to
                  <span> {isOpen && card.recipient_detail.name}</span>
                </p>
  
                <div className={classes.personAva}>
                  <img
                    src={isOpen ? personAva(card.sender_detail.file) : ''}
                    alt="avatar"
                  />
                  <h5>&rarr;</h5>
                  <img
                    src={isOpen ? personAva(card.recipient_detail.file) : ''}
                    alt="avatar"
                  />
                </div>
              </Fragment>
            }
          </div>
  
          <div className={classes.content}>
            { !isSSA && !isMilestone &&
              <div className={classes.formControl}>
                <h5>Category</h5>
                <div 
                  className={classes.categoryItem}
                  style={{ width: '10rem' }}
                >
                  <h5>{isOpen && card.category_detail && card.category_detail.name}</h5>
                </div>
              </div>
            }
  
            <div className={classes.formControl}>
              <h5>Appreciation</h5>
              { card.award_type === 'milestone' &&
                <p
                  className={classes.cardMessage}
                  ref={this.handleMessageStyle}
                  style={msgStyle}
                  dangerouslySetInnerHTML={renderHtml({ content: card.message })}
                />
              }
              {
                card.award_type !== 'milestone' &&
                <p ref={this.handleMessageStyle} style={msgStyle}>
                  {card.message}
                </p>
              }
            </div>
  
            <div className={classes.formControl}>
              <h5>Status</h5>
              <h6>{getStatus(card.state)}</h6>
              <h6>{format(card.updated_at * 1000, 'MMM DD YYYY | hh:mm A')} (GMT+7)</h6>
            </div>
          </div>
        </div>
      </BottomDrawer>
    );
  }
}

DataDetailDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  card: PropTypes.object,
};

export default withStyles(styles)(DataDetailDrawer);
