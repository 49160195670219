const styles = () => ({
  card: {
    display: 'grid',
    position: 'relative',
    cursor: 'pointer',
    width: '100%',
    '& img': {
      width: '100%',
    },
  },
  headerPrint: {
    top: '16%',
    left: '24%',
    position: 'absolute',
    width: '60%',
    '& h3': {
      margin: 0,
      color: '#ECECEC',
      textTransform: 'uppercase',
      fontFamily: 'Museo Sans',
      fontWeight: 100,
      fontSize: '1.8rem',
      lineHeight: 0.9,
    },
  },
  header: {
    top: '16%',
    left: '24%',
    position: 'absolute',
    width: '60%',
    '& h3': {
      margin: 0,
      color: '#ECECEC',
      textTransform: 'uppercase',
      fontFamily: 'Museo Sans',
      fontWeight: 100,
      fontSize: '1.8rem',
      lineHeight: 0.9,
    },
    '@media screen and (max-width: 360px)': {
      top: '14%',
      '& h3': {
        fontSize: '1.3rem',
      },
    },
    '@media screen and (min-width: 375px) and (max-width: 500px)': {
      top: '15%',
      '& h3': {
        fontSize: '1.6rem',
      },
    },
  },
  contentPrint: {
    color: '#1BA0E2',
    top: '37%',
    left: '9%',
    position: 'absolute',
    width: '85%',
    height: 150,
    '& p': {
      margin: 0,
      fontSize: '.7rem',
      fontFamily: 'Museo Sans',
      fontWeight: 500,
      marginTop: '6px',
    },
  },
  content: {
    color: '#1BA0E2',
    top: '37%',
    left: '9%',
    position: 'absolute',
    width: '85%',
    '& p': {
      margin: 0,
      fontSize: '.7rem',
      fontFamily: 'Museo Sans',
      fontWeight: 500,
      marginTop: '6px',
    },
    '@media screen and (max-width: 360px)': {
      top: '36%',
      '& p': {
        fontSize: '.55rem',
      },
    },
    '@media screen and (min-width: 375px) and (max-width: 500px)': {
      top: '36%',
      '& p': {
        fontSize: '.55rem',
      },
    },
  },
  greetingPrint: {
    marginBottom: '1rem',
  },
  greeting: {
    marginBottom: '1rem',
    '@media screen and (max-width: 360px)': {
      marginBottom: '.6rem',
    },
    '@media screen and (min-width: 375px) and (max-width: 500px)': {
      marginBottom: '.6rem',
    },
  },
  messagePrint: {
    marginBottom: '1rem',
  },
  message: {
    marginBottom: '1rem',
    '@media screen and (max-width: 360px)': {
      marginBottom: '.6rem',
    },
    '@media screen and (min-width: 375px) and (max-width: 500px)': {
      marginBottom: '.6rem',
    },
  },
  messageMilestone: {
    marginBottom: '0.3rem',
    '@media screen and (min-width: 376px) and (max-width: 767px)': {
      marginBottom: '1.8rem',
    },
  },
  signaturePrint: {
    textAlign: 'right',
  },
  signature: {
    textAlign: 'right',
  },
  tcaMessage: {
    color: '#1BA0E2',
    bottom: '20px',
    left: '35px',
    position: 'absolute',
    margin: 0,
    fontSize: '.6rem',
    fontFamily: 'Museo Sans',
    fontWeight: 500,
    fontStyle: 'italic',
    paddingRight: '20px',

    '& b': {
      fontWeight: 700,
      fontStyle: 'italic',
    },
  }
});

export default styles;
