import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import LoadingEllipsis from 'components/loadings/loading-ellipsis';
import dispatchers from './dispatchers';
import states from './states';
import styles from './styles';

const Notification = ({
  classes,
  user,
  getStatusUpdateProfile,
  getListUpdateProfile,
  reviewUpdateProfile,
}) => {
  const [myProposals, setMyProposals] = useState([]);
  const [listProposal, setListProposal] = useState([]);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState({});
  const [isMyProposalLoading, setMyProposalLoading] = useState(false);
  const [isListProposalLoading, setListProposalLoading] = useState(false);

  const { profile } = user;

  const fetchStatusUpdateProfile = async () => {
    setMyProposalLoading(true);
    await getStatusUpdateProfile()
      .then(res => {
        setMyProposals(res.data);
        setMyProposalLoading(false);
        return res;
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchListUpdateProfile = async() => {
    setListProposalLoading(true);
    await getListUpdateProfile()
      .then(res => {
        setListProposal(res.data.data);
        setListProposalLoading(false);
        return res;
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleReviewClick = (proposal) => {
    setSelectedProposal(proposal);
    setReviewModalOpen(true);
  };

  const handleActionReview = (action) => {
    const payload = {
      state: action,
    };
    const fetchReviewUpdateProfile = async () => {
      await reviewUpdateProfile(selectedProposal.id, payload)
        .then(() => {
          setReviewModalOpen(false);
          fetchStatusUpdateProfile();
          fetchListUpdateProfile();
        })
        .catch(err => {
          console.log(err);
        });
    };
    fetchReviewUpdateProfile();
  };

  useEffect(() => {
    fetchStatusUpdateProfile();
    fetchListUpdateProfile();
  }, []);

  const getMessageState = (proposal) => {
    if(!proposal.state){
      return <>Hi {profile.name}, kamu bisa melihat status perubahan profilmu disini.</>;
    }
    if(proposal.state === 'pending'){
      return <>Hi {profile.name}, perubahan profil mu sedang direview oleh recognition team, mohon tunggu notifikasi berikutnya</>;
    }
    if(proposal.state === 'approved'){
      return <>Hi {profile.name}, perubahan profil mu <span style={{ color: 'green' }}>diterima</span> oleh Admin</>;
    }
    if(proposal.state === 'rejected'){
      return <>Hi {profile.name}, perubahan profil mu <span style={{ color: 'red' }}>ditolak</span> oleh Admin</>;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3>Notification</h3>
      </div>
      <div className={classes.container}>
        <h4>My Update Profile Status</h4>
        {isMyProposalLoading && <LoadingEllipsis />}
        {!isMyProposalLoading && myProposals.length > 0 && myProposals.map((myPropose) => 
          <Card key={myPropose.id} className={classes.cardNotif}>
            {getMessageState(myPropose)}
          </Card>
        )}
      </div>
      {profile.role === 'recognition_team' && 
        <div className={classes.container}>
          <h4>Employees Update Profile Status</h4>
          {isListProposalLoading && <LoadingEllipsis />}
          {!isListProposalLoading && <>
            {listProposal.length > 0 && listProposal.map((profileItem) =>
              <Card key={profileItem.id} className={classes.cardNotif}>
              Hi Admin, user <span>{profileItem.name}</span> dengan email <span>{profileItem.email || '-'}</span> sedang mengubah profile nya, 
                <Button color="primary" style={{textTransform: 'none'}} onClick={() => handleReviewClick(profileItem)}>silahkan review dengan klik link disini</Button>
              </Card>
            )}
            {listProposal.length === 0 && 
              <Card className={classes.cardNotif}>
                No proposal updates from other users
              </Card>
            }
          </>}
        </div>
      }
      
      <Modal
        open={reviewModalOpen}
        onClose={() => setReviewModalOpen(false)}
        aria-labelledby="modal-review-label"
        aria-describedby="modal-review-description"
      >
        <div className={classes.modalBox}>
          <div className={classes.reviewContainer}>
            <h4>Review Proposal</h4>
            <p>Name: {selectedProposal.name}</p>
            <p>Email: {selectedProposal.email}</p>
            <div className={classes.modalAction}>
              <Button
                className={classes.rejectBtn}
                variant="outlined"
                onClick={() => handleActionReview('rejected')}
              >Reject</Button>
              <Button
                className={classes.approveBtn}
                variant="outlined"
                onClick={() => handleActionReview('approved')}
              >Approve</Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  getStatusUpdateProfile: PropTypes.func,
  getListUpdateProfile: PropTypes.func,
  reviewUpdateProfile: PropTypes.func,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers),
)(Notification);
