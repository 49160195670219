import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    minWidth: '270px',
    maxWidth: 'calc(512px - 48px)',
    right: 'unset',
    left: 'unset',
    borderRadius: 10,
    overflowY: 'visible',
  },
  btnClose: {
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    minHeight: 0,
    width: 30,
    height: 30,
  },
  btnIcon: {
    color: theme.palette.primary.main,
    position: 'relative',
    top: '3px',
    '& svg': {
      fontSize: 20
    }
  },
});

const BaseDialog = props => {
  const {
    children,
    classes,
    open,
    toggleDialog,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={toggleDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        root: classes.root,
        paper: classes.paper
      }}
    >
      <Fab
        onClick={toggleDialog}
        className={classes.btnClose}
        color="secondary"
        aria-label="Close"
      >
        <div className={classes.btnIcon}>
          <CloseIcon />
        </div>
      </Fab>
      { children }
    </Dialog>
  );
};

BaseDialog.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  toggleDialog: PropTypes.func,
};

export default withStyles(styles)(BaseDialog);