import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  BottomDrawer,
  CustomButton
} from 'components/';
import Input from 'components/inputs/custom-input';
import TextArea from 'components/inputs/base-text-area';
import ClosePrompt from 'components/alerts/prompt';

import {
  AddCategory,
  GetCategoryAward,
  Category
} from 'store/category/actions';
import {
  showSuccessAlert,
  showErrorAlert,
  showLoadingProcess,
  closeLoadingProcess,
} from 'store/commons/actions';

class HomeDrawerAddCategory extends Component {
  constructor(props) {
    super(props);
    this.state =  {
      inputName:'',
      inputDescription:'',
      idCategory:null,
      isFetching:false,
      isClickAble:true,
      showClosePrompt: false,
    };
  }

  handleSubmit = () => {
    this.checkReadySend(()=>{
      if(!this.state.isErrorName && !this.state.isErrorDescription){
        this.setState({
          errorText:null
        });
        this.props.showLoadingProcess();
        AddCategory({
          token:this.props.userToken,
          data:{
            name:this.state.inputName,
            description:this.state.inputDescription,
            award_type_id:this.state.typeId,
            id:this.state.idCategory,
          }
        },({data,isFound,isError,onLoading})=>{
          this.props.closeLoadingProcess();
          if(isFound){
            this.setState({uploudProgressValue:onLoading});
            this.props.showSuccessAlert('A new category has successfully created!');
            this.onGetCategory();
          }else if(isError){
            this.setState({uploudProgressValue:onLoading});
            this.props.showErrorAlert('There\'s something error during the process!');
            this.setState({errorText:data.message});
          }
        });
      }
    });
  }

  checkReadySend=(callback)=>{
    const {state}=this;
    this.setState({
      isErrorName:state.inputName.length>=3?false:true,
      isErrorDescription:state.inputDescription.length>=3?false:true,
    },()=>{
      callback(true);
    });
  }

  onGetCategory=()=>{
    const {
      showErrorAlert,
      Category,
      userToken,
    } = this.props;

    GetCategoryAward({
      token: userToken,
      limit: 10,
      page: 1,
      keyword: '',
    },({data,isError,isFound,isFetching})=>{
      if(isFound){
        this.setState({isFetchUploudFile:false});
        Category({data:data.data,isFetching,isFound,isError}).then(()=>{
          this.handleClose(true);
        });
      }else if(isError){
        showErrorAlert('There\'s something error during the process!');
      }
    });
  }

  onCLosed=()=>{
    this.setState({
      isReadeyUploudFile:false,
      isErrorUploudFile:false,
      filePath:'',
      isFetchUploudFile:false
    });
  }

  handleClose=(isTrue)=>{
    if (isTrue) this.props.onCLosed(false);
    this.setState({ showClosePrompt: false });
  }

  onInputName=(e)=>{
    if (e.target.value.length > 3) {
      this.setState({
        inputName:e.target.value,
        isErrorName:false
      });
    }else{
      this.setState({
        inputName:e.target.value,
        isErrorName:true
      });
    }
  }

  onInputDescription=(e)=>{
    if (e.target.value.length > 3) {
      this.setState({
        inputDescription:e.target.value,
        isErrorDescription:false
      });
    }else{
      this.setState({
        inputDescription:e.target.value,
        isErrorDescription:true
      });
    }
  }

  UNSAFE_componentWillReceiveProps=(nextProps)=>{
    if(nextProps.tempData!==this.props.tempData){
      this.setState({
        inputName:'',
        inputDescription:'',
        isErrorName:false,
        isErrorDescription:false,
        typeId:nextProps.tempData.id,
      });
    }
  }

  render() {
    const { state } = this;

    return (
      <BottomDrawer
        //fullHeight={fullHeight}
        openDialog={this.props.isOpen}
        toggleDrawer={() => this.setState({ showClosePrompt: true })}
      >
        <div className='dFlex justSpace alignCenter dirColumn categoryModal bgBlue'>
          <ClosePrompt
            className='marB-20'
            open={state.showClosePrompt}
            toggleDialog={val => this.handleClose(val)}
            text="Are you sure want to cancel?"
            subText="(All draft will be lost)"
          />

          <div className='categoryModalHeader bgBlue'>
            <h3 className='white'>Add New Category</h3>
          </div>
          <div className='bgWhite categoryModalContent'>
            <div className='gray categoryModaltextInfo'>Fill this following field to add new All-Around Appreciation category</div>
            <div className="mb-12">
              <div className='imgViewTitle'>Category Name</div>
              <Input
                placeholder="Category Name"
                value={state.inputName}
                onChange={this.onInputName}
                fullWidth={true}
              />
              { state.isErrorName &&
                <div className='red inputErrorText'>
                  You must fill category name , minimal 3 characters
                </div>
              }
            </div>

            <div className="mb-12">
              <div className='imgViewTitle'>Description</div>
              <TextArea
                id="inputDescription"
                name="inputDescription"
                placeholder="Description"
                value={state.inputDescription}
                onChange={this.onInputDescription}
                rows={6}
                maxLength={1000}
              />
              { state.isErrorDescription &&
                <div className='red inputErrorText'>
                  You must fill description, minimal 3 characters
                </div>
              }
            </div>

            <div className='pad-5 marT-10'>
              {state.isErrorImage && <div className='red inputErrorText' style={{paddingLeft: 0}}>You pick a background image</div>}
              <div className='dFlex justCenter alignCenter marT-20'>
                <CustomButton
                  title={'Save'}
                  className='categoryModalButton marB-20'
                  onClick={this.handleSubmit }
                />
              </div>

            </div>
          </div>
        </div>
      </BottomDrawer>
    );
  }
}

HomeDrawerAddCategory.defaultProps={
  onCLosed:()=>null,
  isOpen:false,
  idCategory:null,
  userToken:null,
  tempData:{},
};

HomeDrawerAddCategory.propTypes = {
  userToken: PropTypes.string,
  showErrorAlert: PropTypes.func,
  showSuccessAlert: PropTypes.func,
  showLoadingProcess: PropTypes.func,
  closeLoadingProcess: PropTypes.func,
  onCLosed: PropTypes.func,
  tempData: PropTypes.object,
  isOpen: PropTypes.bool,
  Category: PropTypes.func,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  Category: (data) => dispatch(Category(data)),
  showSuccessAlert: (msg) => dispatch(showSuccessAlert(msg)),
  showErrorAlert: (msg) => dispatch(showErrorAlert(msg)),
  showLoadingProcess: () => dispatch(showLoadingProcess()),
  closeLoadingProcess: () => dispatch(closeLoadingProcess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeDrawerAddCategory);
