import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import BaseButton from 'components/buttons/base-button/BaseButton';
import GiveAppreciation from 'components/dialogs/give-appreciation';
import SubmissionSSA from './components/submission-ssa';
import LoadingProcess from 'components/loadings/fullscreen-spin';
import LoadingEllipsis from 'components/loadings/loading-ellipsis';
import ssaSvg from 'assets/icons/shining star appreciation.svg';
import renderHtml from 'helper/renderHtml';

import dispatchers from './dispatchers';
import states from './states';
import styles from './styles';

class ShiningStar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSearch: false,
      openSubmit: false,
      employee: {},
    };
  }

  componentDidMount = () => {
    this.handleUserAuth();
  };

  handleUserAuth() {
    const { history, profile, getAwardTypes } = this.props;
    const privilege = ['lead', 'hod', 'recognition_team', 'ceo'];

    if (!privilege.includes(profile.role)) return history.push('/');
    else getAwardTypes();
  }

  handleToggleDrawer = key => {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }));
  };

  handleSelectEmployee = payload => {
    this.setState(prevState => ({
      employee: payload,
      openSearch: !prevState.openSearch,
      openSubmit: !prevState.openSubmit,
    }));
  };

  handleSubmit = payload => {
    this.props.writeAwardGem(payload);
  };

  render() {
    const { classes, isProcessing, isLoading, awardSSA } = this.props;
    const { openSearch, openSubmit, employee } = this.state;

    return (
      <div className={classes.root}>
        {isProcessing && <LoadingProcess />}

        <div className={classes.container}>
          <div className={classes.header}>
            <h3>Appreciation</h3>
          </div>

          <div className={classes.content}>
            <div className={classes.card}>
              <img src={ssaSvg} alt="icon" />

              {isLoading && (
                <div className="dFlex justCenter alignCenter pad-10">
                  <LoadingEllipsis />
                </div>
              )}
              {!isLoading && (
                <div className={classes.cardContent}>
                  <p
                    dangerouslySetInnerHTML={renderHtml({
                      content: awardSSA.description,
                    })}
                  />
                </div>
              )}

              <BaseButton
                label="Give Appreciation"
                buttonClass={classes.btnYellow}
                labelClass={classes.label}
                color="primary"
                fullWidth={true}
                onClick={() => this.handleToggleDrawer('openSearch')}
              />

              <GiveAppreciation
                openDialog={openSearch}
                toggleDrawer={() => this.handleToggleDrawer('openSearch')}
                onSelect={payload => this.handleSelectEmployee(payload)}
              />
              <SubmissionSSA
                openDialog={openSubmit}
                fullHeight={true}
                toggleDrawer={() => this.handleToggleDrawer('openSubmit')}
                openSearch={() => this.handleToggleDrawer('openSearch')}
                employee={employee}
                onSubmit={payload => this.handleSubmit(payload)}
                maxChar={awardSSA.max_character}
                is_anonymous={awardSSA.is_anonymous}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ShiningStar.defaultProps = {
  getAwardTypes: () => {},
  writeAwardGem: () => {},
  profile: {},
  history: {},
  awardSSA: {},
  isProcessing: false,
  isLoading: false,
};

ShiningStar.propTypes = {
  classes: PropTypes.object.isRequired,
  getAwardTypes: PropTypes.func,
  writeAwardGem: PropTypes.func,
  profile: PropTypes.object,
  history: PropTypes.object,
  awardSSA: PropTypes.object,
  isProcessing: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers)
)(ShiningStar);
