import axios from 'axios';
import { baseUrl } from 'config/api';
import * as commons from '../commons/action.types';

export const getEmployeeCountries = () => {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    return axios.get(`${baseUrl}/api/countries`, {
      headers: {
        Authorization: authStr,
        'Content-Type': 'application/json',
      },
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        dispatch({
          type: commons.SHOW_ERROR_ALERT,
          data: err.response.data.message,
        });
      });
  };
};
