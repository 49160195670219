import React from 'react';
import PropTypes from 'prop-types';

const CategoryFilter = props => {
  const {
    tabValue,
    onClick,
    allCount,
    aaaCount,
    ssaCount,
    milestoneCount,
  } = props;

  const tabClass = (val) => {
    if (val === tabValue) return 'blue';
    return 'gray';
  };

  return (
    <div className="dFlex justCenter alignCenter">
      <div
        className="btn-filter anim text-center"
        onClick={()=>onClick('all')}
      >
        <h3 className="ma-0">{allCount}</h3>
        <p className={`my-4 ${tabClass('all')}`}>All</p>
      </div>

      <div className="summaryLine2" />

      <div
        className="btn-filter anim text-center"
        onClick={()=>onClick('tca')}
      >
        <h3 className="ma-0">{aaaCount}</h3>
        <p className={`my-4 ${tabClass('tca')}`}>All-Around</p>
      </div>

      <div className="summaryLine2" />

      <div
        className="btn-filter anim text-center"
        onClick={()=>onClick('gem')}
      >
        <h3 className="ma-0">{ssaCount}</h3>
        <p className={`my-4 ${tabClass('gem')}`}>Shining Star</p>
      </div>

      <div className="summaryLine2" />

      <div
        className="btn-filter anim text-center"
        onClick={()=>onClick('milestone')}
      >
        <h3 className="ma-0">{milestoneCount}</h3>
        <p className={`my-4 ${tabClass('milestone')}`}>Milestone</p>
      </div>
    </div>
  );
};

CategoryFilter.defaultProps = {
  tabValue: 'all',
  onClick: () => {},
  allCount: 0,
  aaaCount: 0,
  ssaCount: 0,
  milestoneCount: 0,
};

CategoryFilter.propTypes = {
  tabValue: PropTypes.string,
  onClick: PropTypes.func,
  allCount: PropTypes.number,
  aaaCount: PropTypes.number,
  ssaCount: PropTypes.number,
  milestoneCount: PropTypes.number,
};

export default CategoryFilter;
