import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {InputDateBox,SelectBox} from 'components';
import Divider from '@material-ui/core/Divider';

//import { logout } from 'store/user/actions';
import styles from './styles';

class SummaryDrawer extends Component {
  constructor(props){
    super(props);
    this.state={
      dataStatus: props.dataStatus,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    };
  }

  onChecked=(dataStatus)=>{
    this.setState({dataStatus});
  }

  onSetFilter=()=>{
    const {state,props}=this;
    if(props.onSetFilter){
      props.onSetFilter({
        dataStatus:state.dataStatus,
        dateFrom:state.dateFrom,
        dateTo:state.dateTo,
      });
    }
  }
  UNSAFE_componentWillReceiveProps=(nextProps)=>{
    if(nextProps.dataStatus !== this.dataStatus){
      this.setState({dataStatus:nextProps.dataStatus});
    }
  }
  render() {
    const {classes} = this.props;
    return (
      <Drawer
        className={classes.drawerSummary}
        anchor="left"
        open={this.props.isDrawerOpen}
        onClose={this.props.toggleDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={this.props.toggleDrawer} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.drawerMenu+' dFlex dirColumn justStart'} >
          <label className='blue summaryLeftPad'>Filter</label>
          <Divider className={classes.divider} />
          <div className='summaryDrawerContainer '>
            <div className='summaryLeftPad'>
              <InputDateBox title={'From'} value={this.props.dateFrom} onChangeText={(value)=>this.setState({dateFrom:value})} />
              <InputDateBox title={'To'} value={this.props.dateTo} onChangeText={(value)=>this.setState({dateTo:value})}/>
            </div>
            <Divider className={classes.divider} />
            <div className='summaryLeftPad'>
              <SelectBox dataList={this.props.dataStatus} title={'Status'} onChecked={this.onChecked}/>
            </div>
          </div>
        </div>
        <div className='bgBlue dFlex justCenter alignCenter summeryApplyBtn anim' onClick={this.onSetFilter}>
          <label className='white'>APPLY</label>
        </div>
      </Drawer>
    );
  }
}

SummaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  isDrawerOpen:PropTypes.bool,
  toggleDrawer:PropTypes.func,
  onSetFilter:PropTypes.func,
  dataStatus :PropTypes.array,
  dateFrom:PropTypes.string,
  dateTo:PropTypes.string,
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SummaryDrawer);
