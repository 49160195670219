import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#DBEEFC',
      main: '#009CDC',
      dark: '#0599FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F4F4F4',
      dark: '#AEAEAE',
    },
    warning: {
      main: '#FDD835',
      dark: '#FBC02D',
      contrastText: '#FFFFFF'
    },
    text: {
      light: '#C4C4C4',
      main: '#3A3A3A',
      dark: '#1A1A1A',
      contrastText: '#FFFFFF'
    },
    title: {
      main: '#34259C'
    }
  }
});

export default theme;
