import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { imgAsset } from 'helper/function/AssetImage';

const styles = () => ({
  root: {
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e6e6e6'
    }
  },
  container: {
    padding: '1rem',
    display: 'flex',
  },
  personAva: {
    marginRight: '1rem',
  },
  personInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    textAlign: 'left',
  },
  personInfoName: {
    fontWeight: '600 !important',
  },
  personInfoDetail: {
    color: '#8D8D8D !important',
    textAlign: 'left',
    fontWeight: '500 !important',
    // textTransform: 'capitalize',
  }
});

const SearchResultItem = props => {
  const {
    classes,
    data,
    isLast,
    onSelect,
  } = props;

  const profileImg = data.file
    ? data.file.full_path
    : imgAsset.noImg;

  return (
    <div className={classes.root} onClick={onSelect}>
      <div className={classes.container}>
        <div className={classes.personAva}>
          <Avatar src={profileImg} sizes="20" />
        </div>
        <div className={classes.personInfo}>
          <h5 className={classes.personInfoName}>{data.name}</h5>
          <h5 className={classes.personInfoDetail}>
            {data.email}{data.country ? `, ${data.country.name}` : ''}
          </h5>
        </div>
      </div>
      { !isLast ? <Divider /> : '' }
    </div>
  );
};

SearchResultItem.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  isLast: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default withStyles(styles)(SearchResultItem);
