import * as types from './action.types';

const initialState = {
  isLoading: false,
  isProcessing: false,
  isError: {
    status: false,
    message: '',
  },
  dataSummary:{},
};

const reducers = (state=initialState, action) => {
  switch (action.type) {
  case types.DATA_SUMMARY_DATA: {
    return {
      ...state,
      dataSummary:{...action},
    };
  }
  case types.DATA_SUMMARY_DATA_ERROR: {
    return {
      ...state,
      dataSummary:{...action},
    };
  }
  default: return state;
  }
};
export default reducers;
