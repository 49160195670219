import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import SearchField from 'components/inputs/SearchField';
import Loading from 'components/loadings/loading-ellipsis';
import SearchResultItem from './SearchResultItem';

const styles = theme => ({
  root: {
    position: 'relative'
  },
  searchResult: {
    position: 'absolute',
    width: '99%',
    zIndex: 1,
    transform: 'translateY(5px) translateX(1px)',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.2)',
  },
});

class SearchApprover extends Component {
  render() {
    const {
      classes,
      value,
      onChange,
      onSelect,
      onBlur,
      data,
      isTyping,
      isLoading,
      isSelected,
      profile,
      recipient,
      placeholder,
    } = this.props;

    const showResult = () => {
      if (!isSelected) {
        if (isTyping || isLoading) {
          return true;
        }
        if (data.length > 0) {
          return true;
        }
      }
      return false;
    };

    return (
      <div className={classes.root}>
        <SearchField
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
        />
        { showResult() &&
          <div className={classes.searchResult}>
            { (isTyping || isLoading) && <Loading /> }
            {
              data.map((person, index) => {
                if (profile.id !== person.id && recipient.id !== person.id) {
                  return (
                    <SearchResultItem
                      data={person}
                      key={index}
                      isLast={index === data.length - 1}
                      onSelect={() => onSelect(person)}
                    />
                  );
                } else {
                  return null;
                }
              })
            }
          </div>
        }
      </div>
    );
  }
}

SearchApprover.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  data: PropTypes.array,
  isTyping: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSelected: PropTypes.bool,
  profile: PropTypes.object,
  recipient: PropTypes.object,
  placeholder: PropTypes.string,
};

export default withStyles(styles)(SearchApprover);
