import React from 'react';

const SelectComponent=({onSelect,item,index})=>{
  if(item.isSelected){
    return(
      <div className='selectBoxItemBox' onClick={()=>onSelect({index,item})}>
        <div className='checkBoxIconChecked' />
        <div className='checkBoxText'>{item.title}</div>
      </div>
    );
  }else{
    return(
      <div className='selectBoxItemBox' onClick={()=>onSelect({index,item})}>
        <div className='checkBoxIconUnChecked' />
        <div className='checkBoxText'>{item.title}</div>
      </div>
    );
  }
}
const SelectBox=({dataList,onChecked,title})=>{
  const onSelect=({index,item})=>{
    if(item.isSelected){
      dataList[index].isSelected=false;
    }else{
      dataList[index].isSelected=true;
    }
    onChecked(dataList);
  }
  if(dataList.length>0){
    return(
      <div className='selectBoxContainer'>
        <label className='selectBoxTitle'>{title}</label>
        {
          dataList.map((item,index)=>{
            return(
              <SelectComponent key={index.toString()} item={item} index={index} onSelect={onSelect}/>
            )
          })
        }
      </div>
    );
  }else{
    return(
      <div className='selectBoxContainer'>
        <label className='selectBoxTitle'>{title}</label>
        <div className='checkBoxTextNoData'>No Data</div>
      </div>
    );
  }
}

SelectBox.defaultProps={
  onChecked:()=>null,
  dataList:[],
  title:'Fox Select',
}
export {SelectBox}
