export const IS_LOADING = 'AWARD/IS_LOADING';
export const IS_LOADING_MORE = 'AWARD/IS_LOADING_MORE';
export const IS_PROCESSING = 'AWARD/IS_PROCESSING';
export const IS_ERROR = 'AWARD/IS_ERROR';

export const WRITE_TCA_APPRECIATION = 'AWARD/WRITE_TCA_APPRECIATION';
export const WRITE_GEM_APPRECIATION = 'AWARD/WRITE_GEM_APPRECIATION';
export const APPROVAL_GEM = 'AWARD/APPROVAL_GEM';

export const GET_AWARD_TYPES = 'AWARD/GET_AWARD_TYPES';
export const GET_DETAIL = 'AWARD/GET_DETAIL';
export const GET_HISTORY_LIST = 'AWARD/GET_HISTORY_LIST';
export const GET_SENT_LIST = 'AWARD/GET_SENT_LIST';
export const GET_RECEIVED_LIST = 'AWARD/GET_RECEIVED_LIST';
export const GET_PENDING_LIST = 'AWARD/GET_PENDING_LIST';

export const GET_AWARD_COUNT = 'AWARD/GET_AWARD_COUNT';
export const GET_AWARD_COUNT_ALL = 'AWARD/GET_AWARD_COUNT_ALL';
