import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import GiveAppreciation from 'components/dialogs/give-appreciation';
import SubmissionAAA from './components/submission-aaa';
import BaseButton from 'components/buttons/base-button/BaseButton';
import ListingCategory from 'components/listing/listing-category-custom';
import LoadingProcess from 'components/loadings/fullscreen-spin';
import { LoadingEllipsis } from 'components';
import aaaSvg from 'assets/icons/all around appreciation.svg';
import renderHtml from 'helper/renderHtml';

import dispatchers from './dispatchers';
import states from './states';
import styles from './styles';
import { GetCategory } from 'store/category/actions';

class AllAround extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSearch: false,
      openSubmit: false,
      employee: {},
      selCategory: [],
      errCategory: {
        status: false,
        message: 'Please select category first!',
      },
    };
  }

  componentDidMount() {
    const { userToken, Category, getAwardTypes } = this.props;

    getAwardTypes();
    GetCategory({ token: userToken }, ({ data, isFound, isFetching }) => {
      this.setState({ isFetching });
      if (isFound) {
        Category(data);
      }
    });
  }

  handleSelectEmployee = payload => {
    this.setState(prevState => ({
      employee: payload,
      openSearch: !prevState.openSearch,
      openSubmit: !prevState.openSubmit,
    }));
  };

  handleSelectCategory = payload => {
    const { errCategory, selCategory } = this.state;

    this.setState({
      selCategory: [...selCategory, payload],
      errCategory: {
        ...errCategory,
        status: false,
      },
    });
  };

  handleDeselectCategory = payload => {
    const { selCategory } = this.state;

    this.setState({selCategory:selCategory.filter(x => x.id !== payload.id)});
  }

  handleGiveAppreciation = () => {
    const { selCategory, errCategory } = this.state;

    if (selCategory.length > 0) {
      this.handleToggleDrawer('openSearch');
    } else {
      this.setState({
        errCategory: {
          ...errCategory,
          status: true,
        },
      });
    }
  };

  handleSubmit = payload => {
    payload = {
      ...payload,
      categoryId: this.state.selCategory.map(x => x.id),
    };

    this.props.writeAwardTca(payload);

    this.setState({selCategory: []});
  };

  handleToggleDrawer = key => {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }));
  };

  render() {
    const {
      classes,
      categories,
      isProcessing,
      isLoading,
      awardAAA,
    } = this.props;
    const {
      isFetching,
      openSearch,
      openSubmit,
      employee,
      selCategory,
      errCategory,
    } = this.state;

    return (
      <div className={classes.root}>
        {isProcessing && <LoadingProcess />}

        <div className={classes.container}>
          <div className={classes.header}>
            <h3>Appreciation</h3>
          </div>

          <div className={classes.content}>
            <div className={classes.card}>
              <img src={aaaSvg} alt="icon" />

              {(isFetching || isLoading) && (
                <div className="dFlex justCenter alignCenter pad-10">
                  <LoadingEllipsis />
                </div>
              )}
              {!isFetching && !isLoading && (
                <div className={classes.cardContent}>
                  <p
                    dangerouslySetInnerHTML={renderHtml({
                      content: awardAAA.description,
                    })}
                  />
                  <ListingCategory
                    categories={categories}
                    onSelect={this.handleSelectCategory}
                    deSelect={this.handleDeselectCategory}
                    selCategory={selCategory}
                  />
                </div>
              )}

              {errCategory.status && (
                <p className={classes.error}>{errCategory.message}</p>
              )}

              <BaseButton
                label="Give Appreciation"
                buttonClass={classes.btnYellow}
                labelClass={classes.label}
                color="primary"
                fullWidth={true}
                onClick={this.handleGiveAppreciation}
              />

              <GiveAppreciation
                openDialog={openSearch}
                toggleDrawer={() => this.handleToggleDrawer('openSearch')}
                onSelect={payload => this.handleSelectEmployee(payload)}
              />
              <SubmissionAAA
                openDialog={openSubmit}
                fullHeight={true}
                toggleDrawer={() => this.handleToggleDrawer('openSubmit')}
                openSearch={() => this.handleToggleDrawer('openSearch')}
                employee={employee}
                maxChar={557}
                is_anonymous={awardAAA.is_anonymous}
                selCategory={selCategory}
                onSubmit={payload => this.handleSubmit(payload)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AllAround.defaultProps = {
  Category: () => {},
  getAwardTypes: () => {},
  writeAwardTca: () => {},
  categories: [],
  userToken: '',
  isLoading: false,
  isProcessing: false,
  awardAAA: {},
};

AllAround.propTypes = {
  classes: PropTypes.object.isRequired,
  Category: PropTypes.func,
  getAwardTypes: PropTypes.func,
  writeAwardTca: PropTypes.func,
  categories: PropTypes.array,
  userToken: PropTypes.string,
  isLoading: PropTypes.bool,
  isProcessing: PropTypes.bool,
  awardAAA: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers)
)(AllAround);
