import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import LoadingEllipsis from '../../../components/loadings/loading-ellipsis';
import CardAppreciation from '../../../components/cards/card-appreciation';
import BaseButton from '../../../components/buttons/base-button/BaseButton';
import LoadingProcess from '../../../components/loadings/fullscreen-spin';
import NoData from '../../../components/data-handler/no-data';
import RejectedCard from './components/rejected-card';

import dispatchers from './dispatchers';
import states from './states';
import styles from './styles';

class CardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      fileUrl: '',
      fileData: '',
      isPrinting: false,
      isLoadingImage: false,
      isShowImage: false,
    };
  }

  componentDidMount() {
    const { getDetailAward, match } = this.props;
    getDetailAward(match.params.id);
  }

  handleBack = (ev, isCardSent) => {
    const { history } = this.props;

    ev.preventDefault();
    if (isCardSent) history.push('/card/sent');
    else history.push('/card/received');
  };

  handleCreateImage = () => {
    const { award_type, created_date } = this.props.detailGem;
    const senderName = this.getSender(this.props.detailGem);
    const fileName = `${award_type}_${created_date}_${senderName}`;
    const cardElement = document.getElementById('divToPrint');

    if (cardElement) {
      // Fix missing letter i, l, or f following letter f before
      cardElement.style.fontFeatureSettings = '"liga" 0';
      this.setState(
        {
          isLoadingImage: true,
        },
        () => {
          html2canvas(cardElement, {
            scale: 4,
            useCORS: true,
          }).then(canvas => {
            canvas.toBlob(
              blob => {
                const reader = new FileReader();

                reader.onload = () => {
                  this.setState({
                    fileName,
                    fileUrl: reader.result,
                    fileData: blob,
                    isLoadingImage: false,
                    isShowImage: true,
                  });
                };

                reader.readAsDataURL(blob);
              },
              'image/png',
              1
            );
          });
        }
      );
    }
  };

  handleDownload = () => {
    const { fileName, fileUrl } = this.state;

    saveAs(fileUrl, fileName + '.png');
  };

  setImageHasLoaded = () => {
    this.handleCreateImage();
  };

  getSender = data => {
    if (data.sender_detail) {
      if (data.is_anonymous) return 'anonymous';
      if (data.sender_detail.name) return data.sender_detail.name;
    } else {
      return 'Traveloka';
    }
  };

  render() {
    const { classes, isLoading, isError, detailGem, profile } = this.props;
    const { isLoadingImage, isShowImage, fileUrl } = this.state;

    const isCardSent =
      detailGem.sender_detail && profile.id === detailGem.sender_detail.id;
    const isRejected = detailGem.state === 'rejected';

    console.log({ state: this.state });

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          {isLoadingImage && <LoadingProcess />}

          <div className={classes.header}>
            <h3>Card Details</h3>
          </div>

          <div className={classes.content}>
            <button
              className={classes.btnBack}
              onClick={ev => this.handleBack(ev, isCardSent)}
            >
              <span>&larr; </span>
              Back to all {isCardSent ? 'sent' : 'received'} cards
            </button>

            {isLoading && <LoadingEllipsis />}
            {!isLoading && isRejected && <RejectedCard card={detailGem} />}
            {!isLoading && !isRejected && detailGem.id && (
              <div
                id="divToPrint"
                className={classes.exportTarget}
                style={{ display: isLoadingImage ? 'block' : 'none' }}
              >
                <CardAppreciation
                  data={detailGem}
                  setImageHasLoaded={this.setImageHasLoaded}
                  isPrint
                />
              </div>
            )}

            {isShowImage && !isRejected && (
              <img
                className={classes.imageCreated}
                src={fileUrl}
                alt="card.png"
              />
            )}

            <NoData
              isShow={isError.status}
              text="Sorry, you don’t have access to this page"
            />

            {!isLoading && !isError.status && !isCardSent && !isRejected && (
              <div className={classes.actions}>
                <BaseButton
                  fullWidth={true}
                  label="Download as Image"
                  color="primary"
                  onClick={this.handleDownload}
                />
                <p>
                  If the download process doesn&apos;t start automatically, you
                  can download manually by holding the card picture until the
                  pop up appear, then choose &quot;Save Image&quot;.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CardDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isError: PropTypes.object,
  isLoading: PropTypes.bool,
  detailGem: PropTypes.object,
  getDetailAward: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  profile: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers)
)(CardDetails);
