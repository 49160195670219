import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';

class HomeHeader extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.header}>
        <div className={classes.headerProfile}>
          <div className={classes.pageName}>
            <h3 style={{color: '#2E8ADF'}}>Category</h3>
          </div>

        </div>
      </div>
    );
  }
}

HomeHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeHeader);
