import travelokaLogo from '../../assets/gifs/traveloka.gif';

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${travelokaLogo})`,
    backgroundColor: '#1ba0e2',
    backgroundSize: 512,
    backgroundPositionY: 'calc(50% - 160px)',
    backgroundPositionX: 'calc(50% + 10px)',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
  },
  title: {
    marginBottom: 24,
    '& h5': {
      margin: 0,
      fontWeight: '400',
      textAlign: 'center',
      fontSize: 15,
    }
  },
  logo: {
    position: 'absolute',
    top: 'calc(50% - 140px)',
    '& img': {
      width: '250px',
      filter: 'brightness(0) invert(1)',
    },
    '& h5': {
      margin: 0,
      fontWeight: '400',
      textAlign: 'center',
      fontSize: 18,
    }
  },
  signIn: {
    '& button': {
      width: 90,
      height: 90,
      background: theme.palette.secondary.main,
      padding: '.8rem',
      borderRadius: '50%',
      border: 'none',
      cursor: 'pointer',
    },
    '& button:hover': {
      background: theme.palette.secondary.dark,
    },
  }
});

export default styles;
