import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';
import thunk from 'redux-thunk';

// Import redux-persist for persistent store
import {
  persistStore,
  persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';

// Import reducers here
import userReducers from './user/reducers';
import awardReducers from './award/reducers';
import employeeReducers from './employee/reducers';
import dataSummaryReducers from './dataSummary/reducers';
import commonsReducers from './commons/reducers';
import categoryReducers from './category/reducers';
import backupReducers from './backup/reducers';
import configReducers from './configurations/reducers';

const userPersist = {
  key: 'user',
  storage,
  stateReconciler: autoMergeLevel1,
  whitelist: [ 'isLogin', 'auth' ],
};

const reducers = combineReducers({
  user: persistReducer(userPersist, userReducers),
  award: awardReducers,
  employee: employeeReducers,
  dataSummary: dataSummaryReducers,
  commons: commonsReducers,
  category: categoryReducers,
  backup: backupReducers,
  configurations: configReducers,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
