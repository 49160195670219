import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import Avatar from '@material-ui/core/Avatar';
import { imgAsset } from 'helper/function/AssetImage';
import gemSvg from 'assets/icons/bintang.svg';
import styles from './styles';

const CardAppreciationPending = props => {
  const { classes, data, onClick } = props;

  const personAva = (file) => {
    if (file) return file.full_path; 
    return imgAsset.noImg;
  };

  const awardType = () => {
    if (data.award_type === 'gem') return 'Shining Star Appreciation';
    if (data.award_type === 'tca') return 'All-Around Appreciation';
  };
  
  return (
    <div className={classes.card} onClick={onClick}>
      <div className={classes.cardHeader}>
        <h5 className={classes.cardDate}>{format(data.created_date, 'MM/YY')}</h5>
        <div className={classes.awardType}>
          <img
            className={classes.imageAva}
            src={gemSvg}
            alt="icon"
          />
          <h5>{awardType()}</h5>
        </div>
      </div>
      <div className={classes.cardContent}>
        <Avatar
          src={personAva(data.sender_detail.file)}
          alt="avatar"
          sizes="25"
        />
        <div className={classes.cardName}>
          <h5 className={classes.cardSender}>{data.sender_detail.name}</h5>
          <div className={classes.cardReceiver}>
            <h5>to {data.recipient_detail.name}</h5>  
            <img
              className={classes.imageAva}
              src={personAva(data.recipient_detail.file)}
              alt="avatar"
            />
          </div>
        </div>
        <div className={classes.cardAction}>
          &gt;
        </div>
      </div>
    </div>
  );
};

CardAppreciationPending.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export default withStyles(styles)(CardAppreciationPending);
