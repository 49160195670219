import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

const styles = (theme) => ({
  search: {
    backgroundColor: theme.palette.secondary.main,
    padding: '.5rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputRoot: {
    fontSize: '.8rem',
    width: '100%',
    '@media screen and (max-width: 500px)': {
      fontSize: '1rem',
    }
  },
  inputInput: {

  },
  searchIcon: {
    color: '#8D8D8D',
    marginTop: 4,
  }
});

const SearchField = (props) => {
  const {
    classes,
    value,
    onChange,
    onBlur,
    placeholder,
  } = props;

  return (
    <div className={classes.search}>
      <InputBase
        id="search"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="off"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
      />
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
    </div>
  );
};

SearchField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
};

export default withStyles(styles)(SearchField);
