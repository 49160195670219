import * as types from './action.types';

const initialState = {
  isLoading: false,
  isProcessing: false,
  isError: {
    status: false,
    message: '',
  },
  data: {},
  employee: {
    data: [],
    total: 0,
  },
  employeeHod: {
    data: [],
    total: 0,
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
  case types.EMPLOYEE_DATA: {
    return {
      ...state,
      employee: {
        ...state.employee,
        data: action.data.data,
        total: action.data.total,
      },
    };
  }
  case types.EMPLOYEE_DATA_ERROR: {
    return {
      ...state,
      employee: {...action},
    };
  }
  case types.IS_LOADING: {
    return {
      ...state,
      isLoading: true
    };
  }
  case types.IS_PROCESSING: {
    return {
      ...state,
      isProcessing: true,
    };
  }
  case types.IS_ERROR: {
    return {
      ...state,
      isLoading: false,
      isProcessing: false,
      isError: {
        status: true,
        message: action.data.message
      }
    };
  }
  case types.GET_ALL: {
    return {
      ...state,
      isLoading: false,
      employee: action.data,
    };
  }
  case types.GET_ALL_HOD: {
    return {
      ...state,
      isLoading: false,
      employeeHod: action.data,
    };
  }
  case types.UPDATE_PROFILE: {
    return {
      ...state,
      isProcessing: false,
    };
  }
  default: return state;
  }
};

export default reducers;
