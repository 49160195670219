import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ClosePrompt from 'components/alerts/prompt';
import BaseDialog from 'components/dialogs/base-dialog';
import BaseButton from 'components/buttons/base-button/BaseButton';
import TextArea from 'components/inputs/base-text-area';

import styles from './styles';

class ApprovalMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approvalReason: '',
      errorMsg: '',
      showClosePrompt: false,
      isDirty: false,
    };
  }

  handleShowClosePrompt = () => {
    const { toggleDialog } = this.props;
    const { isDirty } = this.state;

    if (isDirty) this.setState({ showClosePrompt: true });
    else {
      toggleDialog(false);
      this.handleResetForm();
    }
  }

  handleClose = (isTrue) => {
    if (isTrue) {
      this.handleResetForm();
      this.props.toggleDialog(false);
    }
    this.setState({ showClosePrompt: false });
  };

  handleSubmit = () => {
    const {
      toggleDialog,
      isApproving,
    } = this.props;
    const { approvalReason } = this.state;

    if (!isApproving ) {
      if (this.validateInput(approvalReason)) {
        this.handleResetForm();
        return toggleDialog(true, approvalReason);
      }
    } else {
      this.handleResetForm();
      return toggleDialog(true, approvalReason);
    }
  }

  handleResetForm = () => {
    this.setState({
      approvalReason: '',
      errorMsg: '',
      isDirty: false,
    });
  }

  handleInput = (e) => {
    const { isApproving } = this.props;

    this.setState({
      approvalReason: e.target.value,
      isDirty: true,
    }, () => {
      if (!isApproving) {
        const { approvalReason } = this.state;
        if (this.validateInput(approvalReason)) {
          this.setState({ errorMsg: '' });
        }
      }
    });
  };

  validateInput = (message) => {
    if (!message) {
      this.setState({ errorMsg: 'Please input your reason for declining request' });
      return false;
    }

    return true;
  };

  render() {
    const {
      classes,
      open,
      isApproving,
    } = this.props;
    const {
      approvalReason,
      errorMsg,
      showClosePrompt,
    } = this.state;

    const approvalTitle = isApproving
      ? 'Approval Reason'
      : 'Decline Reason';

    const approvalLabel = isApproving
      ? 'Please input your reason for approving request (optional)'
      : 'Please input your reason for declining request (required)';

  
    return (
      <BaseDialog
        open={open}
        toggleDialog={this.handleShowClosePrompt}
      >
        <div className={classes.root}>
          <ClosePrompt
            className='marB-20'
            open={showClosePrompt}
            text="Are you sure want to close this window?"
            subText="(All draft will be lost)"
            toggleDialog={val => this.handleClose(val)}
          />

          <h4>{approvalTitle}</h4>

          <div className={classes.formControl}>
            <p>{approvalLabel}</p>
  
            <TextArea
              id="approval-reason"
              name="approvalReason"
              placeholder="Your Reason"
              value={approvalReason}
              onChange={e => this.handleInput(e)}
            />
  
            { errorMsg && <p className={classes.error}>{errorMsg}</p> }
          </div>
  
          <div className={classes.action}>
            <BaseButton
              label="Cancel"
              color="secondary"
              onClick={this.handleShowClosePrompt}
            />
            <BaseButton
              label="Submit"
              color="primary"
              onClick={this.handleSubmit}
            />
          </div>
        </div>
      </BaseDialog>
    );
  }
}

ApprovalMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDialog: PropTypes.func,
  onChange: PropTypes.func,
  open: PropTypes.bool,
  approvalReason: PropTypes.string,
  isApproving: PropTypes.bool,
};

export default withStyles(styles)(ApprovalMessage);
