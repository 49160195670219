import * as types from './action.types';

const initialState = {
  isLoading: false,
  isLoadingMore: false,
  isProcessing: false,
  isError: {
    status: false,
    message: '',
  },
  awardTypes: [],
  detailGem: {},
  awardCountAll: {
    cardSent: {},
    cardReceived: {},
    cardPending: {},
  },
  awardCount: {
    aaa: 0,
    milestone: 0,
    ssa: 0,
    ssa_approved: 0,
    ssa_pending: 0,
    ssa_rejected: 0,
  },
  listHistory: { data: [], total: 0 },
  listReceived: { data: [], total: 0 },
  listSent: { data: [], total: 0 },
  listPending: { data: [], total: 0 },
};

const loadMoreHelper = (newState, oldState) => ({
  ...newState,
  data: newState.current_page > 1
    ? [ ...oldState.data, ...newState.data ]
    : [ ...newState.data ]
});

const awardReducers = (state = initialState, action) => {
  switch (action.type) {
  case types.IS_LOADING: {
    return {
      ...state,
      isLoading: true,
      isError: {
        status: false,
        message: '',
      },
    };
  }
  case types.IS_LOADING_MORE: {
    return {
      ...state,
      isLoadingMore: true
    };
  }
  case types.IS_PROCESSING: {
    return {
      ...state,
      isProcessing: true,
    };
  }
  case types.IS_ERROR: {
    const errData = action.data.response
      ? action.data.response.data
      : action.data;

    return {
      ...state,
      isLoading: false,
      isProcessing: false,
      isLoadingMore: false,
      isError: {
        status: true,
        message: errData.message
      }
    };
  }
  case types.GET_AWARD_TYPES: {
    return {
      ...state,
      isLoading: false,
      awardTypes: action.data
    };
  }
  case types.WRITE_TCA_APPRECIATION: {
    return {
      ...state,
      isProcessing: false,
    };
  }
  case types.WRITE_GEM_APPRECIATION: {
    return {
      ...state,
      isProcessing: false,
    };
  }
  case types.APPROVAL_GEM: {
    return {
      ...state,
      isProcessing: false,
    };
  }
  case types.GET_DETAIL: {
    return {
      ...state,
      isLoading: false,
      detailGem: action.data,
    };
  }
  case types.GET_PENDING_LIST: {
    return {
      ...state,
      isLoading: false,
      isLoadingMore: false,
      listPending: loadMoreHelper(action.data, state.listPending),
    };
  }
  case types.GET_HISTORY_LIST: {
    return {
      ...state,
      isLoading: false,
      isLoadingMore: false,
      listHistory: loadMoreHelper(action.data, state.listSent),
    };
  }
  case types.GET_SENT_LIST: {
    return {
      ...state,
      isLoading: false,
      isLoadingMore: false,
      listSent: loadMoreHelper(action.data, state.listSent),
    };
  }
  case types.GET_RECEIVED_LIST: {
    return {
      ...state,
      isLoading: false,
      isLoadingMore: false,
      listReceived: loadMoreHelper(action.data, state.listReceived),
    };
  }
  case types.GET_AWARD_COUNT: {
    return {
      ...state,
      isLoading: false,
      awardCount: action.data,
    };
  }
  case types.GET_AWARD_COUNT_ALL: {
    const {
      card_pending: pending,
      card_sent: sent,
      card_received: received,
    } = action.data;

    return {
      ...state,
      isLoading: false,
      awardCountAll: {
        cardPending: {
          ...pending,
          total: pending.ssa_pending
        },
        cardReceived: {
          ...received,
          total: received.aaa + received.ssa + received.milestone,
        },
        cardSent: {
          ...sent,
          total: sent.aaa + sent.ssa,
        },
      },
    };
  }
  default:
    return state;
  }
};

export default awardReducers;
