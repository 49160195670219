const styles = (theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  header: {
    height: 260,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .react-player__shadow': {
      border: '2px solid #FFFFFF',
    },
    '& img': {
      marginTop: '1.5rem',
      height: '80%',
    }
  },
  cover: {
    width: '100%',
  },
  pageName: {
    '& h3': {
      color: '#FFFFFF',
      fontWeight: 700,
    }
  },
  avatar: {
    margin: '1.2rem',
    width: 72,
    height: 72,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    padding: '2rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    '& h3': {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
  },
  card: {
    width: '100%',
    minHeight: '8rem',
    margin: '.6rem 0',
    padding: '1rem',
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    '& img': {
      width: 72,
      position: 'absolute',
      top: 0,
      right: 0,
    },
    '& h1': {
      margin: 0,
      color: theme.palette.primary.main,
      fontWeight: 100,
    },
    '& h3': {
      color: theme.palette.primary.main,
    },
  },
  description: {
    margin: 0,
    fontWeight: 400,
    fontSize: '.9rem',
    color: theme.palette.primary.text,
    marginBottom: '1rem',
    whiteSpace: 'pre-wrap',
    '& a': {
      textDecoration: 'none',
      color: '#0097A7',
    }
  },
  appreciation: {
    textAlign: 'center',
    width: '100%',
    '& a': {
      width: '100%',
      textDecoration: 'none',
    }
  },
  subtitle: {
    margin: 0,
    marginBottom: '1.6rem',
    color: theme.palette.text.main,
    fontWeight: 300,
    fontStyle: 'italic',
  },
  categoryItem: {
    borderRadius: 0,
    textTransform: 'none',
    background: '#EEEEEE',
    position: 'relative',
    overflowY: 'hidden',
    padding: '.8rem',
    textAlign: 'center',
    '& h4': {
      margin: 0,
      color: '#FFFFFF',
      position: 'relative',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
      zIndex: 1,
    },
    '& img': {
      position: 'absolute',
      objectFit: 'center',
      width: '100%',
      transform: 'translateY(-30px)',
      filter: 'brightness(70%)',
    }
  }
});

export default styles;
