import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import addDays from 'date-fns/add_days';
import FileSaver from 'file-saver';

import InfiniteScroll from 'react-infinite-scroller';
import {LoaderIndicator,SummaryHeaderBox} from 'components/';
import SummaryDrawer from 'components/drawers/summary-drawer';
import DataDetail from './data-detail-drawer';
import ListingDataSummary from 'components/listing/listing-summary';
import NoData from 'components/data-handler/no-data';

import {GetDataSummary,DataSummary,DownloadDataSummary} from 'store/dataSummary/actions';


class HomeContent extends Component {
  constructor(props) {
    super(props);
    this.state =  {
      page:1,
      limit:10,
      keyword:'',
      dataSummary:{data:[]},
      isFetchingData:true,
      filePath:'',
      isUplouding:false,

      isFetchUploudFile:false,
      isErrorUploudFile:false,
      isReadeyUploudFile:false,

      tabValue:'all',
      dataStatus:[
        {title:'Pending',value:'pending',isSelected:true,isState:true},
        {title:'Declined',value:'rejected',isSelected:true,isState:true},
        {title:'Approved',value:'approved',isSelected:true,isState:true},
        // {title:'Sent',value:'sent',isSelected:true,isState:false},
        // {title:'Received',value:'received',isSelected:true,isState:false},
      ],
      dateFrom:format(addDays(new Date(),-30),'YYYY-MM-DD'),
      dateTo  :format(new Date(),'YYYY-MM-DD'),

      isGetLoad:false,
      hasMore:false,
      selectedData: {},
      showDetail: false,
    };
    this.tempOnSearch=null;
    this.time=0;
  }

  componentDidMount=()=>{
    this.onFirstTimeDataLoad();
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.id !== prevProps.profile.id) {
      this.handleUserAuth();
    }
  }

  handleUserAuth() {
    const { history, profile } = this.props;
    const privilege = ['recognition_team'];
    if (!privilege.includes(profile.role)) return history.push('/');
  }

  onFirstTimeDataLoad=()=>{
    this.setState({keyword:''},()=>{
      this.onGetDataSummary();
    });
  }

  onGetDataSummary=(value)=>{
    value=value?value:'';
    const {state,props} = this;
    this.setState({
      isFetchingData:true,
      page:1
    }, () => {
      GetDataSummary({
        mainParam:{
          dateFrom    :state.dateFrom,
          dateTo      :state.dateTo,
          award_type  :state.tabValue,
          dataStatus  :state.dataStatus,
        },
        token:props.userToken,
        limit:state.limit,
        page:state.page,
        keyword:value,
      },({data,isError,isFound,isFetching})=>{
        if(isFound){
          if(data.data){
            this.setState({
              dataSummary: {
                data:data.data.data
              },
              isFetchingData:false
            }, () => {
              props.DataSummary({data:data.data.data,isFetching,isFound,isError}).then(()=>{
                if(data.data.data.length>=10){
                  this.setState({hasMore:true});
                }else{
                  this.setState({hasMore:false});
                }
              });
            });
          }
        }else if(isError){
          props.DataSummary({data:data,isFetchingData:isFetching,isFound,isError}).then(()=>{
          });
        }
      });
    });
  }

  onLoadMoreGetDataSummary=()=>{
    const {state,props} = this;
    if(!state.isFetchingData){
      if(!state.isGetLoad){
        this.setState({isFetchingDataLoad:true,isGetLoad:true},()=>{
          let page = state.page;
          page =page+1;
          GetDataSummary({
            mainParam:{
              dateFrom    :state.dateFrom,
              dateTo      :state.dateTo,
              award_type  :state.tabValue,
              dataStatus  :state.dataStatus,
            },
            token:props.userToken,
            limit:state.limit,
            page:page,
            keyword:state.keyword,
          },({data,isError,isFound,isFetching})=>{
            if(isFound){
              if(data.data){
                let result = state.dataSummary.data.concat(data.data.data);
                this.setState({dataSummary:{data:result},isFetchingData:false,isGetLoad:false,page:page},()=>{
                  props.DataSummary({data:result,isFetching,isFound,isError}).then(()=>{
                    if(data.data.data.length>=10){
                      this.setState({hasMore:true});
                    }else{
                      this.setState({hasMore:false});
                    }
                  });
                });
              }
            }else if(isError){
              props.DataSummary({data:data,isFetchingData:isFetching,isFound,isError,isGetLoad:false}).then(()=>{
              });
            }
          });

        });
      }
    }
  }

  onDownloadCLick=()=>{
    const {state,props} = this;
    this.setState({isFetchingData:true},()=>{
      DownloadDataSummary({
        mainParam:{
          dateFrom    :state.dateFrom,
          dateTo      :state.dateTo,
          award_type  :state.tabValue,
          dataStatus  :state.dataStatus,
        },
        token:props.userToken,
        limit:state.limit,
        page:state.page,
        keyword:state.keyword,
      },({data,isFound})=>{
        if(isFound){
          // let file = new Blob([data]);
          FileSaver.saveAs(data,`summary_data_${state.dateFrom}-${state.dateTo}_traveloka.xlsx`);
          this.setState({isFetchingData:false});
        }
      });
    });
  }

  onSearch=(value)=>{
    // const {state} = this;

    clearTimeout(this.tempOnSearch);
    this.setState({keyword:value,isFetchingData:true,hasMore:false},()=>{
      this.tempOnSearch=setTimeout(()=>{
        this.onGetDataSummary(value);
      },300);
    });
  }

  onTabBtnCLick=(value)=>{
    this.setState({
      tabValue: value,
      page: 1,
    },()=>{
      this.onGetDataSummary();
    });
  }

  onSetFilter=(filter)=>{
    this.setState({
      page: 1,
      dateFrom :filter.dateFrom,
      dateTo :filter.dateTo,
      dataStatus :filter.dataStatus,
      isDrawerOpen : false,
    },()=>{
      this.onFirstTimeDataLoad();
    });
  }

  handleToggleDetail = (item = {}) => {
    this.setState(prevState => ({
      selectedData: item,
      showDetail: !prevState.showDetail,
    }));
  }

  render() {
    const {state,props}=this;
    return (
      <div className='mainContainer dFlex justStart dirColumn'>
        <div className='pad-10 dFlex justSpace dirColumn width'>
          <SummaryHeaderBox
            dataStatus={state.dataStatus}
            onFilterCLick={()=>this.setState({isDrawerOpen:true})}
            onDownloadCLick={this.onDownloadCLick}
            onSearch={this.onSearch}
            onTabBtnCLick={this.onTabBtnCLick}
            tabValue={state.tabValue}
          />
        </div>
        <div className='dFlex justCenter dirColumn bgGrayF1 width'>
          <LoaderIndicator isShow={state.isFetchingData}/>
          { !state.isFetchingData &&
            <InfiniteScroll
              //style={{minHeight: '100vh'}}
              pageStart={0}
              loadMore={this.onLoadMoreGetDataSummary}
              hasMore={state.hasMore}
              threshold={0}
              useWindow={true}
              loader={<LoaderIndicator key={'loaderSummary'} isShow={state.isGetLoad}/>}
            >
              <ListingDataSummary
                key={'listSummary'}
                dataList={state.dataSummary.data}
                onCollapse={this.onCollapse}
                token={props.userToken}
                onClick={item => this.handleToggleDetail(item)}
              />
            </InfiniteScroll>
          }
          <NoData text="No data found." isShow={state.dataSummary.data.length<1 && !state.isFetchingData}/>
        </div>

        <DataDetail
          isOpen={state.showDetail}
          toggleDrawer={this.handleToggleDetail}
          card={state.selectedData}
        />

        <SummaryDrawer
          dateFrom={state.dateFrom}
          dateTo={state.dateTo}
          dataStatus={state.dataStatus}
          isDrawerOpen={this.state.isDrawerOpen}
          onSetFilter={this.onSetFilter}
          toggleDrawer={()=>this.setState({isDrawerOpen:false})}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userToken:state.user.auth.access_token,
  dataSummary:state.DataSummary,
  profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
  DataSummary: (data) => dispatch(DataSummary(data)),
});

HomeContent.propTypes = {
  filterData:PropTypes.object,
  onFilterCLick:PropTypes.func,
  profile: PropTypes.object,
  history: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContent);
