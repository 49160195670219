import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import HomeHeader from './components/HomeHeader';
import HomeContent from './components/HomeContent';

import styles from './styles';

class DataSummary extends Component {

  render() {
    const { classes, history } = this.props;
    return (
      <div className={classes.root}>
        <HomeHeader />
        <HomeContent history={history} />

      </div>
    );
  }
}

DataSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withStyles(styles)(DataSummary);
