const styles = theme => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    textAlign: 'center',
    padding: '1.3rem 0',
    color: theme.palette.primary.main,
    '& h3': {
      margin: 0,
      fontWeight: 700,
    },
  },
  content: {
    width: '80%',
    minHeight: '90vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 24,
  },
  searchFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '.8rem',
    alignItems: 'center',
  },
  groupMonth: {
    margin: '1rem 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      textDecoration: 'none',
      margin: '.5rem 0',
      minWidth: '100%',
    },
  },
});

export default styles;
