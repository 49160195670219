import React, { Component } from 'react';
import Proptypes from 'prop-types';
import { OwnIcon } from 'helper';
import { SearchBox } from 'components';

class SummaryHeaderBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSearchActive: false,
    };
  }

  onBlur = () => {
    this.setState({ onSearchActive: false });
    this.props.onSearch('');
  };

  onSearchCLick = () => {
    if (!this.state.onSearchActive) {
      this.setState({ onSearchActive: true });
    } else {
      this.setState({ onSearchActive: false });
    }
  };

  render() {
    return (
      <div className="selectBoxContainer dFlex dirColumn">
        <div
          className="selectBoxContainer dFlex justSpace"
          style={{ minHeight: '43px' }}
        >
          <div className="dFlex justStart">
            <OwnIcon.IconFilter
              className="gray summaryBtn anim summaryBtnMarRight"
              onClick={this.props.onFilterCLick}
            />
            <OwnIcon.IconDownload
              className="gray summaryBtn anim "
              onClick={this.props.onDownloadCLick}
            />
          </div>
          <div className="dFlex justEnd">
            <SearchBox
              onSearch={this.props.onSearch}
              onClick={this.onSearchCLick}
              className={`anim ${
                this.state.onSearchActive ? 'animWidth' : 'animNoWidth'
              }`}
            />
          </div>
        </div>

        <div className="dFlex justCenter alignCenter">
          <div
            className="summaryBtnBottom dFlex justSpace anim"
            onClick={() => this.props.onTabBtnCLick('all')}
          >
            <label
              className={`${this.props.tabValue === 'all' ? 'blue' : 'gray'}`}
            >
              All
            </label>
            <div className="summaryLine" />
          </div>
          <div
            className="summaryBtnBottom dFlex justSpace anim"
            onClick={() => this.props.onTabBtnCLick('tca')}
          >
            <label
              className={`${this.props.tabValue === 'tca' ? 'blue' : 'gray'}`}
            >
              All-Around
            </label>
            <div className="summaryLine" />
          </div>
          <div
            className="summaryBtnBottom dFlex justSpace anim"
            onClick={() => this.props.onTabBtnCLick('gem')}
          >
            <label
              className={`${this.props.tabValue === 'gem' ? 'blue' : 'gray'}`}
            >
              Shining Star
            </label>
            <div className="summaryLine" />
          </div>
          <div
            className="summaryBtnBottom dFlex justSpace anim"
            onClick={() => this.props.onTabBtnCLick('milestone')}
          >
            <label
              className={`${
                this.props.tabValue === 'milestone' ? 'blue' : 'gray'
              }`}
            >
              Milestone
            </label>
          </div>
        </div>
      </div>
    );
  }
}

SummaryHeaderBox.defaultProps = {
  onFilterCLick: () => null,
  onBlur: () => null,
  onSearch: () => null,
  onTabBtnCLick: () => null,
  onDownloadCLick: () => null,
  tabValue: 'all',
};

SummaryHeaderBox.propTypes = {
  onSearch: Proptypes.func,
  onFilterCLick: Proptypes.func,
  onDownloadCLick: Proptypes.func,
  onTabBtnCLick: Proptypes.func,
  tabValue: Proptypes.string,
};

export { SummaryHeaderBox };
