import React from 'react';
import './styles_elipsis.css';

const LoadingEllipsis = () => {
  return (
    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  );
};

export default LoadingEllipsis;
