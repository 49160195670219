import * as types from './action.types';

const initialState = {
  isLoading: false,
  isProcessing: false,
  isError: {
    status: false,
    message: '',
  },
  isLogin: false,
  auth: {},
  profile: {},
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
  case types.IS_LOADING: {
    return {
      ...state,
      isLoading: true
    };
  }
  case types.IS_PROCESSING: {
    return {
      ...state,
      isProcessing: true,
    };
  }
  case types.IS_ERROR: {
    const errorMsg = action.data.response
      ? action.data.response.message
      : action.data;

    return {
      ...state,
      isLoading: false,
      isProcessing: false,
      isError: {
        status: true,
        message: errorMsg
      }
    };
  }
  case types.SET_LOGIN: {
    return {
      ...state,
      isProcessing: false,
      isLogin: true,
      auth: action.data
    };
  }
  case types.SET_LOGOUT: {
    return {
      ...state,
      isProcessing: false,
      isLogin: false,
      auth: {},
      profile: {},
    };
  }
  case types.SET_PROFILE: {
    return {
      ...state,
      isLoading: false,
      profile: action.data
    };
  }
  case types.UPDATE_PROFILE: {
    return {
      ...state,
      isProcessing: false
    };
  }
  default:
    return state;
  }
};

export default userReducers;
