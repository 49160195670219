import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import styles from '../styles';
import dispatchers from '../dispatchers';
import states from '../states';

const ProfileEdit = ({
  classes,
  profile,
  proposeProfile,
  closeEditModal,
}) => {
  const [name, setName] = useState(profile.name);

  const handleSubmit = () => {
    const payload = {
      name: name,
      role: profile.role,
      join_date: profile.join_date,
    };

    proposeProfile(payload).then(
      closeEditModal()
    );
  };

  return (
    <div className={classes.editContainer}>
      <h3>
        Propose Edit Profile
      </h3>
      <form id="propose-profile" className={classes.editForm} onSubmit={handleSubmit}>
        <FormControl className={classes.formControl}>
          <TextField
            id="profile-name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <Button type="submit" form="propose-profile" color="primary" variant="outlined" onClick={handleSubmit}>
          Propose
        </Button>
      </form>
    </div>
  );
};

ProfileEdit.defaultProps = {
  proposeProfile: () => {},
  closeEditModal: () => {},
  profile: {},
};

ProfileEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  proposeProfile: PropTypes.func,
  closeEditModal: PropTypes.func,
  profile: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers),
)(ProfileEdit);
