import * as types from './action.types';

const initialState = {
  maintenance: {
    status: false,
    endDate: '',
    message: '',
  },
  showSuccess: false,
  showError: false,
  successMsg: '',
  errorMsg: '',
  showLoadingProcess: false,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
  case types.SHOW_ERROR_ALERT: {
    return {
      ...state,
      showError: true,
      errorMsg: action.data
    };
  }
  case types.HIDE_ERROR_ALERT: {
    return {
      ...state,
      showError: false,
      errorMsg: '',
      maintenance: {
        status: false,
        endDate: '',
        message: '',
      }
    };
  }
  case types.SHOW_SUCCESS_ALERT: {
    return {
      ...state,
      showSuccess: true,
      successMsg: action.data
    };
  }
  case types.HIDE_SUCCESS_ALERT: {
    return {
      ...state,
      showSuccess: false,
      successMsg: ''
    };
  }
  case types.SHOW_LOADING_PROCESS: {
    return {
      ...state,
      showLoadingProcess: true,
    };
  }
  case types.HIDE_LOADING_PROCESS: {
    return {
      ...state,
      showLoadingProcess: false,
    };
  }
  case types.SET_MAINTENANCE_STATUS: {
    return {
      ...state,
      maintenance: action.payload,
    };
  }
  default: return state;
  }
};

export default reducers;
