import axios from 'axios';

const TIMEOUT = 120000;

export const GetRequestServer = ({baseUrl,apiUrl,token,responseType},callback) =>{
  const api = axios.create({
    baseURL: baseUrl,
    timeout: TIMEOUT,
    headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':'Bearer '+token
    }
  });
  callback({
    data:null,
  },
  {
    isFetching:true,
    isFound:false,
    isError:false,
  }
  );
  return api.get(apiUrl).then((res) => {
    let data =res.data;
    // console.log(data.constructor());
    if (data.constructor()!==String){
      callback({
        data:res.data,
      },
      {
        isFetching:false,
        isFound:true,
        isError:false,
      }
      );
    } else {
      callback({
        data:res.data,
      },
      {
        isFetching:false,
        isFound:false,
        isError:true,
      }
      );
    }
  }).catch((err) => { callback({
    data:err.response?err.response.data:err,
  },
  {
    isFetching:false,
    isFound:false,
    isError:true,
  });
  });
};

export const PostRequestServer = ({baseUrl,apiUrl,token,data},callback) =>{
  const api = axios.create({
    baseURL: baseUrl,
    timeout: TIMEOUT,
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
      'Authorization':'Bearer '+token
    }
  });
  callback({
    data:null,
  },
  {
    isFetching:true,
    isFound:false,
    isError:false,
  });
  return api.post(apiUrl,data,{
    onUploadProgress:(progressEvent) => {
      const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
      if (totalLength !== null) {
        //this.updateProgressBarValue(Math.round( (progressEvent.loaded * 100) / totalLength ));
        callback({
          data:null,
        },
        {
          isFetching:true,
          isFound:false,
          isError:false,
          onLoading:(Math.round( (progressEvent.loaded * 100) / totalLength )),
        });
      }
    }
  }).then((res) => {
    let data =res.data;
    if(data.constructor()!==String){
      callback({
        data:res.data,
      },
      {
        isFetching:false,
        isFound:true,
        isError:false,
        onLoading:100,
      });
    } else {
      callback({
        data:res.data,
      },
      {
        isFetching:false,
        isFound:true,
        isError:false,
        onLoading:100,
      });
    }
  }).catch((err) => {
    callback({
      data:err.response?err.response.data:err,
    },
    {
      isFetching:false,
      isFound:false,
      isError:true,
      onLoading:100,
    });
  });
};

export const PutRequestServer = ({ baseUrl,apiUrl,token,data }, callback) =>{
  const api = axios.create({
    baseURL: baseUrl,
    timeout: TIMEOUT,
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
      'Authorization':'Bearer '+token
    }
  });
  callback({
    data:null,
  },
  {
    isFetching:true,
    isFound:false,
    isError:false,
  });
  return api.put(apiUrl,data).then((res) => {
    let data =res.data;
    if(data.constructor()!==String){
      callback({
        data:res.data,
      },
      {
        isFetching:false,
        isFound:true,
        isError:false,
      });
    } else {
      callback({
        data:res.data,
      },
      {
        isFetching:false,
        isFound:false,
        isError:true,
      });
    }
  }).catch((err) => {
    callback({
      data:err.response?err.response.data:err,
    },
    {
      isFetching:false,
      isFound:false,
      isError:true,
    });
  });
};

export const DeleteRequestServer = ({baseUrl,apiUrl,token},callback) =>{
  const api = axios.create({
    baseURL: baseUrl,
    timeout: TIMEOUT,
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
      'Authorization':'Bearer '+token
    }
  });
  callback({
    data:null,
  },
  {
    isFetching:true,
    isFound:false,
    isError:false,
  });
  return api.delete(apiUrl).then((res) => {
    let data =res.data;
    if(data.constructor()!==String){
      callback({
        data:res.data,
      },
      {
        isFetching:false,
        isFound:true,
        isError:false,
      });
    } else {
      callback({
        data:res.data,
      },
      {
        isFetching:false,
        isFound:false,
        isError:true,
      });
    }
  }).catch((err) => {
    callback({
      data:err.response?err.response.data:err,
    },
    {
      isFetching:false,
      isFound:false,
      isError:true,
    });
  });
};

export const FileRequestServer = ({baseUrl,apiUrl,token,responseType},callback) =>{
  const api = axios.create({
    baseURL: baseUrl,
    timeout: TIMEOUT,
    responseType:responseType,
    headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':'Bearer '+token
    }
  });
  return api.get(apiUrl).then((res) => {
    callback({
      data:res.data,
    },
    {
      isFetching:false,
      isFound:true,
      isError:false,
    });
  }).catch((err) => {
    callback({
      data:err.response?err.response.data:err,
    },
    {
      isFetching:false,
      isFound:false,
      isError:true,
    });
  });
};
