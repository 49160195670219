import {
  GetRequestServer,
  PostRequestServer,
  DeleteRequestServer,
} from 'helper';
import {
  //baseUrl,
  API,
} from 'config/api';
import * as types from './action.types';
import axios from 'axios';

export const Category =
  ({ data, isFetching, isError, isFound }) =>
  async dispatch => {
    if (isError) {
      await dispatch({
        type: types.CATEGORY_DATA_ERROR,
        isFetching,
        data,
        isError: data,
        isFound,
      });
    } else {
      await dispatch({
        type: types.CATEGORY_DATA,
        data,
        isFetching,
        isError,
        isFound,
      });
    }
  };

export const GetCategory = ({ token }, callback) => {
  GetRequestServer(
    {
      apiUrl: API.GET_CATEGORY.apiUrl,
      baseUrl: API.GET_CATEGORY.baseUrl,
      token: token,
    },
    (dataResponse, { isFetching, isFound, isError }) => {
      const { data } = dataResponse;
      callback({ data, isFetching, isFound, isError });
      // console.log(dataResponse);
    }
  );
};

// export const GetSinggleCategory= ({token,id},callback)=> {
//   GetRequestServer({
//     apiUrl  :API.GET_CATEGORY.apiUrl+`/${id}`,
//     baseUrl :API.GET_CATEGORY.baseUrl,
//     token   :token,
//   },
//   (dataResponse,{isFetching,isFound,isError})=>{
//     const {data} = dataResponse;
//     callback(
//       {data,isFetching,isFound,isError}
//     );
//   });
// };

export const UpdateCategory = ({ token, data }, callback) => {
  let formData = new FormData();
  formData.append('_method', 'put');
  formData.append('name', data.name);
  formData.append('description', data.description);
  formData.append('award_type_id', data.award_type_id);
  formData.append('status', data.status);

  // if(data.img.isLocal){
  //   formData.append('file',data.img.file);
  // }

  PostRequestServer(
    {
      apiUrl: API.GET_CATEGORY.apiUrl + `/${data.id}`,
      baseUrl: API.GET_CATEGORY.baseUrl,
      token: token,
      data: formData,
    },
    (dataResponse, { isFetching, isFound, isError, onLoading }) => {
      const { data } = dataResponse;
      callback({ data, isFetching, isFound, isError, onLoading });
      // console.log(dataResponse,'resposnse',dataResponse.data);
    }
  );
};

export const AddCategory = ({ token, data }, callback) => {
  let formData = new FormData();
  formData.append('name', data.name);
  formData.append('description', data.description);
  formData.append('award_type_id', data.award_type_id);
  // if(data.img.isLocal){
  //   formData.append('file',data.img.file);
  // }

  PostRequestServer(
    {
      apiUrl: API.GET_CATEGORY.apiUrl,
      baseUrl: API.GET_CATEGORY.baseUrl,
      token: token,
      data: formData,
    },
    (dataResponse, { isFetching, isFound, isError, onLoading }) => {
      const { data } = dataResponse;
      callback({ data, isFetching, isFound, isError, onLoading });
      //console.log(dataResponse,'resposnse',dataResponse.data);
    }
  );
};

export const DeleteCategory = ({ token, data }, callback) => {
  //console.log(data,'from unknow','aku');
  DeleteRequestServer(
    {
      apiUrl: API.GET_CATEGORY.apiUrl + `/${data.id}`,
      baseUrl: API.GET_CATEGORY.baseUrl,
      token: token,
    },
    (dataResponse, { isFetching, isFound, isError }) => {
      const { data } = dataResponse;
      callback({ data, isFetching, isFound, isError });
      //console.log(dataResponse,'resposnse',dataResponse.data);
    }
  );
};

export const GetCategoryAward = ({ token }, callback) => {
  GetRequestServer(
    {
      apiUrl: API.GET_CATEGORY_AWARD.apiUrl,
      baseUrl: API.GET_CATEGORY_AWARD.baseUrl,
      token: token,
    },
    (dataResponse, { isFetching, isFound, isError }) => {
      const { data } = dataResponse;
      callback({ data, isFetching, isFound, isError });
      //console.log(dataResponse);
    }
  );
};

export const GetListMilestone = ({ token }, callback) => {
  GetRequestServer(
    {
      apiUrl: API.GET_MILESTONE_LIST.apiUrl,
      baseUrl: API.GET_MILESTONE_LIST.baseUrl,
      token: token,
    },
    (dataResponse, { isFetching, isFound, isError }) => {
      const { data } = dataResponse;
      callback({ data, isFetching, isFound, isError });
      //console.log(dataResponse);
    }
  );
};

export const AddMilestone = ({ token, data }, callback) => {
  axios
    .post(
      API.GET_MILESTONE_LIST.baseUrl + API.GET_MILESTONE_LIST.apiUrl,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    )
    .then(res => {
      callback({ isError: false, result: res });
    })
    .catch(err => {
      console.log(`error create milestone`, err);
      callback({ isError: true });
    });
};

export const EditMilestone = ({ token, data }, callback) => {
  axios
    .post(
      API.GET_MILESTONE_LIST.baseUrl + API.GET_MILESTONE_LIST.apiUrl+'/'+data.id,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    )
    .then(res => {
      callback({ isError: false, result: res });
    })
    .catch(err => {
      console.log(`error edit milestone`, err);
      callback({ isError: true });
    });
};

export const DeleteMilestone = ({ token, data }, callback) => {
  axios
    .delete(
      API.GET_MILESTONE_LIST.baseUrl + API.GET_MILESTONE_LIST.apiUrl+'/'+data.id,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    )
    .then(res => {
      callback({ isError: false, result: res });
    })
    .catch(err => {
      console.log(`error Delete milestone`, err);
      callback({ isError: true });
    });
};

export const UpdateCategoryAward = ({ token, data }, callback) => {
  let formData = new FormData();
  formData.append('name', data.name);
  formData.append('description', data.description);
  formData.append('_method', 'put');
  formData.append('award_type', data.award_type);
  formData.append('max_character', data.maxChar);
  formData.append('is_anonymous', data.is_anonymous);
  if (data.img.isLocal) {
    formData.append('file', data.img.file);
  }
  //console.log(data,'from unknow','aku');
  PostRequestServer(
    {
      apiUrl: API.GET_CATEGORY_AWARD.apiUrl + `/${data.id}`,
      baseUrl: API.GET_CATEGORY_AWARD.baseUrl,
      token: token,
      data: formData,
    },
    (dataResponse, { isFetching, isFound, isError, onLoading }) => {
      const { data } = dataResponse;
      callback({ data, isFetching, isFound, isError, onLoading });
      //console.log(dataResponse,'resposnse',dataResponse.data);
    }
  );
};

// export const AddCategoryAward= ({token,data},callback)=> {
//   let formData = new FormData();
//   formData.append('name',data.name);
//   formData.append('description',data.description);
//   if(data.img.isLocal){
//     formData.append('file',data.img.file);
//   }
//   PostRequestServer({
//     apiUrl  :API.GET_CATEGORY_AWARD.apiUrl,
//     baseUrl :API.GET_CATEGORY_AWARD.baseUrl,
//     token   :token,
//     data    :formData,
//   },
//   (dataResponse,{isFetching,isFound,isError,onLoading})=>{
//     const {data} = dataResponse;
//     callback(
//       {data,isFetching,isFound,isError,onLoading}
//     );
//     console.log(dataResponse,'resposnse',dataResponse.data);
//   });
// };
