export const EMPLOYEE_DATA ='EMPLOYEE_DATA';

export const IS_LOADING = 'EMPLOYEE/IS_LOADING';
export const IS_PROCESSING = 'EMPLOYEE/IS_PROCESSING';
export const IS_ERROR = 'EMPLOYEE/IS_ERROR';

export const GET_ALL = 'EMPLOYEE/GET_ALL';
export const GET_ALL_HOD = 'EMPLOYEE/GET_ALL_HOD';
export const UPDATE_PROFILE = 'EMPLOYEE/UPDATE_PROFILE';

export const EMPLOYEE_DATA_ERROR ='EMPLOYEE_DATA_ERROR';
