import React from 'react';
import PropTypes from 'prop-types';

const FileDownloaderCustom = ({ onButtonClick, isLoading }) => {
  return (
    <div className="dFlex justStart dirRow alignStart" onClick={() => {
      if(!isLoading) onButtonClick();
    }}>
      <label
        className={`btnUploud dFlex justCenter alignCenter anim ${
          isLoading && 'btnDownloadLoading'
        }`}
      >
        <div className="btnUploudText">
          {isLoading ? 'Downloading...' : 'Export'}
        </div>
      </label>
    </div>
  );
};

FileDownloaderCustom.defaultProps = {
  onButtonClick: () => null,
  isLoading: false,
};

FileDownloaderCustom.propTypes = {
  onButtonClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export { FileDownloaderCustom };
