import * as types from './action.types';

const initialState = {
  isLoading: false,
  isProcessing: false,
  isError: {
    status: false,
    message: '',
  },
  //data: {},
  category: {
    data: []
  },
  // categoryHod: {
  //   data: []
  // },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
  case types.CATEGORY_DATA: {
    return {
      ...state,
      category: {...action},
    };
  }
  case types.CATEGORY_DATA_ERROR: {
    return {
      ...state,
      category: {...action},
    };
  }
  // case types.IS_LOADING: {
  //   return {
  //     ...state,
  //     isLoading: true
  //   };
  // }
  // case types.IS_PROCESSING: {
  //   return {
  //     ...state,
  //     isProcessing: true,
  //   };
  // }
  // case types.IS_ERROR: {
  //   return {
  //     ...state,
  //     isLoading: false,
  //     isProcessing: false,
  //     isError: {
  //       status: true,
  //       message: action.data.message
  //     }
  //   };
  // }
  // case types.GET_ALL: {
  //   return {
  //     ...state,
  //     isLoading: false,
  //     category: action.data,
  //   };
  // }
  // case types.GET_ALL_HOD: {
  //   return {
  //     ...state,
  //     isLoading: false,
  //     categoryHod: action.data,
  //   };
  // }
  default: return state;
  }
};

export default reducers;
