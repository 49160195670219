export const baseUrl = process.env.REACT_APP_API; 
export const headers = {
  'Content-Type': 'application/json',
};

export const googleClientId = '360336783704-eufi3ankqi472aflkg9feev7imfdvlvm.apps.googleusercontent.com';

export const API = {
  GET_EMPLOYEE: {
    baseUrl: baseUrl,
    apiUrl: '/api/employees',
  },
  GET_DATASUMMARY: {
    baseUrl: baseUrl,
    apiUrl: '/api/awards',
  },
  GET_CATEGORY: {
    baseUrl: baseUrl,
    apiUrl: '/api/categories',
  },
  GET_CATEGORY_AWARD: {
    baseUrl: baseUrl,
    apiUrl: '/api/award_types',
  },
  GET_MILESTONE_LIST: {
    baseUrl: baseUrl,
    apiUrl: '/api/milestones',
  },
};
