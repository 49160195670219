import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme) => ({
  root: {
    '& label': {
      fontSize: 14,
      fontWeight: 600,
    }
  },
  select: {
    marginLeft: 8,
    backgroundColor: theme.palette.secondary.main,
    padding: '6px 10px',
    fontSize: '.8rem',
    borderRadius: 2,
    '& div': {
      background: 'none !important',
    }
  },
});

const InlineSelect = props => {
  const {
    classes,
    name,
    label,
    onChange,
    value,
    options,
  }= props;

  return (
    <div className={classes.root}>
      <label htmlFor={name}>{label}</label>
      <Select
        className={classes.select}
        value={value}
        onChange={onChange}
        inputProps={{
          name: name,
          id: `inline-${name}`,
        }}
        disableUnderline
      >
        <MenuItem value=""></MenuItem>
        {
          options.map((option, i) => (
            <MenuItem
              key={`${name}${i}`}
              value={option.value}
            >
              {option.name}
            </MenuItem>
          ))
        }
      </Select>
    </div>
  );
};

InlineSelect.defaultProps = {
  name: 'inline-select',
  label: 'Select',
  value: '',
  onChange: () => {},
  options: [],
};

InlineSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

export default withStyles(styles)(InlineSelect);
