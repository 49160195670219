import React from 'react';
import {LoadingEllipsis} from 'components'

const LoaderIndicator=({text,isShow})=>{
  if(isShow){
    return(
      <div className='errorContainer pad-10 gray dFlex justCenter alignCenter '>
        <LoadingEllipsis/>
      </div>
    )
  }else{
    return null
  }
}
export {LoaderIndicator}
