
const styles = () => ({
  listingCategory: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 12,
  },
  category: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  description: {
    visibility: 'visible',
    opacity: 1,
    transition: 'all .7s ease',
    marginTop: 8,
    background: '#1ba0e2',
    margin: '5px 30px 15px',
    padding: 10,
    color: 'white',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    '& li': {
      fontWeight: 300,
    }
  },
  hide: {
    visibility: 'hidden',
    opacity: 0,
    display: 'none',
    transition: 'all .7s ease',
    marginTop: 8,
    background: '#1ba0e2',
    margin: '5px 30px 15px',
    padding: 10,
    color: 'white',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    '& li': {
      fontWeight: 300,
    }
  },
  button: {
    height: 36,
    width: 230,
    margin: 4,
    backgroundColor: '#fff',
    color: '#1ba0e2',
    '&:hover': {
      backgroundColor: '#fff',
    }
  },
  buttonActive: {
    height: 36,
    width: 230,
    margin: 4,
    backgroundColor: '#1ba0e2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1ba0e2',
    }
  },
  label: {
    fontWeight: 600
  }
});

export default styles;
