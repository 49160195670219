const styles = theme => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    textAlign: 'center',
    padding: '1.3rem 0',
    color: theme.palette.primary.main,
    '& h3': {
      margin: 0,
      fontWeight: 700,
    }
  },
  content: {
    width: '80%',
    minHeight: '90vh',
    padding: '1rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 100,
  },
  formControl: {
    width: '100%',
    margin: '.2rem 0',
    '& > label': {
      color: theme.palette.text.main,
      fontSize: '.9rem',
      fontWeight: 'bold'
    },
  },
  formActions: {
    position: 'fixed',
    width: 512,
    bottom: -2,
    '& button': {
      height: 50,
    }
  },
  error: {
    margin: 0,
    marginBottom: '.4rem',
    textAlign: 'center !important',
    color: 'red !important',
    fontSize: '.8rem',
  },
  maintenanceRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > span': {
      marginRight: -12,
    }
  },
});

export default styles;
