import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import cardBgImg from 'assets/images/card-template.png';
import styles from './styles';
import renderHtml from '../../../helper/renderHtml';

const CardAppreciation = ({ classes, data, isPrint, setImageHasLoaded }) => {
  const getCategory = () => {
    switch (data.award_type) {
    case 'tca':
      return 'All Around Appreciation';
    case 'gem':
      return 'Shining Star Appreciation';
    case 'milestone':
      return 'Milestone Appreciation';
    default:
      return 'Award';
    }
  };

  const getTemplate = () => {
    if (data.award_type_detail) {
      if (data.award_type_detail.file) {
        return data.award_type_detail.file.full_path;
      }
      return cardBgImg;
    } else {
      return cardBgImg;
    }
  };

  const getSender = () => {
    if (data.sender_detail) return data.sender_detail.name;
    return 'Traveloka';
  };

  let catMessageList = data.categories.map((x,idx) => (data.categories.length > 2 && idx !== data.categories.length - 1) ? `${x.name},`: x.name);

  if(catMessageList.length > 1) {
    let tmp = catMessageList[catMessageList.length - 1];
    catMessageList[catMessageList.length - 1] = 'and';
    catMessageList.push(tmp);
  }

  return (
    <div className={classes.card}>
      <img src={getTemplate()} alt="card" onLoad={setImageHasLoaded} />
      <div className={isPrint ? classes.headerPrint : classes.header}>
        <h3>{getCategory()}</h3>
      </div>
      <div className={isPrint ? classes.contentPrint : classes.content}>
        <div className={isPrint ? classes.greetingPrint : classes.greeting}>
          <p>Dear {data.recipient_detail.name},</p>
        </div>
        <div className={
          isPrint ? classes.messagePrint : data.award_type === 'milestone' ? classes.messageMilestone : classes.message
        }>
          {data.message.split(/\r\n|\r|\n/g).map((value, index) => (
            data.award_type === 'milestone' ?  <p
              dangerouslySetInnerHTML={renderHtml({
                content: value,
              })}
            /> : <p key={index.toString()}>{value}</p>
          ))}
        </div>
        {!data.is_anonymous && (
          <div className={isPrint ? classes.signaturePrint : classes.signature}>
            <p>
              Cheers, <br />
              <br /> {getSender()}
            </p>
          </div>
        )}
      </div>

      {
        data.award_type === 'tca' &&  <div className={classes.tcaMessage}>
           Thank you for setting the example and living out value <b>{catMessageList.join(' ')}</b>.
           Let’s continue to implement the 10 principles as we journey together in Traveloka.
        </div>
      }
    </div>
  );
};

CardAppreciation.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  isPrint: PropTypes.bool,
  setImageHasLoaded: PropTypes.func,
};

export default withStyles(styles)(CardAppreciation);
