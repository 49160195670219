const styles = (theme) => ({
  root: {
    backgroundColor: '#FFFFFF'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
  },
  header: {
    padding: '1.3rem 0',
    color: theme.palette.primary.main,
    '& h3': {
      margin: 0,
      fontWeight: 700,
    }
  },
  content: {
    padding: '1rem 0',
    width: '80%',
    minHeight: '90vh',
  },
  card: {
    minHeight: '8rem',
    margin: '.6rem 0',
    padding: '1rem 1rem',
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    '& img': {
      width: 100,
      marginBottom: '1rem',
    },
    '& h1': {
      margin: 0,
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    '& h3': {
      color: theme.palette.primary.main,
    },
    '& p': {
      margin: 0,
      fontWeight: 300,
      fontSize: '.9rem',
      color: theme.palette.primary.text,
      lineHeight: '1.4rem',
      whiteSpace: 'pre-wrap',
    },
  },
  cardTitle: {
    marginBottom: '2rem',
  },
  cardContent: {},
  subtitle: {
    color: theme.palette.secondary.dark + '!important',
    fontWeight: 100 + '!important',
  },
  cardList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    '& img': {
      width: 90,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  cardListCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  categoryItem: {
    width: '48%',
    margin: '4px 0',
    textTransform: 'none',
    background: '#FFF',
    position: 'relative',
    overflowY: 'hidden',
    padding: '.8rem',
    textAlign: 'center',
    borderRadius: 4,
    boxShadow: '2px 4px 10px 0px rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h4': {
      margin: 0,
      color: theme.palette.title.main,
      position: 'relative',
      zIndex: 1,
      fontWeight: 500,
      fontFamily: 'Museo Sans',
      fontSize: '.9rem',
    },
    '& img': {
      position: 'absolute',
      objectFit: 'center',
      width: '100%',
      filter: 'brightness(70%)',
    }
  },
  btnYellow: {
    backgroundColor: '#ffcc00',
    color: '#19469b',
    margin: '2px 0',
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#f6c500',
    }
  },
  label: {
    fontWeight: 600
  },
  error: {
    textAlign: 'center !important',
    color: 'red !important',
    fontSize: '.8rem !important',
  }
});

export default styles;
