import { bindActionCreators } from 'redux';
import {
  getStatusUpdateProfile,
  getListUpdateProfile,
  reviewUpdateProfile,
} from 'store/user/actions';

export default dispatch => bindActionCreators({
  getStatusUpdateProfile,
  getListUpdateProfile,
  reviewUpdateProfile,
}, dispatch);
