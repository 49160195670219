import {
  GetRequestServer,
  // PostRequestServer,
  FileRequestServer,
  // DeleteRequestServer,
} from 'helper';
import { API } from 'config/api';
import * as types from './action.types';

export const DataSummary =
  ({ data, isFetching, isError, isFound }) =>
  async dispatch => {
    if (isError) {
      await dispatch({
        type: types.DATA_SUMMARY_DATA_ERROR,
        isFetching,
        data,
        isError: data,
        isFound,
      });
    } else {
      await dispatch({
        type: types.DATA_SUMMARY_DATA,
        data,
        isFetching,
        isError,
        isFound,
      });
    }
  };

export const DownloadDataSummary = (
  { token, mainParam, limit, page, keyword },
  callback
) => {
  let state = '&state=';
  for (var i = 0; i < mainParam.dataStatus.length; i++) {
    if (mainParam.dataStatus[i].isState) {
      if (mainParam.dataStatus[i].isSelected) {
        state = state + `${mainParam.dataStatus[i].value},`;
      }
    }
  }
  state = state.toString().split('');
  state[state.length - 1] = '';
  state = state.join('');
  let show = '&show=all';
  // for (var i = 0; i < mainParam.dataStatus.length; i++) {
  //   if(!mainParam.dataStatus[i].isState){
  //     show=show+`&show=${mainParam.dataStatus[i].value}`;
  //   }
  // }
  let dateFrom =
    mainParam.dateFrom !== '' ? `&date_from=${mainParam.dateFrom}` : '';
  let dateTo = mainParam.dateTo !== '' ? `&date_to=${mainParam.dateTo}` : '';
  let awardType = `&award_type=${mainParam.award_type}`;
  let search = keyword !== '' ? `&name=${keyword}` : '';

  //console.log(dateTo+dateFrom+awardType+show+state);
  FileRequestServer(
    {
      apiUrl:
        API.GET_DATASUMMARY.apiUrl +
        `/list?limit=${limit}&page=${page}${search}${state}${show}${dateFrom}${dateTo}${awardType}&excel=true`,
      baseUrl: API.GET_DATASUMMARY.baseUrl,
      token: token,
      responseType: 'blob',
    },
    (dataResponse, { isFetching, isFound, isError }) => {
      const { data } = dataResponse;
      callback({ data, isFetching, isFound, isError });
    }
  );
};

export const GetDataSummary = (
  { token, mainParam, limit, page, keyword },
  callback
) => {
  let state = '&state=';
  let selectedState = mainParam.dataStatus.filter(status => status.isSelected);

  if (selectedState.length) {
    selectedState = selectedState.map(s => s.value);
    state = state + selectedState.join(',');
  } else {
    selectedState = mainParam.dataStatus.map(s => s.value);
    state = state + selectedState.join(',');
  }

  let show = '&show=all';
  // for (var i = 0; i < mainParam.dataStatus.length; i++) {
  //   if(!mainParam.dataStatus[i].isState){
  //     show=show+`&show=${mainParam.dataStatus[i].value}`;
  //   }
  // }
  let dateFrom =
    mainParam.dateFrom !== '' ? `&date_from=${mainParam.dateFrom}` : '';
  let dateTo = mainParam.dateTo !== '' ? `&date_to=${mainParam.dateTo}` : '';
  let awardType = `&award_type=${mainParam.award_type}`;
  let search =
    keyword !== ''
      ? `&name=${keyword.includes('+') ? keyword.split('+')[0] : keyword}`
      : '';

  // console.log(`/list?limit=${limit}&page=${page}${search}${state}${show}${dateFrom}${dateTo}${awardType}`);
  // console.log(dateTo+dateFrom+awardType+show+state);
  GetRequestServer(
    {
      apiUrl:
        API.GET_DATASUMMARY.apiUrl +
        `/list?limit=${limit}&page=${page}${search}${state}${show}${dateFrom}${dateTo}${awardType}`,
      baseUrl: API.GET_DATASUMMARY.baseUrl,
      token: token,
    },
    (dataResponse, { isFetching, isFound, isError }) => {
      const { data } = dataResponse;
      callback({ data, isFetching, isFound, isError });
    }
  );
};
