export const SHOW_SUCCESS_ALERT = 'COMMONS/SHOW_SUCCESS_ALERT';
export const HIDE_SUCCESS_ALERT = 'COMMONS/HIDE_SUCCESS_ALERT';

export const SHOW_ERROR_ALERT = 'COMMONS/SHOW_ERROR_ALERT';
export const HIDE_ERROR_ALERT = 'COMMONS/HIDE_ERROR_ALERT';

export const SHOW_LOADING_PROCESS = 'COMMONS/SHOW_LOADING_PROCESS';
export const HIDE_LOADING_PROCESS = 'COMMONS/HIDE_LOADING_PROCESS';

export const SET_MAINTENANCE_STATUS = 'COMMONS/SET_MAINTENANCE_STATUS';
