/* eslint-disable indent */
/* eslint-disable quotes */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  BottomDrawer,
  FileUplouder,
  UplouderFileIndicator,
  LoadingEllipsis,
  CustomButton,
  SelectBox,
} from 'components/';
import Input from 'components/inputs/custom-input';
import TextArea from 'components/inputs/base-text-area';
import ClosePrompt from 'components/alerts/prompt';

import {
  UpdateCategoryAward,
  Category,
  GetCategoryAward,
} from 'store/category/actions';
import {
  showSuccessAlert,
  showErrorAlert,
  showLoadingProcess,
  closeLoadingProcess,
} from 'store/commons/actions';

class HomeDrawerEditAwardType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMaxChar: 0,
      inputName: '',
      inputDescription: '',
      filePath: { file: '', isLocal: false },
      isReadeyUploudFile: false,
      idCategory: null,
      isFetching: false,
      isErrorUploudFile: false,
      isClickAble: true,
      fileImage: null,
      showClosePrompt: false,
      anonymous: [
        {
          title: 'Select this if you allow input as anonymous',
          value: 'anonymous',
          isSelected: true,
        },
      ],
    };

    this.onInputMaxChar = this.onInputMaxChar.bind(this);
  }

  onUploudFile = () => {
    const {
      anonymous,
      isErrorName,
      isErrorDescription,
      errorMaxChar,
      inputMaxChar,
    } = this.state;
    this.checkReadySend(() => {
      if (!isErrorName && !isErrorDescription && !errorMaxChar) {
        this.setState({
          isFetchUploudFile: true,
          isErrorUploudFile: false,
          errorText: null,
        });
        this.props.showLoadingProcess();
        UpdateCategoryAward(
          {
            token: this.props.userToken,
            data: {
              is_anonymous: anonymous[0].isSelected ? 1 : 0,
              maxChar: inputMaxChar && inputMaxChar > 0 ? inputMaxChar : 250,
              name: this.state.inputName,
              description: this.state.inputDescription,
              img: this.state.filePath,
              award_type: this.state.awardType,
              id: this.state.idCategory,
            },
          },
          ({ data, isFound, isError, onLoading }) => {
            this.setState({ uploudProgressValue: onLoading });
            if (isFound) {
              this.props.closeLoadingProcess();
              this.props.showSuccessAlert('Award has successfully updated!');
              this.onGetCategory();
            } else if (isError) {
              this.props.closeLoadingProcess();
              this.props.showErrorAlert(
                "There's something error during the process!"
              );
              this.setState({
                isErrorUploudFile: true,
                isFetchUploudFile: false,
                errorText: data.message,
              });
            }
          }
        );
      }
    });
  };

  checkReadySend = callback => {
    const { state } = this;
    this.setState(
      {
        isErrorName: state.inputName.length >= 3 ? false : true,
        isErrorDescription: state.inputDescription.length >= 3 ? false : true,
      },
      () => {
        callback(true);
      }
    );
  };

  onGetCategory = () => {
    const { props } = this;
    GetCategoryAward(
      {
        token: props.userToken,
        limit: 10,
        page: 1,
        keyword: '',
      },
      ({ data, isError, isFound, isFetching }) => {
        if (isFound) {
          this.setState({ isFetchUploudFile: false });
          props
            .Category({ data: data.data, isFetching, isFound, isError })
            .then(() => {
              this.handleClose(true);
            });
        } else if (isError) {
          this.props.showErrorAlert(
            "There's something error during the process"
          );
        }
      }
    );
  };

  onFileSelect = ({ file, isError, isLocal }) => {
    // console.log(file);
    this.setState({ filePath: { file, isFileError: isError, isLocal } });
    if (file) {
      this.setState({
        isReadeyUploudFile: true,
        isErrorUploudFile: false,
        fileImg: URL.createObjectURL(file),
      });
    }
  };

  onCLosed = () => {
    this.setState({
      isReadeyUploudFile: false,
      isErrorUploudFile: false,
      filePath: '',
      isFetchUploudFile: false,
    });
  };

  handleClose = isTrue => {
    if (isTrue) this.props.onCLosed(false);
    this.setState({ showClosePrompt: false });
  };

  onInputName = e => {
    if (e.target.value.length > 3) {
      this.setState({
        inputName: e.target.value,
        isErrorName: false,
      });
    } else {
      this.setState({
        inputName: e.target.value,
        isErrorName: true,
      });
    }
  };

  onInputDescription = e => {
    if (e.target.value.length > 3) {
      this.setState({
        inputDescription: e.target.value,
        isErrorDescription: false,
      });
    } else {
      this.setState({
        inputDescription: e.target.value,
        isErrorDescription: true,
      });
    }
  };

  onInputMaxChar = e => {
    const { value } = e.target;
    let errorMaxChar = '';
    if (value < 0) errorMaxChar = 'Max character cannot be minus';
    if (!value) errorMaxChar = 'You have to filled max character';
    if (value < 0) this.setState({ inputMaxChar: 0, errorMaxChar });
    else if (!value) this.setState({ inputMaxChar: null, errorMaxChar });
    else this.setState({ inputMaxChar: value, errorMaxChar: '' });
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.tempData !== this.props.tempData) {
      let fileImg = null;
      if (nextProps.tempData.file) {
        fileImg = nextProps.tempData.file.full_path;
      }
      this.setState({
        inputName: nextProps.tempData.name,
        inputDescription: nextProps.tempData.description,
        inputMaxChar: nextProps.tempData.max_character,
        idCategory: nextProps.tempData.id,
        awardType: nextProps.tempData.award_type,
        anonymous: [
          {
            title: 'Select this if you allow input as anonymous',
            value: 'anonymous',
            isSelected: nextProps.tempData.is_anonymous,
          },
        ],

        filePath: { file: '', isLocal: false },
        isErrorName: false,
        isErrorDescription: false,
        errorMaxChar: '',
        uploudProgressValue: 0,
        isErrorUploudFile: false,

        fileImg: fileImg,
      });
    }
  };

  render() {
    const { state, props } = this;

    const modalTitle = () => {
      switch (props.tempData.award_type) {
        case 'tca':
          return 'All-Around Appreciation';
        case 'gem':
          return 'Shining Star Appreciation';
        default:
          return 'Edit Award';
      }
    };

    return (
      <BottomDrawer
        fullHeight={true}
        openDialog={this.props.isOpen}
        toggleDrawer={() => this.setState({ showClosePrompt: true })}
      >
        <div className="dFlex justSpace alignCenter dirColumn categoryModal bgBlue">
          <ClosePrompt
            className="marB-20"
            open={state.showClosePrompt}
            toggleDialog={val => this.handleClose(val)}
            text="Are you sure want to cancel?"
            subText="(All draft will be lost)"
          />

          <div className="categoryModalHeader bgBlue">
            <h3 className="white">{modalTitle()}</h3>
          </div>
          <div className="bgWhite categoryModalContent">
            <div className="gray categoryModaltextInfo">
              Fill this following field to edit this awards type
            </div>

            <div className="mb-12">
              <div className="imgViewTitle">Category Name</div>
              <Input
                placeholder="Category Name"
                value={this.state.inputName}
                onChange={this.onInputName}
                fullWidth={true}
              />
              {state.isErrorName && (
                <div className="red inputErrorText">
                  You must fill award name , minimal 3 characters
                </div>
              )}
            </div>

            <div className="mb-12">
              <div className="imgViewTitle">Description</div>
              <TextArea
                id="inputDescription"
                name="inputDescription"
                placeholder="Description"
                value={this.state.inputDescription}
                onChange={this.onInputDescription}
                maxLength={1000}
              />
              {state.isErrorDescription && (
                <div className="red inputErrorText">
                  You must fill description, minimal 3 characters
                </div>
              )}
            </div>

            <div className="categoryImgView">
              <div className="imgViewTitle">Background Image</div>
              <img src={state.fileImg} alt="default" />
            </div>

            <div className="pad-5 marT-10">
              <FileUplouder
                title="Choose File"
                onFileSelect={this.onFileSelect}
                accept="image/jpg"
                isButtonHide={true}
                isProcessing={state.isFetchUploudFile}
                //onFileUploud={this.onUploudFile}
                isFetching={state.isFetchUploudFile}
              />
              <UplouderFileIndicator
                file={state.filePath.file}
                className="marT-10"
                uploudProgressValue={state.uploudProgressValue}
                isShow={state.isReadeyUploudFile}
                isError={state.isErrorUploudFile}
                isLoading={state.isFetchUploudFile}
                onCLosed={this.onCLosed}
                errorText={state.errorText}
              />

              {props.tempData.award_type !== 'milestone' && (
                <>
                  <div className="mb-12" style={{ marginTop: 24 }}>
                    <div className="imgViewTitle">Max Character</div>
                    <Input
                      placeholder="Maximum Character"
                      value={state.inputMaxChar}
                      onChange={this.onInputMaxChar}
                      fullWidth={true}
                      type="number"
                    />
                    {state.errorMaxChar && (
                      <div className="red inputErrorText">
                        {state.errorMaxChar}
                      </div>
                    )}
                  </div>

                  <SelectBox
                    dataList={state.anonymous}
                    title="Anonymous"
                    onChecked={val =>
                      this.setState({
                        anonymous: val,
                      })
                    }
                  />
                </>
              )}

              <div className="dFlex justCenter alignCenter marT-20">
                {state.isFetchUploudFile && <LoadingEllipsis />}
                {!state.isFetchUploudFile && (
                  <CustomButton
                    title="Save"
                    className="categoryModalButton marB-20"
                    onClick={this.onUploudFile}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </BottomDrawer>
    );
  }
}

HomeDrawerEditAwardType.defaultProps = {
  onCLosed: () => null,
  isOpen: false,
  userToken: null,
  tempData: {},
};

HomeDrawerEditAwardType.propTypes = {
  userToken: PropTypes.string,
  showErrorAlert: PropTypes.func,
  showSuccessAlert: PropTypes.func,
  showLoadingProcess: PropTypes.func,
  closeLoadingProcess: PropTypes.func,
  onCLosed: PropTypes.func,
  tempData: PropTypes.object,
  isOpen: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  Category: data => dispatch(Category(data)),
  showSuccessAlert: msg => dispatch(showSuccessAlert(msg)),
  showErrorAlert: msg => dispatch(showErrorAlert(msg)),
  showLoadingProcess: () => dispatch(showLoadingProcess()),
  closeLoadingProcess: () => dispatch(closeLoadingProcess()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeDrawerEditAwardType);
