import React from 'react';
import {HighlightOff} from '@material-ui/icons/';
import {OwnIcon} from 'helper';

const clue=['img','file'];
const fileImg=[
  {icon:(<OwnIcon.IconImage className="gray bgGrayF1 uploudFileIcon" />)},
  {icon:(<OwnIcon.IconFile className="gray bgGrayF1 uploudFileIcon" />)},
]
const UplouderFileIndicator=({file,isShow,isLoading,isError,onCLosed,errorText,textUploud,isShowLoadingText,className,icon,uploudProgressValue})=>{
  if(isShow && file){
    return(
      <div className={`errorContainer gray dFlex justCenter alignStart dirRow uploudContainer ${className}`}>
        {fileImg[clue.indexOf(icon)].icon}
        {!isLoading && <HighlightOff className='uploudLoaderCloseBtn anim' onClick={onCLosed}/>}
        <div className='dFlex justCenter alignStart dirColumn loaderIndicatorBox'>
          <label className='uploudTextFile'>{file.name}</label>
          { isError &&
            <label className='uploudTextFile red pad-10'>{errorText}</label>
          }
          { (isShowLoadingText && isLoading) &&
            <label className='uploudTextFile'>{textUploud}</label>
          }
          <div className='bgGrayF1 uploudFileLoaderInside'>
            { <div className='bgBlue ' style={{width: uploudProgressValue+'%',height: '3px',overflow: 'hidden'}} />}
          </div>
          <div className='dFlex justSpace alignStart dirRow'>
            <label className='uploudStatusText'>{uploudProgressValue}% done</label>
            <label className='uploudStatusText'>{(file.size?file.size/1000 :0/1000 ).toFixed(2)}kb</label>
          </div>
        </div>
      </div>
    )
  }else{
    return null
  }
}
UplouderFileIndicator.defaultProps={
  file:{name:'File Name'},
  isShow:false,
  isLoading:false,
  isError:false,
  onCLosed:()=>null,
  errorText:'Theres Something Error During The Process !!!',
  isShowLoadingText:true,
  textUploud:'Loading...',
  className:'',
  icon:'file',
  uploudProgressValue:0,
}
export {UplouderFileIndicator}
