import axios from 'axios';
import format from 'date-fns/format';
import { baseUrl } from 'config/api';
import * as types from './action.types';
import * as commons from '../commons/action.types';

export const getConfigs = () => {
  return async (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_LOADING });
    await axios.get(`${baseUrl}/api/configurations`, {
      headers: {
        'Authorization': authStr,
      }
    })
      .then(res => {
        dispatch({ type: types.GET_LIST, data: res.data.data });
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err.response.data });
      });
  };
};

export const createUpdateConfigs = (payload) => {
  return async (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;
    const formData = new FormData();

    formData.append('config[video]', payload.video);
    formData.append('config[infographics]', payload.infographics);
    formData.append('config[infographics_hod]', payload.infographicsHod);
    formData.append('config[infographics_lead]', payload.infographicsLead);
    formData.append('config[infographics_recognition]', payload.infographicsRecognition);
    formData.append('config[title]', payload.title);
    formData.append('config[content]', payload.content);
    formData.append('config[infographics_menu]', payload.infographicsMenu);

    if (payload.maintenanceStatus) {
      formData.append('config[maintenance_status]', payload.maintenanceStatus ? '1' : '0');
      formData.append('config[maintenance_end]', format(payload.maintenanceEnd, 'MM-DD-YYYY'));
      formData.append('config[maintenance_message]', payload.maintenanceMessage);
    } else {
      formData.append('config[maintenance_status]', payload.maintenanceStatus ? '1': '0');
    }

    dispatch({ type: types.IS_PROCESSING });
    await axios.post(`${baseUrl}/api/configurations`, formData, {
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
      .then(({ data }) => {
        dispatch({ type: types.CREATE_UPDATE, data: data.data });
        dispatch({ type: commons.SHOW_SUCCESS_ALERT, data: 'Update config success!' });
      })
      .catch((err) => {
        dispatch({ type: types.IS_ERROR, data: err.response.data });
        dispatch({ type: commons.SHOW_ERROR_ALERT, data: err.response.data.message });
      });
  };
};

export const sendMaintenanceEmail = () => {
  return async (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.SEND_MAINTENANCE_EMAIL_LOADING });
    await axios.post(`${baseUrl}/api/maintenance/send-email`, {}, {
      headers: {
        'Authorization': authStr,
      },
    })
      .then((res) => {
        dispatch({ type: types.SEND_MAINTENANCE_EMAIL_SUCCESS, data: res.data });
        dispatch({ type: commons.SHOW_SUCCESS_ALERT, data: res.data.message || 'Send maintenance email success!' });
      })
      .catch((err) => {
        dispatch({ type: types.IS_ERROR, data: err.response.data });
        dispatch({ type: commons.SHOW_ERROR_ALERT, data: err.response.data.message });
      });
  };
};
