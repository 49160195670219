import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/dialogs/base-dialog';
import BaseButton from 'components/buttons/base-button/BaseButton';

import questionSvg from 'assets/icons/question-colored.svg';

import './styles.css';

const PromptAlert = (props) => {
  const {
    toggleDialog,
    open,
    text,
    subText,
  } = props;

  const handleClick = val => {
    toggleDialog(val);
  };

  return (
    <BaseDialog open={open} toggleDialog={() => handleClick(false)}>
      <div className="action-prompt">
        <img
          id="pulseAlert"
          style={{ width: '5rem', margin: '1rem' }}
          src={questionSvg}
          alt="icon"
        />

        <div className="action-prompt__message">
          <h4>{text}</h4>
          { subText && <p>{subText}</p> }
        </div>

        <div className="action-prompt__action">
          <BaseButton
            label="No"
            color="secondary"
            onClick={() => handleClick(false)}
          />
          <BaseButton
            label="Yes"
            color="primary"
            onClick={() => handleClick(true)}
          />
        </div>
      </div>
    </BaseDialog>
  );
};

PromptAlert.propTypes = {
  toggleDialog: PropTypes.func,
  open: PropTypes.bool,
  text: PropTypes.string,
  subText: PropTypes.string,
};

export default PromptAlert;
