const styles = (theme) => ({
  drawer: {
  },
  drawerHeader: {
    padding: '0',
    width: '18.5rem',
    display: 'flex',
    '& button': {
      marginLeft: 'auto'
    },
  },
  drawerMenu: {
    paddingLeft: '2rem',
    '& a': {
      textDecoration: 'none',
    }
  },
  menuText: {
    '& span': {
      color: theme.palette.text.main,
      fontSize: '.8rem',
    }
  },
  menuIcon: {
    margin: 0,
    '& img': {
      width: '1rem',
      height: '1rem',
    },
  },
  divider: {
    margin: '1rem 0rem',
  },
  nested: {
    paddingLeft: theme.spacing.unit * 5,
  },
});

export default styles;
