import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import ProfileHeader from './components/ProfileHeader';
import ProfileContent from './components/ProfileContent';
import ProfileEdit from './components/ProfileEdit';

import styles from './styles';

const Profile = ({ classes }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <div className={classes.root}>
      <ProfileHeader toggleEditModal={() => setEditModalOpen(!editModalOpen)}/>
      <ProfileContent />
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.boxModal}>
          <ProfileEdit closeEditModal={() => setEditModalOpen(false)} />
        </div>
      </Modal>
    </div>
  );
};

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Profile);
