import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { InputDateBox } from 'components';
import { ROLES } from 'constants/roles';
import dispatchers from '../dispatchers';
import states from '../states';
import styles from '../styles';

const EmployeeEdit = ({
  classes,
  employee,
  closeEditModal,
  onSuccessUpdate,
  getEmployeeCountries,
  getEmployeeDepartments,
  updateEmployee,
}) => {
  const [name, setName] = useState(employee.name);
  const [email, setEmail] = useState(employee.email);
  const [role, setRole] = useState(employee.role);
  const [status, setStatus] = useState(employee.status);
  const [joinDate, setJoinDate] = useState(employee.join_date);
  const [country, setCountry] = useState(employee.country.name);
  const [department, setDepartment] = useState(employee.department === null ? '' : employee.department.name );
  const [listCountries, setListCountries] = useState([]);
  const [listDepartments, setListDepartments] = useState([]);

  const handleSubmit = async() => {
    const payload = {
      name,
      email,
      role,
      status,
      join_date: joinDate,
      country,
      department
    };

    await updateEmployee(employee.id, payload)
      .then(() => {
        closeEditModal();
        onSuccessUpdate();
      });
  };

  useEffect(() => {
    const fetchEmployeeCountries = async () => {
      await getEmployeeCountries()
        .then(res => {
          setListCountries(res.data);
          return res;
        })
        .catch(err => {
          console.log(err);
        });
    };
    const fetchEmployeeDepartments = async() => {
      await getEmployeeDepartments()
        .then(res => {
          setListDepartments(res.data);
          return res;
        })
        .catch(err => {
          console.log(err);
        });
    };
    fetchEmployeeCountries();
    fetchEmployeeDepartments();
  }, []);

  return (
    <div className={classes.editContainer}>
      <h3>
        Edit Employee
      </h3>
      <form id="edit-emp" className={classes.editForm} onSubmit={handleSubmit}>
        <FormControl className={classes.formControl}>
          <TextField
            id="emp-name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControlLabel
          className={classes.formControl}
          label={status === 'active' ? 'Active' : 'Inactive'}
          control={
            <>
              <Switch
                checked={status === 'active'}
                onChange={() => setStatus(status === 'active' ? 'inactive' : 'active')}
                name="emp-status-check" 
                color="primary"
              />
            </>
          }
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="emp-role-label">Role</InputLabel>
          <Select
            labelid="emp-role-label"
            id="emp-role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            {
              Object.keys(ROLES).map((roleKey) => 
                <MenuItem
                  key={roleKey}
                  value={roleKey}
                >{ROLES[roleKey]}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="emp-department-label">Department</InputLabel>
          <Select
            labelid="emp-department-label"
            id="emp-department"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          >
            <MenuItem value={''}>None</MenuItem>
            {
              listDepartments.length > 0 && listDepartments.map((dpmItem, dpmIndex) => 
                <MenuItem
                  key={dpmIndex}
                  value={dpmItem.name}
                >{dpmItem.name}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="emp-country-label">Country</InputLabel>
          <Select
            labelid="emp-country-label"
            id="emp-country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            {
              listCountries.length > 0 && listCountries.map((countryItem, countryIndex) => 
                <MenuItem
                  key={countryIndex}
                  value={countryItem.name}
                >{countryItem.name}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="emp-email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputDateBox title={'Join Date'} value={joinDate} onChangeText={(value) => setJoinDate(value)} />
        </FormControl>
        <Button type="submit" form="edit-emp" color="primary" variant="outlined" onClick={handleSubmit}>
          Update
        </Button>
      </form>
    </div>
  );
};

EmployeeEdit.defaultProps = {
  onUpdateClick: () => {},
  closeEditModal: () => {},
  onSuccessUpdate: () => {},
  employee: {},
};

EmployeeEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  updateEmployee: PropTypes.func,
  closeEditModal: PropTypes.func,
  getEmployeeCountries: PropTypes.func,
  getEmployeeDepartments: PropTypes.func,
  onSuccessUpdate: PropTypes.func,
  employee: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers),
)(EmployeeEdit);
