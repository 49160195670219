import { bindActionCreators } from 'redux';
import {
  getAwardCount,
} from 'store/award/actions';
import {
  getProfile,
  updateProfile,
  proposeProfile,
} from 'store/user/actions';

export default dispatch => bindActionCreators({
  getAwardCount,
  updateProfile,
  getProfile,
  proposeProfile,
}, dispatch);
