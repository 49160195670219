import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LoaderIndicator } from '../../../components/';
import { ListingCategory } from '../../../components/listing/listing-category';
import { ListingMilestone } from '../../../components/listing/listing-milestone';
import ClosePrompt from 'components/alerts/prompt';
import NoData from '../../../components/data-handler/no-data';
import HomeDrawerEditCategory from './HomeDrawerEditCategory';
import HomeDrawerAddCategory from './HomeDrawerAddCategory';
import HomeDrawerEditAwardType from './HomeDrawerEditAwardType';
import HomeDrawerAddMilestone from './HomeDrawerAddMilestone';
import HomeDrawerEditMilestone from './HomeDrawerEditMilestone';

import {
  Category,
  GetCategoryAward,
  GetListMilestone,
  AddMilestone,
  EditMilestone,
  DeleteMilestone,
} from 'store/category/actions';
import {
  showSuccessAlert,
  showErrorAlert,
} from 'store/commons/actions';

class HomeContent extends Component {
  constructor(props) {
    super(props);
    this.state =  {
      page:1,
      limit:10,
      keyword:'',
      dataCategory:{data:[]},
      isFetchingData:true,
      filePath:'',
      isUplouding:false,
      isFetchUploudFile:false,
      isErrorUploudFile:false,
      isReadeyUploudFile:false,
      listMilestone: [],
      errorText:null,
      isDrawerAddMilestoneOpen: false,
      isDrawerEditMilestoneOpen: false,
      isDialogDeleteOpen: false
    };
    this.tempOnSearch=null;
    this.time=0;
  }

  componentDidMount=()=>{
    this.onGetCategory();
    this.onGetMilestone();
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.id !== prevProps.profile.id) {
      this.handleUserAuth();
    }
  }

  handleUserAuth() {
    const { history, profile } = this.props;
    const privilege = ['recognition_team'];
    if (!privilege.includes(profile.role)) return history.push('/');
  }

  onFirstTimeDataLoad=()=>{
    this.setState({keyword:'',isFetchingData:true},()=>{
      this.onGetCategory();
    });
  }

  onGetCategory=()=>{
    const {state,props} = this;

    GetCategoryAward({
      token: props.userToken,
      limit: state.limit,
      page: state.page,
      keyword: state.keyword,
    },({data,isError,isFound})=>{
      if(isFound){
        this.breakUpData(data.data,(dataCategory)=>{
          this.setState({dataCategory:{data:dataCategory},isFetchingData:false});
          // props.Category({data:dataCategory,isFetching,isFound,isError}).then(()=>{
          //
          // });
        });
      }else if(isError){
        // props.Category({data:data,isFetching,isFound,isError}).then(()=>{
        // });
      }
    });
  }

  onGetMilestone=()=> {
    const {props} = this;
    GetListMilestone({token: props.userToken},({data, isFound}) => {
      if (isFound){
        this.setState({listMilestone: data.data.data});
      }
    });
  }

  onSearch=(value)=>{
    const {state} = this;
    this.setState({keyword:value});
    if(!state.isFetchingData){
      this.setState({isFetchingData:true});
    }
    clearTimeout(this.tempOnSearch);
    this.tempOnSearch=setTimeout(()=>{
      this.onGetCategory();
    },300);
  }

  breakUpData=(data,callback)=>{
    for (var i = 0; i < data.length; i++) {
      data[i]={
        ...data[i],
        isCollapse:false,
      };
    }
    callback(data);
  }

  onOpenDrawerEdit=(data)=>{
    this.setState({tempData:data},()=>{
      this.setState({isDrawerEditCategoryOpen:true});
    });
  }

  onOpenDrawerEditAwards=(data)=>{
    this.setState({tempDataAward:data},()=>{
      this.setState({isDrawerEditAwardOpen:true});
    });
  }
  onOpenDrawerAdd=(tempDataAward)=>{
    this.setState({isDrawerAddCategoryOpen:true,tempDataAward});
  }

  handleAddMilestone=(datas)=> {
    const {props} = this;

    AddMilestone({token: props.userToken, data: datas }, ({isError, result}) => {
      const {message} = result.data;
      this.setState({isDrawerAddMilestoneOpen: false});

      if(isError) {
        this.props.showErrorAlert('There\'s something error during the process!');
      } else {
        this.props.showSuccessAlert(message);
      }

      this.onGetMilestone();
    });
  }

  handleEditMilestone=(datas)=> {
    const {props} = this;

    EditMilestone({token: props.userToken, data: datas }, ({isError, result}) => {
      const {message} = result.data;
      this.setState({isDrawerEditMilestoneOpen: false});

      if(isError) {
        this.props.showErrorAlert('There\'s something error during the process!');
      } else {
        this.props.showSuccessAlert(message);
      }

      this.onGetMilestone();
    });
  }

  handleDeleteCard=(isDelete)=>{
    const {state, props} = this;
   
    if(isDelete) {
      if(state.isDialogDeleteOpen.is_active === 0) {
        DeleteMilestone({token: props.userToken, data: state.isDialogDeleteOpen }, ({isError, result}) => {
          const {message} = result.data;
          this.setState({isDrawerEditMilestoneOpen: false});
    
          if(isError) {
            this.props.showErrorAlert('There\'s something error during the process!');
          } else {
            this.props.showSuccessAlert(message);
          }
    
          this.onGetMilestone();
  
          this.setState({isDialogDeleteOpen: false});
        });
      } else {
        this.setState({isDialogDeleteOpen: false});
        this.props.showErrorAlert('Failed to delete! This card is currently active!');
      }
    } else {
      this.setState({isDialogDeleteOpen: false});
    }
  }

  UNSAFE_componentWillReceiveProps=(nextProps)=>{
    if(nextProps.category!==this.props.category){
      if(nextProps.category.category){
        this.setState({dataCategory:{data:nextProps.category.category.data}});
      }
    }
  }

  render() {
    const {state,props}=this;
    return (
      <div className='mainContainer dFlex justCenter dirColumn '>
        <ClosePrompt
          className="marB-20"
          open={state.isDialogDeleteOpen!==false}
          toggleDialog={val => this.handleDeleteCard(val)}
          // eslint-disable-next-line quotes
          text={`Are you sure want to delete ${state.isDialogDeleteOpen.title} card?`}
        />
        <LoaderIndicator isShow={state.isFetchingData}/>
        <ListingCategory 
          dataList={state.dataCategory.data}
          onOpenDrawerAdd={this.onOpenDrawerAdd}
          onOpenDrawerEdit={this.onOpenDrawerEdit}
          onOpenDrawerEditAwards={this.onOpenDrawerEditAwards}
          onCollapse={this.onCollapse}
          onEdit={this.onCollapse}
          token={props.userToken}
          // eslint-disable-next-line
          onEdit={this.onOpenDrawerEdit}
        />

        <ListingMilestone
          dataList={state.listMilestone.sort((a, b) => a.years - b.years)}
          onEdit={(data) => this.setState({isDrawerEditMilestoneOpen: data})}
          onDelete={(data) => this.setState({isDialogDeleteOpen: data})}
        />

        {
          state.listMilestone.length > 0 && (
            <div className='dFlex justEnd alignCenter'>
              <div className='blue btnAddCategory anim'
                onClick={()=>this.setState({isDrawerAddMilestoneOpen: true})}
              >
            + Add New Milestone
              </div>
            </div>
          )
        }

        <NoData
          text="No data found."
          isShow={state.dataCategory.data.length<1 && !state.isFetchingData}
        />

        <HomeDrawerEditCategory
          tempDataAward={state.tempDataAward}
          tempData={state.tempData}
          userToken={props.userToken}
          isOpen={state.isDrawerEditCategoryOpen}
          onCLosed={(value)=>this.setState({isDrawerEditCategoryOpen:value})}
        />

        <HomeDrawerAddCategory
          tempData={state.tempDataAward}
          userToken={props.userToken}
          isOpen={state.isDrawerAddCategoryOpen}
          onCLosed={(value)=>this.setState({isDrawerAddCategoryOpen:value})}
        />

        <HomeDrawerEditAwardType
          tempData={state.tempDataAward}
          userToken={props.userToken}
          isOpen={state.isDrawerEditAwardOpen}
          onCLosed={(value)=>this.setState({isDrawerEditAwardOpen:value})}
        />

        <HomeDrawerAddMilestone
          currentYears={state.listMilestone.map(x => x.years)}
          isOpen={state.isDrawerAddMilestoneOpen}
          onSave={this.handleAddMilestone}
          onCLosed={() => this.setState({isDrawerAddMilestoneOpen: false})}
        />

        <HomeDrawerEditMilestone
          isOpen={state.isDrawerEditMilestoneOpen}
          onSave={this.handleEditMilestone}
          onCLosed={() => this.setState({isDrawerEditMilestoneOpen: false})}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userToken: state.user.auth.access_token,
  category: state.category,
  profile: state.user.profile
});

const mapDispatchToProps = (dispatch) => ({
  Category: (data) => dispatch(Category(data)),
  showSuccessAlert: (msg) => dispatch(showSuccessAlert(msg)),
  showErrorAlert: (msg) => dispatch(showErrorAlert(msg)),
});

HomeContent.propTypes = {
  category: PropTypes.object,
  history: PropTypes.object,
  profile: PropTypes.object,
  showSuccessAlert: PropTypes.func,
  showErrorAlert: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeContent);
