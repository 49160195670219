import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SuccessAlert from 'components/alerts/success';
import ErrorAlert from 'components/alerts/error';

class DefaultLayout extends Component {
  render() {
    const { children } = this.props;

    return (
      <Fragment>
        <SuccessAlert />
        <ErrorAlert />
        {children}
      </Fragment>
    );
  }
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default DefaultLayout;
