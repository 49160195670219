
const styles = theme => ({
  root: {
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e6e6e6'
    },
    '& h3': {
      color: theme.palette.primary.main,
    },
    '& h5': {
      margin: 0,
      color: theme.palette.text.main,
      fontWeight: 400,
    }
  },
  container: {
    padding: '1rem',
    display: 'flex',
  },
  personAva: {
    margin: 'auto',
    marginRight: '1rem !important',
    marginLeft: 0,
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    textAlign: 'left',
  },
  cardMessage: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    margin: '4px 0',
    width: 230,
    '@media screen and (min-width: 320px) and (max-width: 375px)': {
      width: 140,
    },
    '@media screen and (min-width: 375px) and (max-width: 414px)': {
      width: 160,
    },
  },
  cardDate: {
    marginLeft: 'auto',
    textAlign: 'right',
    '& p': {
      fontSize: 11,
      margin: '4px 0',
    }
  },
  personInfoName: {
    display: 'flex',
    alignItems: 'center',
    '& h5': {
      fontWeight: '600 !important',
      fontSize: 14,
    }
  },
  personInfoDetail: {
    color: '#8D8D8D !important',
    textAlign: 'left',
    fontSize: 12,
  },
  statusPending: {
    marginTop: 4,
    padding: '0 5px',
    borderRadius: 20,
    fontSize: 12,
    display: 'inline-block',
    textTransform: 'capitalize',
    backgroundColor: '#fff4b9',
    color: '#78714c',
    border: '1px solid #78714c',
  },
  statusSent: {
    marginTop: 4,
    padding: '0 5px',
    borderRadius: 20,
    fontSize: 12,
    display: 'inline-block',
    textTransform: 'capitalize',
    backgroundColor: '#c9ebbf',
    color: '#597152',
    border: '1px solid #597152',
  },
  statusDeclined: {
    marginTop: 4,
    padding: '0 5px',
    borderRadius: 20,
    fontSize: 12,
    display: 'inline-block',
    textTransform: 'capitalize',
    backgroundColor: '#f8cccb',
    color: '#743f3d;',
    border: '1px solid #743f3d',
  },
  statusApproved: {
    marginTop: 4,
    padding: '0 5px',
    borderRadius: 20,
    fontSize: 12,
    display: 'inline-block',
    textTransform: 'capitalize',
    backgroundColor: '#a7c6ea',
    color: '#4c637e',
    border: '1px solid #4c637e',
  },
  divider: {
    margin: '0 12px',
  }
});

export default styles;
