import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import BottomDrawer from 'components/drawers/bottom-drawer';
import TextArea from 'components/inputs/base-text-area';
import BaseButton from 'components/buttons/base-button/BaseButton';
import SearchApprover from 'components/inputs/search-approver';
import ClosePrompt from 'components/alerts/prompt';
import ssaSvg from 'assets/icons/shining star appreciation.svg';
import Switch from '@material-ui/core/Switch';

import dispatchers from '../../dispatchers';
import states from '../../states';
import styles from './styles';

let timer = 0;

class SubmissionSSA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      searchStr: '',
      isTyping: false,
      isSelected: false,
      isAnonymous: false,

      hodEmployee: {},
      message: '',
      messageToHod: '',

      errorHod: '',
      errorMsg: '',
      errorMsgHod: '',

      msgStyle: {},
      msgHodStyle: {},

      showClosePrompt: false,
      showBackPrompt: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { openSearch, toggleDrawer } = prevProps;
    const { step } = this.state;

    // Check if user press back button when on step 1,
    // handle the drawer to close & reopen the
    // search employee dialog
    if (prevState.step > 0 && step < 1) {
      toggleDrawer();
      openSearch();
      this.handleResetForm();
    }
  }

  handleResetForm = () => {
    this.setState({
      step: 1,
      hodEmployee: {},
      searchStr: '',
      message: '',
      messageToHod: '',
      errorHod: '',
      errorMsg: '',
      errorMsgHod: '',
      isAnonymous: false,
    });
  };

  handleClose = isTrue => {
    if (isTrue) {
      this.handleResetForm();
      this.props.toggleDrawer();
    }
    this.setState({ showClosePrompt: false });
  };

  handleBackDialog = () => {
    if (this.state.step === 1) {
      this.setState({ showBackPrompt: true });
    } else {
      this.handleBack(true);
    }
  };

  handleBack = isTrue => {
    if (isTrue) {
      this.setState(prevState => ({
        step: prevState.step - 1,
      }));
    }
    this.setState({ showBackPrompt: false });
  };

  handleNext = () => {
    const { employee, onSubmit } = this.props;
    const {
      step,
      hodEmployee,
      message,
      messageToHod,
      isAnonymous,
    } = this.state;

    if (step === 1) {
      let valid = true;

      if (!this.validateInput(message.trim(), 'errorMsg')) {
        valid = false;
      }

      if (!this.menuPrivilege(['ceo'])) {
        if (!hodEmployee.id) {
          this.setState({ errorHod: 'Please insert Approver’s name' });
          valid = false;
        }

        if (!this.validateInput(messageToHod.trim(), 'errorMsgHod')) {
          valid = false;
        }
      }

      if (!valid) return;
    }

    if (step === 2) {
      onSubmit({
        recipientId: employee.id,
        approverId: hodEmployee.id,
        message,
        messageToHod,
        isAnonymous,
      });
      return this.handleClose(true);
    }

    this.setState(prevState => ({
      step: prevState.step + 1,
      errorMsg: '',
    }));
  };

  handleSearchApprover = e => {
    const { getAllEmployees, profile } = this.props;
    const searchStr = e.target.value;

    if (!timer) this.setState({ isTyping: true });

    this.setState({
      searchStr,
      isSelected: false,
    });

    clearTimeout(timer);
    timer = setTimeout(() => {
      const ceoPrivilege = ['ceo', 'hod'];
      let position = 'hod';

      if (ceoPrivilege.includes(profile.role)) position += ',ceo';

      getAllEmployees({
        search: searchStr,
        position,
        status: 'active',
      });
      this.setState({ isTyping: false });
      timer = null;
    }, 800);
  };

  handleSelectApprover = hod => {
    this.setState({
      searchStr: hod.name,
      hodEmployee: hod,
      isSelected: true,
      errorHod: '',
    });
  };

  handleInputMessage = e => {
    this.setState({ message: e.target.value }, () => {
      const { message } = this.state;
      if (this.validateInput(message.trim(), 'errorMsg')) {
        this.setState({ errorMsg: '' });
      }
    });
  };

  handleInputMessageHOD = e => {
    this.setState({ messageToHod: e.target.value }, () => {
      const { messageToHod } = this.state;
      if (this.validateInput(messageToHod.trim(), 'errorMsgHod')) {
        this.setState({ errorMsgHod: '' });
      }
    });
  };

  validateInput = (message, key) => {
    // eslint-disable-next-line
    const isForeign = /^[\s\w\d\?><;,\{\}\[\]\-_\+=!@\#\$%:."“”’`^/()~\\&\*\|\']*$/.test(
      message
    );

    if (!isForeign) {
      this.setState({
        [key]: 'Message can only contain roman alphabet characters!',
      });
      return false;
    }

    if (!message && !message.length) {
      let errMsg =
        key === 'errorMsg'
          ? 'Please input the appreciation message!'
          : 'Please input the message!';

      this.setState({ [key]: errMsg });
      return false;
    }

    return true;
  };

  getMsgStyle = element => {
    if (element) {
      if (element.clientHeight > 15) {
        this.setState({
          msgStyle: { textAlign: 'left' },
        });
      } else {
        this.setState({
          msgStyle: { textAlign: 'center' },
        });
      }
    }
  };

  getMsgHodStyle = element => {
    if (element) {
      if (element.clientHeight > 15) {
        this.setState({
          msgHodStyle: { textAlign: 'left' },
        });
      } else {
        this.setState({
          msgHodStyle: { textAlign: 'center' },
        });
      }
    }
  };

  menuPrivilege = privilege => {
    const { profile } = this.props;

    if (privilege.includes(profile.role)) return true;
    return false;
  };

  render() {
    const {
      classes,
      fullHeight,
      openDialog,
      employeeHod,
      isLoading,
      profile,
      employee,
      maxChar,
      is_anonymous,
    } = this.props;
    const {
      step,
      searchStr,
      message,
      messageToHod,
      hodEmployee,
      isTyping,
      isSelected,
      errorHod,
      errorMsg,
      errorMsgHod,
      msgStyle,
      msgHodStyle,
      showClosePrompt,
      showBackPrompt,
      isAnonymous,
    } = this.state;

    return (
      <BottomDrawer
        fullHeight={fullHeight}
        openDialog={openDialog}
        toggleDrawer={() => this.setState({ showClosePrompt: true })}
      >
        <div className={classes.container}>
          <ClosePrompt
            className="marB-20"
            open={showClosePrompt}
            toggleDialog={val => this.handleClose(val)}
            text="Are you sure want to close this window?"
            subText="(All draft will be lost)"
          />

          <ClosePrompt
            className="marB-20"
            open={showBackPrompt}
            toggleDialog={val => this.handleBack(val)}
            text="Are you sure want to back to previous screen?"
            subText="(All draft will be lost)"
          />

          <div className={classes.header}>
            <img src={ssaSvg} alt="icon" />
          </div>
          {step === 1 && (
            <div className={classes.content}>
              {!this.menuPrivilege(['ceo']) && (
                <div className={classes.formControl}>
                  <h5 className={classes.formLabel}>Approver</h5>
                  <SearchApprover
                    value={searchStr}
                    placeholder="Please insert Approver’s name"
                    data={employeeHod.data}
                    isLoading={isLoading}
                    isTyping={isTyping}
                    isSelected={isSelected}
                    profile={profile}
                    recipient={employee}
                    onChange={this.handleSearchApprover}
                    onSelect={hod => this.handleSelectApprover(hod)}
                  />
                  {errorHod && <p className={classes.error}>{errorHod}</p>}
                </div>
              )}

              <div className={classes.formControl}>
                <div className={classes.formLabel}>
                  <h5>
                    Please insert your appreciation message in the message box
                    below
                  </h5>
                  <h5>(This message will be received by the employee)</h5>
                </div>
                <TextArea
                  id="award-message"
                  name="message"
                  placeholder={`Message (Max. ${maxChar} characters)`}
                  value={message}
                  onChange={e => this.handleInputMessage(e)}
                  maxLength={maxChar}
                />
                {errorMsg && <p className={classes.error}>{errorMsg}</p>}
              </div>

              {!this.menuPrivilege(['ceo']) && (
                <div className={classes.formControl}>
                  <div className={classes.formLabel}>
                    <h5>
                      Please briefly give explanation on why you want to give
                      the appreciation to the respective employee
                    </h5>
                    <h5>(This message will be received by the ‘Approver’)</h5>
                  </div>
                  <TextArea
                    id="award-message"
                    name="messageToHod"
                    placeholder={`Message (Max. ${maxChar} characters)`}
                    value={messageToHod}
                    onChange={e => this.handleInputMessageHOD(e)}
                    maxLength={maxChar}
                  />
                  {errorMsgHod && (
                    <p className={classes.error}>{errorMsgHod}</p>
                  )}
                </div>
              )}

              {!!is_anonymous && (
                <div className={classes.anonymousSwitch}>
                  <h5>Anonymously?</h5>
                  <Switch
                    value="isAnonymous"
                    color="primary"
                    checked={isAnonymous}
                    onChange={e =>
                      this.setState({
                        isAnonymous: e.target.checked,
                      })
                    }
                  />
                </div>
              )}
              {errorMsg && <p className={classes.error}>{errorMsg}</p>}
            </div>
          )}
          {step === 2 && (
            <div className={classes.content}>
              {!this.menuPrivilege(['ceo']) && (
                <div className={classes.formControl}>
                  <h5>Approver</h5>
                  <p style={{ textAlign: 'center' }}>{hodEmployee.name}</p>
                </div>
              )}

              <div className={classes.formControl}>
                <h5>Appreciation Message</h5>
                {message.split(/\r\n|\r|\n/g).map((value, index) => (
                  <p
                    ref={this.getMsgStyle}
                    style={msgStyle}
                    key={index.toString()}
                  >
                    {value}
                  </p>
                ))}
              </div>

              {!this.menuPrivilege(['ceo']) && (
                <div className={classes.formControl}>
                  <h5>Message to Approver</h5>
                  {messageToHod.split(/\r\n|\r|\n/g).map((value, index) => (
                    <p
                      ref={this.getMsgHodStyle}
                      style={msgHodStyle}
                      key={index.toString()}
                    >
                      {value}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>

        {step === 2 && (
          <div className={classes.actions} style={{ flexDirection: 'column' }}>
            <p>
              Please make sure your shining star appreciation card details are
              correct!
            </p>
            <BaseButton
              label="Send"
              color="primary"
              fullWidth={true}
              onClick={this.handleNext}
            />
            <BaseButton
              label="Cancel"
              color="primary"
              variant="outlined"
              fullWidth={true}
              onClick={this.handleBackDialog}
            />
          </div>
        )}
        {step !== 2 && (
          <div className={classes.actions}>
            <BaseButton
              label="Back"
              color="primary"
              onClick={this.handleBackDialog}
              style={{
                backgroundColor: '#696969',
                color: '#fff',
              }}
            />
            <BaseButton
              label="Next"
              color="primary"
              onClick={this.handleNext}
            />
          </div>
        )}
      </BottomDrawer>
    );
  }
}

SubmissionSSA.propTypes = {
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  fullHeight: PropTypes.bool,
  openSearch: PropTypes.func,
  employee: PropTypes.object,
  employeeHod: PropTypes.object,
  onSubmit: PropTypes.func,
  getAllEmployees: PropTypes.func,
  isLoading: PropTypes.bool,
  profile: PropTypes.object,
  maxChar: PropTypes.number,
  is_anonymous: PropTypes.number,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers)
)(SubmissionSSA);
