const styles = theme => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    textAlign: 'center',
    padding: '1.3rem 0',
    color: theme.palette.primary.main,
    '& h3': {
      margin: 0,
      fontWeight: 700,
    }
  },
  content: {
    width: '80%',
    minHeight: '90vh',
    padding: '1rem 0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 24,
  },
  groupMonth: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
