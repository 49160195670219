import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import HomeHeader from './components/HomeHeader';
import HomeContent from './components/HomeContent';

import styles from './styles';

const Employee = ({ classes, history }) => {
  return (
    <div className={classes.root}>
      <HomeHeader />
      <HomeContent history={history} />
    </div>
  );
};

Employee.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withStyles(styles)(Employee);
