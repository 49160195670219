import React from 'react';
import PropTypes from 'prop-types';
import {OwnIcon} from 'helper';

const SearchBox = (props) => {
  const {
    onSearch,
    // style,
    className,
    onBlur,
    onClick,
    placeholder,
  } = props;

  const onChangeText = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if(onSearch){
      onSearch(event.target.value);
    }
  };

  return(
    <div className={`bgGrayF2 searchBox dFlex alignCenter ${className}`}>
      <input
        type='text'
        className='searchBoxInput'
        placeholder={placeholder}
        onInput={onChangeText}
        onBlur={onBlur}
      />
      <OwnIcon.IconSearch
        className=' anim sarchBoxBtn'
        onClick={onClick}
      />
    </div>
  );
};

SearchBox.defaultProps={
  onSearch: () => null,
  onBlur: () => null,
  onClick: () => null,
  placeholder: 'Search'
};

SearchBox.propTypes = {
  onSearch: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export {SearchBox};
