import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class CategoryFilter extends Component {
  render() {
    const {
      tabValue,
      onClick,
      aaaCount,
      ssaCount,
      pendingCount,
      showPendingTab,
      menuPrivilege,
    } = this.props;
  
    const tabClass = (val) => {
      if (val === tabValue) return 'blue';
      return 'gray';
    };

    return (
      <div className="dFlex justCenter alignCenter">
        <div
          className="btn-filter anim text-center"
          onClick={()=>onClick('tca')}
        >
          <h3 className="ma-0">{aaaCount}</h3>
          <p className={`my-4 ${tabClass('tca')}`}>All-Around</p>
        </div>


        {
          menuPrivilege(['ceo', 'hod', 'lead', 'recognition_team']) &&
          <Fragment>
            <div className="summaryLine2" />

            <div
              className="btn-filter anim text-center"
              onClick={()=>onClick('gem')}
            >
              <h3 className="ma-0">{ssaCount}</h3>
              <p className={`my-4 ${tabClass('gem')}`}>Shining Star</p>
            </div>
          </Fragment>
        }

        { 
          showPendingTab &&
          <Fragment>
            <div className="summaryLine2" />

            <div
              className="btn-filter anim text-center"
              onClick={()=>onClick('pending')}
            >
              <h3 className="ma-0">{pendingCount}</h3>
              <p className={`my-4 ${tabClass('pending')}`}>Pending</p>
            </div>
          </Fragment>
        }
      </div>
    );
  }
}

CategoryFilter.defaultProps = {
  tabValue: 'tca',
  onClick: () => {},
  allCount: 0,
  ssaCount: 0,
  pendingCount: 0,
  showPendingTab: false,
  profile: {},
  menuPrivilege: () => {},
};

CategoryFilter.propTypes = {
  tabValue: PropTypes.string,
  onClick: PropTypes.func,
  aaaCount: PropTypes.number,
  ssaCount: PropTypes.number,
  pendingCount: PropTypes.number,
  showPendingTab: PropTypes.bool,
  profile: PropTypes.object,
  menuPrivilege: PropTypes.func,
};

export default CategoryFilter;
