import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    '& textarea': {
      padding: '.4rem 1rem',
      fontSize: '.8rem',
      '@media screen and (max-width: 500px)': {
        fontSize: '1rem',
      },
    },
  },
});

const BaseTextArea = props => {
  const {
    classes,
    id,
    name,
    placeholder,
    value,
    onChange,
    rows,
    maxLength,
  } = props;

  return (
    <TextField
      id={id}
      name={name}
      type="text"
      className={classes.root}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      multiline={true}
      fullWidth={true}
      rows={rows}
      InputProps={{ disableUnderline: true }}
      // eslint-disable-next-line
      inputProps={{ maxLength }}
    ></TextField>
  );
};

BaseTextArea.defaultProps = {
  id: '',
  name: '',
  placeholder: '',
  value: '',
  onChange: () => {},
  rows: 4,
  maxLength: 250,
};

BaseTextArea.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
};

export default withStyles(styles)(BaseTextArea);
