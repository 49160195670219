const styles = (theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  header: {
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    color: '#2E8ADF',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding: '0 2rem',
    margin: '2rem 0',
    '& a': {
      width: '100%',
      textDecoration: 'none',
    }
  },
  cardNotif: {
    padding: 16,
    marginBottom: 8,
    '& span': {
      color: '#2E8ADF',
    }
  },

  //Review Modal
  modalBox: {
    backgroundColor: 'white',
    borderRadius: 4,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modalAction: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  reviewContainer: {
    padding: 16,
  },
  approveBtn: {
    color: 'green',
    borderColor: 'green',
  },
  rejectBtn: {
    color: 'red',
    borderColor: 'red',
  }
});

export default styles;
