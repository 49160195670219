import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import LoadingProcess from 'components/loadings/fullscreen-spin';
import ButtonUpload from 'components/buttons/ChangeProfilePic';
import ClosePrompt from 'components/alerts/prompt';
import { imgAsset } from 'helper/function/AssetImage';

import dispatchers from '../dispatchers';
import states from '../states';
import styles from '../styles';

class ProfileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgFile: null,
      tempUrl: '',
      fileLoaded: false,
      errorFile: false,

      imgFileCover: null,
      tempUrlCover: '',
      fileLoadedCover: false,
      errorFileCover: false,

      showCancelAva: false,
      showCancelCover: false,
    };
  }

  handleUploadAva = (e) => {
    const img = e.target.files[0];
    const tempUrl = URL.createObjectURL(img);

    if (this.validateFile(img)) {
      this.setState({
        imgFile: img,
        fileLoaded: true,
        errorFile: false,
        tempUrl,
      });
    } else {
      this.setState({
        imgFile: img,
        fileLoaded: true,
        errorFile: true,
        tempUrl,
      });
    }
  }

  handleUploadCover = (e) => {
    const img = e.target.files[0];
    const tempUrlCover = URL.createObjectURL(img);

    if (this.validateFile(img)) {
      this.setState({
        imgFileCover: img,
        fileLoadedCover: true,
        errorFileCover: false,
        tempUrlCover,
      });
    } else {
      this.setState({
        imgFileCover: img,
        fileLoadedCover: true,
        errorFileCover: true,
        tempUrlCover,
      });
    }
  }

  validateFile = (file) => {
    const fileType = file.type.split('/')[0];
    const fileSize = file.size;
    const maxSize = 2 * 1024 * 1024;

    if (fileSize > maxSize) return false;
    if (fileType !== 'image') return false;
    return true;
  }

  handleReset = (isTrue, val) => {
    if (isTrue) {
      if (val === 'cover') {
        this.setState({
          imgFileCover: null,
          fileLoadedCover: false,
          errorFileCover: false,
          tempUrlCover: '',
        });
      } else if (val === 'ava') {
        this.setState({
          imgFile: null,
          fileLoaded: false,
          errorFile: false,
          tempUrl: '',
        });
      } else {
        this.setState({
          imgFileCover: null,
          fileLoadedCover: false,
          errorFileCover: false,
          tempUrlCover: '',
          imgFile: null,
          fileLoaded: false,
          errorFile: false,
          tempUrl: '',
        });
      }
    }

    this.setState({
      showCancelAva: false,
      showCancelCover: false
    });
  }

  handleSubmit = (val) => {
    const {
      updateProfile,
      getProfile,
    } = this.props;
    const {
      imgFile,
      imgFileCover,
    } = this.state;
    const payload = {
      file: val === 'ava' ? imgFile : null,
      background: val === 'cover' ? imgFileCover : null,
    };

    updateProfile(payload)
      .then(() => {
        getProfile()
          .then(() => this.handleReset('all'));
      });
  }

  render() {
    const {
      classes,
      profile,
      isProcessing,
      toggleEditModal,
    } = this.props;
    const {
      tempUrl,
      tempUrlCover,
      fileLoaded,
      fileLoadedCover,
      errorFile,
      errorFileCover,
      showCancelAva,
      showCancelCover,
    } = this.state;

    const profileImg = () => {
      if (tempUrl && fileLoaded) {
        return tempUrl;
      } else if  (profile.file) {
        return profile.file.full_path;
      } else {
        return imgAsset.noImg;
      }
    };

    const coverImg = () => {
      if (tempUrlCover && fileLoadedCover) {
        return tempUrlCover;
      } else if  (profile.background) {
        return profile.background.full_path;
      } else {
        return '';
      }
    };

    return (
      <div className={classes.header}>
        { isProcessing && <LoadingProcess /> }

        <ClosePrompt
          className='marB-20'
          open={showCancelAva}
          toggleDialog={val => this.handleReset(val, 'ava')}
          text="Are you sure want to cancel?"
          subText="(All draft will be lost)"
        />

        <ClosePrompt
          className='marB-20'
          open={showCancelCover}
          toggleDialog={val => this.handleReset(val, 'cover')}
          text="Are you sure want to cancel?"
          subText="(All draft will be lost)"
        />

        <div className={classes.headerProfile}>
          <div className={classes.pageName}>
            <h3>Profile</h3>
          </div>

          <div className={classes.avatarSection}>
            <Avatar
              className={classes.avatar}
              src={profileImg()}
              alt="avatar"
            />

            <div
              className={classes.btnEditGroup}
              style={{ justifyContent: fileLoaded
                ? 'space-between'
                : 'flex-end'
              }}
            >
              { !fileLoaded &&
                <ButtonUpload
                  id="uploadAva"
                  name="uploadAva"
                  title="Change profile picture"
                  onChange={this.handleUploadAva}
                />
              }
              { fileLoaded &&
                  <Fab
                    title="Cancel"
                    className={classes.btnSmall}
                    style={{ backgroundColor: '#e53935' }}
                    onClick={() => this.setState({ showCancelAva: true })}
                  >
                    <CloseIcon className={classes.btnIcon} />
                  </Fab>
              }
              { fileLoaded && !errorFile &&
                <Fab
                  title="Submit"
                  className={classes.btnSmall}
                  style={{ backgroundColor: '#388e3c' }}
                  onClick={() => this.handleSubmit('ava')}
                >
                  <CheckIcon className={classes.btnIcon} />
                </Fab>
              }
            </div>
          </div>

          { errorFile &&
            <p className={classes.error}>
              Please select image in jpg or png format (max 2MB)
            </p>
          }

          <div className={classes.profileInfo} style={{ marginTop: errorFile ? 0 : 6 }}>
            <h3>{profile.name}
              <span style={{ marginLeft: 8 }}>
                <Fab
                  title="Edit"
                  className={classes.btnSmall}
                  onClick={() => toggleEditModal()}>
                  <EditIcon className={classes.btnIcon} style={{ color: 'black' }}/>
                </Fab>
              </span>
            </h3>
            <h6>{profile.email}{profile.country && ` | ${profile.country.name}`}</h6>
          </div>
        </div>

        <div className={classes.uploadCover}>
          { !fileLoadedCover &&
            <ButtonUpload
              id="uploadCover"
              name="uploadCover"
              title="Change cover picture"
              onChange={this.handleUploadCover}
            />
          }

          { fileLoadedCover &&
            <Fab
              title="Cancel"
              className={classes.btnSmall2}
              style={{ backgroundColor: '#e53935' }}
              onClick={() => this.setState({ showCancelCover: true })}
            >
              <CloseIcon className={classes.btnIcon} />
            </Fab>
          }

          { fileLoadedCover && !errorFileCover &&
            <Fab
              title="Submit"
              className={classes.btnSmall}
              style={{ backgroundColor: '#388e3c' }}
              onClick={() => this.handleSubmit('cover')}
            >
              <CheckIcon className={classes.btnIcon} />
            </Fab>
          }

          <p
            className={classes.error2}
            style={{ visibility: errorFileCover ? 'visible' : 'hidden' }}
          >
            Please select image in jpg or png format (max 2MB)
          </p>
        </div>

        { (profile.background || tempUrlCover) &&
          <img className={classes.headerCover} src={coverImg()} alt="cover"/>
        }
      </div>
    );
  }
}

ProfileHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.shape({
    created_at: PropTypes.string,
    deleted_at: PropTypes.string,
    email: PropTypes.string,
    email_verified_at: PropTypes.string,
    file: PropTypes.object,
    file_id: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    position: PropTypes.string,
    role: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    updated_at: PropTypes.string,
  }),
  updateProfile: PropTypes.func,
  getProfile: PropTypes.func,
  isProcessing: PropTypes.bool,
  toggleEditModal: PropTypes.func,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers),
)(ProfileHeader);
