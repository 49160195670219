import * as types from './action.types';

const initialState = {
  isLoading: false,
  isProcessing: false,
  isLoadingMore: false,
  isError: {
    status: false,
    message: '',
  },
  listBackup: { data: [], total: 0 },
};

const loadMoreHelper = (newState, oldState) => ({
  ...newState,
  data: newState.current_page > 1
    ? [ ...oldState.data, ...newState.data ]
    : [ ...newState.data ]
});

const awardReducers = (state = initialState, action) => {
  switch (action.type) {
  case types.IS_LOADING: {
    return {
      ...state,
      isLoading: true
    };
  }
  case types.IS_LOADING_MORE: {
    return {
      ...state,
      isLoadingMore: true
    };
  }
  case types.IS_PROCESSING: {
    return {
      ...state,
      isProcessing: true,
    };
  }
  case types.IS_ERROR: {
    return {
      ...state,
      isLoading: false,
      isProcessing: false,
      isError: {
        status: true,
        message: action.data.message
      }
    };
  }
  case types.GET_LIST_BACKUP: {
    return {
      ...state,
      isLoading: false,
      isLoadingMore: false,
      listBackup: loadMoreHelper(action.data, state.listBackup),
    };
  }
  default:
    return state;
  }
};

export default awardReducers;
