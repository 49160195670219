import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import BottomDrawer from 'components/drawers/bottom-drawer';
import BaseButton from 'components/buttons/base-button/BaseButton';
import ApprovalMessage from '../approval-message-popup';

import ssaSvg from 'assets/icons/shining star appreciation.svg';
import { imgAsset } from 'helper/function/AssetImage';

import styles from './styles';
import dispatchers from '../../dispatchers';

class AwardApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgStyle: {},
      msgApptyle: {},
      isApproving: false,
      showApprovalReason: false,
    };
  }

  handleOpenApproval = value => {
    this.setState({
      showApprovalReason: true,
      isApproving: value === 'approved',
    });
  };

  handleCloseApproval = (isTrue, message) => {
    if (isTrue) this.handleSubmit(message);
    this.setState({ showApprovalReason: false });
  };

  handleSubmit = message => {
    const { approvalAwardGem, toggleDrawer, card } = this.props;
    const { isApproving } = this.state;
    const state = isApproving ? 'approved' : 'rejected';

    switch (state) {
      case 'approved': {
        approvalAwardGem({
          awardId: card.id,
          state,
          messageApproved: message,
        });
        return toggleDrawer();
      }
      case 'rejected': {
        approvalAwardGem({
          awardId: card.id,
          state,
          messageRejected: message,
        });
        return toggleDrawer();
      }
      default:
        break;
    }
  };

  handleMsgStyle = element => {
    if (element) {
      if (element.clientHeight > 15) {
        this.setState({
          msgStyle: { textAlign: 'left' },
        });
      } else {
        this.setState({
          msgStyle: { textAlign: 'center' },
        });
      }
    }
  };

  handleMsgAppStyle = element => {
    if (element) {
      if (element.clientHeight > 15) {
        this.setState({
          msgApptyle: { textAlign: 'left' },
        });
      } else {
        this.setState({
          msgApptyle: { textAlign: 'center' },
        });
      }
    }
  };

  render() {
    const { classes, openDialog, card, toggleDrawer } = this.props;
    const {
      msgStyle,
      msgApptyle,
      showApprovalReason,
      isApproving,
    } = this.state;

    const personAva = file => {
      if (file) return file.full_path;
      return imgAsset.noImg;
    };

    const isOpen = 'id' in card;

    return (
      <BottomDrawer
        fullHeight={true}
        openDialog={openDialog}
        toggleDrawer={toggleDrawer}
      >
        <div className={classes.container}>
          <ApprovalMessage
            open={showApprovalReason}
            isApproving={isApproving}
            toggleDialog={(val, payload) =>
              this.handleCloseApproval(val, payload)
            }
          />

          <div className={classes.header}>
            <img className={classes.headerLogo} src={ssaSvg} alt="icon" />

            <p>
              From
              <span> {isOpen && card.sender_detail.name} </span> to
              <span> {isOpen && card.recipient_detail.name}</span>
            </p>

            <div className={classes.personAva}>
              <img
                src={isOpen ? personAva(card.sender_detail.file) : ''}
                alt="avatar"
              />
              <h5>&rarr;</h5>
              <img
                src={isOpen ? personAva(card.recipient_detail.file) : ''}
                alt="avatar"
              />
            </div>
          </div>

          <div className={classes.content}>
            <div className={classes.formControl}>
              <h5>Appreciation</h5>
              {card.message &&
                card.message.split(/\r\n|\r|\n/g).map((value, index) => (
                  <p
                    key={index.toString()}
                    ref={this.handleMsgStyle}
                    style={msgStyle}
                  >
                    {value}
                  </p>
                ))}
            </div>

            <div className={classes.formControl}>
              <h5>Message for you</h5>
              {card.message_hod &&
                card.message_hod.split(/\r\n|\r|\n/g).map((value, index) => (
                  <p
                    key={index.toString()}
                    ref={this.handleMsgAppStyle}
                    style={msgApptyle}
                  >
                    {value}
                  </p>
                ))}
            </div>
          </div>
        </div>

        <div className={classes.actions}>
          <BaseButton
            label="Approve"
            color="primary"
            fullWidth={true}
            onClick={() => this.handleOpenApproval('approved')}
          />
          <BaseButton
            label="Decline"
            color="primary"
            variant="outlined"
            fullWidth={true}
            onClick={() => this.handleOpenApproval('rejected')}
          />
        </div>
      </BottomDrawer>
    );
  }
}

AwardApproval.propTypes = {
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  card: PropTypes.object,
  approvalAwardGem: PropTypes.func,
  showErrorAlert: PropTypes.func,
};

export default compose(
  withStyles(styles),
  connect(null, dispatchers)
)(AwardApproval);
