import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import HomeContent from './components/HomeContent';
import recognitionIcon from 'assets/icons/traveloka appreciation.svg';

import styles from './styles';
import states from './states';
import dispatchers from './dispatchers';

class Home extends Component {
  render() {
    const {
      classes,
      profile,
      title,
      content,
      isLoadingConfig,
    } = this.props;

    return (
      <div className={classes.root}>
        {/* <HomeHeader video={video} /> */}
        <div className={classes.header}>
          <img src={recognitionIcon} alt="Recognition Icon" />
        </div>

        <HomeContent
          profile={profile}
          title={title}
          content={content}
          isLoadingConfig={isLoadingConfig}
        />
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  getConfigs: PropTypes.func,
  profile: PropTypes.object,
  title: PropTypes.object,
  content: PropTypes.object,
  isLoadingConfig: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers)
)(Home);
