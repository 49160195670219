import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import renderHtml from 'helper/renderHtml';

import styles from './styles';

class ListingCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if((nextProps && nextProps.selCategory && nextProps.selCategory.length === 0 ) && this.state.active.length > 0) {
      this.setState({active: []});
    }
  }

  toggleDescription = (category) => {
    const { onSelect, deSelect } = this.props;
    const { active } = this.state;

    let isInclude = active.filter(x => x.id === category.id).length > 0;

    if(isInclude) {
      this.setState({active: active.filter(x => x.id !== category.id)});
      deSelect(category);
    } else {
      this.setState({active: [...active, category]});
      onSelect(category);
    }
  }

  render() {
    const { classes, categories } = this.props;
    const { active } = this.state;

    const isSelected = (val) => {
      let status = false;
      active.map(stat => {
        if(stat.id === val.id) status = true;

        return stat;
      });

      return status;
    };

    return (
      <div className={classes.listingCategory}>
        { 
          categories && categories.map((category, index) => (
            <div key={`Category-${index}`} className={classes.category}>
              <Fab
                className={isSelected(category)
                  ? classes.buttonActive
                  : classes.button
                }
                classes={{ label: classes.label }}
                variant="extended"
                onClick={() => this.toggleDescription(category)}
              >
                {category.name}
              </Fab>
              <div className={isSelected(category) ? classes.description : classes.hide}>
                <p dangerouslySetInnerHTML={renderHtml({ content: category.description })} />
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

ListingCategory.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.array,
  onSelect: PropTypes.func,
  deSelect: PropTypes.func,
  selCategory: PropTypes.array,
};

export default withStyles(styles)(ListingCategory);
