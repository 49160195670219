import React from 'react';
import PropTypes from 'prop-types';
import {OwnIcon} from 'helper';

const FileUplouderCustom=(props)=>{
  const {
    title,
    accept,
    isFetching,
    isProcessing,
    isButtonHide,
    onFileSelect,
    onFileUploud,
    labelCustomStyle,
  } = props;

  const onUploud = () => {
    if (!isFetching) {
      onFileUploud();
    }
  };

  return (
    <div className='dFlex justStart dirRow alignStart'>
      <label className='btnUploud dFlex justCenter alignCenter anim' style={{...labelCustomStyle}}>
        <div className='btnUploudText'>{title}</div>
        <input
          id='searchRef'
          type='file'
          name="upload"
          accept={accept}
          onChange={onFileSelect}
        />
      </label>
      {isButtonHide && <OwnIcon.IconDownload title="Upload File" className='gray btnUploudPush anim' onClick={onUploud}/>}
      {isProcessing && <label className='uploudingTextLoading'>Uploading...</label>}
    </div>
  );
};

FileUplouderCustom.defaultProps={
  onFileSelect:()=>null,
  onFileUploud:()=>null,
  accept:'',
  isProcessing:false,
  isButtonHide:false,
  labelCustomStyle: {},
};

FileUplouderCustom.propTypes = {
  title: PropTypes.string,
  accept: PropTypes.string,
  isFetching: PropTypes.bool,
  isProcessing: PropTypes.bool,
  isButtonHide: PropTypes.bool,
  onFileSelect: PropTypes.func,
  onFileUploud: PropTypes.func,
  labelCustomStyle: PropTypes.object
};

export {FileUplouderCustom};
