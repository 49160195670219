import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import renderHtml from 'helper/renderHtml';

import BaseButton from 'components/buttons/base-button/BaseButton';
import LoadingEllipsis from 'components/loadings/loading-ellipsis';

import styles from '../styles';

class HomeContent extends Component {
  render() {
    const {
      classes,
      profile,
      title,
      content,
      isLoadingConfig,
    } = this.props;

    const menuPrivilege = privilege => {
      if (privilege.includes(profile.role)) return true;
      return false;
    };

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.card}>
            { isLoadingConfig && <LoadingEllipsis /> }
            { title && <h3>{title.value}</h3> }
            { content &&
              <p
                className={classes.description}
                dangerouslySetInnerHTML={renderHtml({ content: content.value })}
              />
            }
          </div>
          
          <div className={classes.appreciation}>
            <h3>Submission Shortcut: </h3>
            <Link to="/appreciation/all-around">
              <BaseButton
                label="Give All-Around Appreciation"
                color="primary"
                fullWidth={true}
              />
            </Link>
            { 
              menuPrivilege(['lead', 'hod', 'recognition_team', 'ceo']) &&
              <Link to="/appreciation/shining-star">
                <BaseButton
                  label="Give Shining Star Appreciation"
                  color="primary"
                  fullWidth={true}
                />
              </Link>
            }
          </div>
        </div>
      </div>
    );
  }
}

HomeContent.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object,
  title: PropTypes.object,
  content: PropTypes.object,
  isLoadingConfig: PropTypes.bool,
};

export default withStyles(styles)(HomeContent);
