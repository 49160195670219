import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import {
  FileUplouderCustom,
  SearchBox,
  LoaderIndicator,
  UplouderFileIndicator,
  FileDownloaderCustom
} from 'components/';
import NoData from 'components/data-handler/no-data';
import ListingEmployee from 'components/listing/listing-employee';
import {OwnIcon} from 'helper';
import FilterDrawer from './FilterDrawer';
import {
  GetEmployee,
  UploudEmployee,
  DownloadEmployee,
  Employee
} from 'store/employee/actions';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import {
  showSuccessAlert,
  showErrorAlert,
} from 'store/commons/actions';
import EmployeeEdit from './EmployeeEdit';
import styles from '../styles';

class HomeContent extends Component {
  constructor(props) {
    super(props);
    this.state =  {
      page: 1,
      limit: 10,
      keyword: '',
      isGetLoad: false,
      hasMore: false,
      dataEmployee: { data:[] },
      isFetchingData: true,
      filePath: null,
      isUplouding: false,
      isFetchUploudFile: false,
      isErrorUploudFile: false,
      isReadeyUploudFile: false,
      errorText: null,
      uploudProgressValue: 0,
      isFilterOpen: false,
      isFocusSearch: false,
      roleList: [
        { title: 'CEO', value: 'ceo', isSelected: true },
        { title: 'HoD', value: 'hod', isSelected: true },
        { title: 'Team Lead', value: 'lead', isSelected: true },
        { title: 'Recognition Team', value: 'recognition_team', isSelected: true },
        { title: 'Staff', value: 'employee', isSelected: true },
      ],
      statusList: [
        { title: 'Active', value: 'active', isSelected: true },
        { title: 'Inactive', value: 'inactive', isSelected: true },
      ],
      inputJoinDate: false,
      dateFrom: '2010-01-01',
      dateTo: format(new Date(), 'YYYY-MM-DD'),
      downloadLoading: false,
      editModalOpen: false,
      selectedEmployee: {},
    };
    this.tempOnSearch = null;
    this.time = 0;
  }

  componentDidMount = () => {
    this.onFirstTimeDataLoad();
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.id !== prevProps.profile.id) {
      this.handleUserAuth();
    }
  }

  handleUserAuth() {
    const { history, profile } = this.props;
    const privilege = ['recognition_team'];
    if (!privilege.includes(profile.role)) return history.push('/');
  }

  onFirstTimeDataLoad=()=>{
    this.setState({
      keyword: '',
      isFetchingData: true,
      page: 1,
      hasMore: false,
      isGetLoad: false,
      dataEmployee: { data:[] },
    }, () => {
      this.onGetEmployee();
    });
  }

  onGetEmployee = (value) => {
    value = value ? value : '';
    const { state, props } = this;

    const role = state.roleList
      .filter(role => role.isSelected)
      .map(role => role.value)
      .join(',');

    const status = state.statusList
      .filter(status => status.isSelected)
      .map(status => status.value)
      .join(',');

    this.setState({ page: 1 }, () => {
      GetEmployee({
        token: props.userToken,
        limit: state.limit,
        page: state.page,
        keyword: value,
        role,
        status,
        inputJoinDate: state.inputJoinDate,
        dateTo: state.dateTo,
        dateFrom: state.dateFrom,
      },({data,isError,isFound,isFetching})=>{
        if(isFound){
          this.breakUpData(data.data.data, (dataEmployee) => {
            this.setState({
              dataEmployee: { data:dataEmployee },
              isFetchingData:false,
              page: 2,
            });

            const payload = {
              data: dataEmployee,
              total: data.data.total,
            };

            props.Employee({payload,isFetching,isFound,isError}).then(()=>{
              if(data.data.data.length>=10){
                this.setState({hasMore: true });
              }else{
                this.setState({hasMore: false });
              }
            });
          });
        }else if(isError){
          props.Employee({payload: null,isFetching,isFound,isError}).then(()=>{
          });
        }
      });
    });
  }

  onLoadMoreGetDataSummary=()=>{
    const {state,props} = this;
    if(!state.isGetLoad){
      this.setState({isGetLoad:true},()=>{
        const role = state.roleList
          .filter(role => role.isSelected)
          .map(role => role.value)
          .join(',');

        const status = state.statusList
          .filter(status => status.isSelected)
          .map(status => status.value)
          .join(',');

        let page = state.page;
        page =page+1;
        GetEmployee({
          token: props.userToken,
          limit: state.limit,
          page: state.page,
          keyword: state.keyword,
          role,
          status,
          inputJoinDate: state.inputJoinDate,
          dateTo: state.dateTo,
          dateFrom: state.dateFrom,
        },({data,isError,isFound,isFetching})=>{
          if(isFound){
            this.breakUpData(data.data.data,(dataEmployee)=>{
              let result = state.dataEmployee.data.concat(dataEmployee);
              this.setState({dataEmployee:{data:result},isGetLoad:false,page:page});

              const payload = {
                data: dataEmployee,
                total: data.data.total,
              };

              props.Employee({payload,isFetching,isFound,isError}).then(()=>{
                if(data.data.data.length>=10){
                  this.setState({hasMore:true});
                }else{
                  this.setState({hasMore:false});
                }
              });
            });
          }else if(isError){
            props.Employee({payload: null,isFetching,isFound,isError}).then(()=>{
            });
          }
        });

      });
    }
  }

  onSearch=(value)=>{
    // const {state} = this;
    this.setState({
      keyword:value,
      isFetchingData:true,
      page:1,
      hasMore:false,
      isGetLoad:false
    }, () => {
      clearTimeout(this.tempOnSearch);
      this.tempOnSearch=setTimeout(()=>{
        this.onGetEmployee(value);
      },300);
    });
  }

  onUploudFile=()=>{
    if(this.state.filePath.name){
      this.setState({isFetchUploudFile:true,isErrorUploudFile:false,errorText:null});
      UploudEmployee({token:this.props.userToken,data:this.state.filePath},({data,isFound,isError,onLoading})=>{
        this.setState({uploudProgressValue:onLoading});
        if(isFound){
          this.setState({isErrorUploudFile:false,isFetchUploudFile:false,uploudProgressValue:0,isReadeyUploudFile:false,filePath:''});
          this.props.showSuccessAlert('Import employee data success!');
          this.onFirstTimeDataLoad();
        }else if(isError){
          this.props.showErrorAlert('Import employee data failed!');
          this.setState({isErrorUploudFile:true,isFetchUploudFile:false,errorText:data.message,uploudProgressValue:0});
        }
      });
    }
  }

  onDownloadFile=()=>{
    this.setState({downloadLoading: true});
    const {dateFrom, dateTo, keyword, roleList, statusList} = this.state;

    let params = {
      date_from: dateFrom,
      date_to: dateTo,
      role: roleList.filter(role => role.isSelected).map(role => role.value).join(','),
      status: statusList.filter(status => status.isSelected).map(status => status.value).join(','),
    };

    if(keyword) params = {...params, search: keyword};  

    DownloadEmployee({token: this.props.userToken, params}, (response) => {
      let url = response.data.data.url;

      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.setState({downloadLoading: false});
    });
  }

  onFileSelectCustom = e => {
    const file = e.target.files[0];
    e.target.value = null;

    if (file) {
      this.setState({
        filePath: file,
        isReadeyUploudFile: true,
        isErrorUploudFile: false,
        isFileError: false,
      });
    } else {
      this.setState({
        filePath: '',
        isReadeyUploudFile: false,
        isFileError: true,
        isErrorUploudFile: true,
      });
    }
  }

  onCLosed=()=>{
    this.setState({
      filePath: null,
      isReadeyUploudFile: false,
      isErrorUploudFile: false,
      isFileError: false,
      isFetchUploudFile: false
    });
  }

  breakUpData=(data,callback)=>{
    for (var i = 0; i < data.length; i++) {
      data[i]={
        ...data[i],
        isCollapse:false,
      };
    }
    callback(data);
  }

  onCollapse=(index)=>{
    let dataEmployee=this.state.dataEmployee.data;
    if(dataEmployee[index].isCollapse){
      dataEmployee[index].isCollapse=false;
    }else{
      dataEmployee[index].isCollapse=true;
    }
    this.setState({dataEmployee:{data:dataEmployee}});
  }

  toggleFocusSearch = () => {
    this.setState((prevState) => ({
      isFocusSearch: !prevState.isFocusSearch,
    }));
  }

  toggleFilterDrawer = () => {
    this.setState((prevState) => ({
      isFilterOpen: !prevState.isFilterOpen,
    }));
  }

  setEditModalOpen = (bool) => {
    this.setState(() => ({
      editModalOpen: bool,
    }));
  }

  setSelectedEmployee = (selEmployee) => {
    this.setState(() => ({
      selectedEmployee: selEmployee,
    }));
  }

  handleEmployeeEditClick = (employee) => {
    this.setSelectedEmployee(employee);
    this.setEditModalOpen(true);
  };

  handleApplyFilter = (payload) => {
    const { dateFrom, dateTo } = this.state;

    this.setState(() => {
      if (payload.dateTo !== dateTo || payload.dateFrom !== dateFrom) {
        return {
          inputJoinDate: true,
          dateFrom: payload.dateFrom,
          dateTo: payload.dateTo,
          roleList: payload.roleList,
          statusList: payload.statusList,
          page: 1,
        };
      } else {
        return {
          roleList: payload.roleList,
          statusList: payload.statusList,
          page: 1,
        };
      }
    }, () => {
      this.onGetEmployee();
    });
  }

  render() {
    const { state, props } = this;
    const searchClass = `anim ${state.isFocusSearch
      ? 'animWidth'
      : 'animNoWidth'
    }`;
    const { classes } = props;

    return (
      <div className="mainContainer dFlex justCenter dirColumn">
        <div className="pad-10 dFlex justSpace">
          <div className="dFlex alignCenter">
            <OwnIcon.IconFilter
              className="gray summaryBtn anim summaryBtnMarRight"
              onClick={this.toggleFilterDrawer}
            />
            {!state.isFocusSearch && 
            <FileUplouderCustom
              title="Import"
              onFileSelect={this.onFileSelectCustom}
              accept="text/csv"
              onFileUploud={this.onUploudFile}
              isFetching={state.isFetchUploudFile}
              isButtonHide={state.isReadeyUploudFile}
              labelCustomStyle={{marginRight: '15px'}}
            />
            }

            
            <FileDownloaderCustom 
              isLoading={state.downloadLoading}
              onButtonClick={this.onDownloadFile} 
            />
            
          </div>
          <SearchBox
            onSearch={this.onSearch}
            placeholder="Search Employee"
            className={searchClass}
            onClick={this.toggleFocusSearch}
            onBlur={this.toggleFocusSearch}
          />
        </div>
        <UplouderFileIndicator
          file={state.filePath}
          uploudProgressValue={state.uploudProgressValue}
          isShow={state.isReadeyUploudFile}
          isError={state.isErrorUploudFile}
          isLoading={state.isFetchUploudFile}
          onCLosed={this.onCLosed}
          errorText={state.errorText}
        />
        <div className='dFlex justCenter dirColumn bgGrayF1'>
          <LoaderIndicator isShow={state.isFetchingData}/>
          <InfiniteScroll
            threshold={0}
            useWindow={true}
            pageStart={0}
            loadMore={()=>this.onLoadMoreGetDataSummary()}
            hasMore={state.hasMore}
            loader={<LoaderIndicator key={'loaderEmployee'}isShow={state.isGetLoad} />}
          >
            <ListingEmployee
              key='employeeList'
              dataList={state.dataEmployee.data}
              onCollapse={this.onCollapse}
              token={props.userToken}
              employeeEditClick={this.handleEmployeeEditClick}
            />
          </InfiniteScroll>

          <NoData text="No data found." isShow={state.dataEmployee.data.length<1 && !state.isFetchingData}/>
        </div>

        <FilterDrawer
          isOpen={state.isFilterOpen}
          toggleDrawer={this.toggleFilterDrawer}
          dateFrom={state.dateFrom}
          dateTo={state.dateTo}
          roleList={state.roleList}
          statusList={state.statusList}
          applyFilter={payload => this.handleApplyFilter(payload)}
        />
        
        <Modal
          open={state.editModalOpen}
          onClose={() => this.setEditModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.boxModal}>
            <EmployeeEdit
              closeEditModal={() => this.setEditModalOpen(false)}
              employee={state.selectedEmployee}
              onSuccessUpdate={() => this.onFirstTimeDataLoad()}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

HomeContent.propTypes = {
  classes: PropTypes.object.isRequired,
  userToken: PropTypes.string,
  showErrorAlert: PropTypes.func,
  showSuccessAlert: PropTypes.func,
  employeeEditClick: PropTypes.func,
  history: PropTypes.object,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userToken:state.user.auth.access_token,
  employee:state.Employee,
  profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
  Employee: (data) => dispatch(Employee(data)),
  showSuccessAlert: (msg) => dispatch(showSuccessAlert(msg)),
  showErrorAlert: (msg) => dispatch(showErrorAlert(msg)),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(HomeContent);
