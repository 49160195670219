import React from 'react';

class InputBox extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      isFocus:(this.props.value===''|| this.props.value===null)?false:true,
      textValue:this.props.value
    }
  }
  onTextBlur=()=>{
    if(this.state.textValue==='' || this.state.textValue===null){
      this.setState({isFocus:false})
    }
    // console.log(this.state.textValue);
  }
  onTextChage=(event)=>{
    this.setState({textValue:event.target.value});
    this.props.onInput(event.target.value)
  }
  componentWillReceiveProps=(nextProps)=>{
    if(nextProps.value!==this.props.value){
      this.setState({isFocus:(nextProps.value===''|| nextProps.value===null)?false:true})
    }
  }
  render(){
    if(this.props.isTextArea){
      return(
        <div className='dFlex justCenter dirColumn inputTextBoxContainer'>
          <div className={`inputTextBoxInside dFlex justStart alignCenter bgGrayF1 ${!this.props.isBorder?'inputNoBorder':''}`}  >
            <textarea type='text' className='inputTextBox '  rows={this.props.rows}
              onFocus={()=>this.setState({isFocus:true})}
              onChange={this.onTextChage}
              onBlur={this.onTextBlur}
              value={this.props.value}
            />

            <label className={`anim inputTextBoxTitle inputUnFocus ${this.state.isFocus?'inputFocus':''}`}>{this.props.title}</label>
          </div>
        </div>
      )
    }else{
      return(
        <div className='dFlex justCenter dirColumn inputTextBoxContainer'>
          <div className={`inputTextBoxInside dFlex justStart alignCenter bgGrayF1 ${!this.props.isBorder?'inputNoBorder':''}`} >
            <input type='text' className='inputTextBox gray'
              onFocus={()=>this.setState({isFocus:true})}
              onChange={this.onTextChage}
              onBlur={this.onTextBlur}
              value={this.props.value}
            />

            <label className={`anim inputTextBoxTitle inputUnFocus ${this.state.isFocus?'inputFocus':''}`}>{this.props.title}</label>
          </div>
        </div>
      )
    }
  }
}
InputBox.defaultProps={
  title:'Text Input',
  onInput:()=>null,
  isTextArea:false,
  rows:10,
  isBorder:true,
  value:'',
};
export {InputBox};
