const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.5rem',
    backgroundColor:'#fff',
    borderRadius: '10px 10px 0 0',
    flex:1,
    minHeight: 0,
  },
  header: {
    textAlign: 'center',
    padding: '.6rem',
    '& img': {
      width: 90,
      height: 90,
    },
    '& h3': {
      margin: '.8rem',
      color: theme.palette.primary.main,
    }
  },
  content: {
    textAlign: 'center',
    width: '100%',
    '& h4': {
      fontWeight: 600,
      color: theme.palette.text.dark,
    },
    '& h5': {
      margin: '.3rem',
      fontWeight: 600,
      color: theme.palette.text.dark,
    },
    '& p': {
      // textAlign: 'left',
      fontWeight: 400,
      color: theme.palette.text.dark,
      fontSize: '.8rem',
      marginTop: '6px !important',
    },
    overflow:'hidden',
    overflowY:'auto',
    // Customize the scrollbar on category-box div element
    // Add 'display: none' on the -webkit-scrollbar class to hide it
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: '#dadada',
      bordeRadius: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#afafaf',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#9b9b9b',
    },
  },
  chooseCategory: {
    marginBottom: '1.5rem',
  },
  chooseCategory2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
    '& h4': {
      fontSize: '.9rem',
    }
  },
  cardList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    '& img': {
      width: 90,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  categoryName: {
    width: 'max-content',
    margin: '4px 0',
    textTransform: 'none',
    backgroundColor: '#FFF',
    position: 'relative',
    overflowY: 'hidden',
    padding: '.8rem',
    textAlign: 'center',
    border: 'none',
    borderRadius: 4,
    boxShadow: '2px 4px 10px 0px rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h4': {
      margin: 0,
      color: theme.palette.title.main,
      position: 'relative',
      zIndex: 1,
      fontWeight: 500,
      fontFamily: 'Museo Sans'
    },
    marginRight: '10px'
  },
  categoryItem: {
    cursor: 'pointer',
    width: '48%',
    margin: '4px 0',
    textTransform: 'none',
    backgroundColor: '#FFF',
    position: 'relative',
    overflowY: 'hidden',
    padding: '.8rem',
    textAlign: 'center',
    border: 'none',
    borderRadius: 4,
    boxShadow: '2px 4px 10px 0px rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h4': {
      margin: 0,
      color: theme.palette.title.main,
      position: 'relative',
      zIndex: 1,
      fontWeight: 500,
      fontFamily: 'Museo Sans'
    },
    '&:hover': {
      backgroundColor: '#F0F0F0',
    }
  },
  imgDefault: {
    position: 'absolute',
    objectFit: 'center',
    width: '100%',
    opacity: 0.2,
    top: 0,
    left: 0,
    height: '100%',
  },
  imgActive: {
    position: 'absolute',
    objectFit: 'center',
    width: '100%',
    filter: 'brightness(50%)',
    top: 0,
    left: 0,
    height: '100%',
  },
  categoryDetail: {
    backgroundColor: theme.palette.secondary.main,
    margin: '1rem 0',
    marginBottom: 0,
    padding: '1rem',
    textAlign: 'left',
    '& h4': {
      color: theme.palette.text.main,
      fontWeight: 600,
      margin: 0,
    },
    '& p': {
      color: theme.palette.text.main,
      fontSize: '.9rem',
      whiteSpace: 'pre-wrap',
    }
  },
  anonymousSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  actions: {
    backgroundColor: theme.palette.secondary.main,
    padding: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      fontWeight: 400,
      color: theme.palette.text.dark,
      fontSize: '.8rem',
    },
    '& span': {
      fontWeight: 600,
    }
  },
  btnBack: {
    fontSize: '.8rem',
    color: theme.palette.primary.main,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  error: {
    textAlign: 'center !important',
    color: 'red !important',
    fontSize: '.8rem',
  },
  wrapperCategory: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingLeft: '10px',
    marginBottom: '10px',
  }
});

export default styles;
