const styles = (theme) => ({
  drawerSummary: {
    transform: 'initial !important',
    overflow:'hidden',
    overflowY: 'hidden',
    '& .MuiPaper-root-16':{
      overflow:'hidden',
      overflowY: 'hidden',
    }
  },
  drawerHeader: {
    padding: '0',
    width: '18rem',
    display: 'flex',
    '& button': {
      marginLeft: 'auto'
    },
  },
  drawerMenu: {
    paddingRight: '2rem',
    height:'100%',
    '& a': {
      textDecoration: 'none',
    }
  },
  menuText: {
    '& span': {
      color: theme.palette.text.main,
      fontSize: '.8rem',
    }
  },
  menuIcon: {
    margin: 0,
    '& img': {
      width: '1rem',
      height: '1rem',
    },
  },
  divider: {
    margin: '1rem 0rem',
  }
});

export default styles;
