const styles = theme => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    textAlign: 'center',
    padding: '1.3rem 0',
    color: theme.palette.primary.main,
    '& h3': {
      margin: 0,
      fontWeight: 700,
    }
  },
  content: {
    width: '80%',
    minHeight: '90vh',
    padding: '1rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& span': {
      fontSize: '1rem',
    },
  },
  btnBack: {
    border: 'none',
    backgroundColor: 'transparent',
    alignSelf: 'start',
    marginBottom: '1rem',
    textDecoration: 'none',
    color: theme.palette.secondary.dark,
    fontSize: '.8rem',
    cursor: 'pointer',
  },
  exportTarget: {
    width: 1700/4,
    '& button': {
      margin: 0,
    }
  },
  groupMonth: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actions: {
    marginTop: '1.5rem',
    width: '100%',
    '& p': {
      margin: '.5rem 0',
      fontSize: 12,
      color: theme.palette.secondary.dark,
    }
  },
  imageCreated: {
    width: '100%',
  }
});

export default styles;
