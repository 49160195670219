import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import LoadingEllipsis from 'components/loadings/loading-ellipsis';
import CardPendingItem from 'components/cards/card-pending';
import BaseButton from 'components/buttons/base-button/BaseButton';
import LoadingProcess from 'components/loadings/fullscreen-spin';
import AwardApproval from './components/award-approval';
import NoData from 'components/data-handler/no-data';

import dispatchers from './dispatchers';
import states from './states';
import styles from './styles';

class CardPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      openDialog: false,
      selectedCard: {},
    };
  }

  componentDidMount() {
    this.handleUserAuth();
  }

  handleUserAuth() {
    const {
      history,
      profile,
    } = this.props;
    const privilege = ['hod', 'ceo'];

    if (!privilege.includes(profile.role)) return history.push('/');
    this.handleFetchAwards();
  }

  handleToggleDrawer = (payload = {}) => {
    this.setState(prevState => ({
      openDialog: !prevState.openDialog,
      selectedCard: payload,
    }));
  }

  handleFetchAwards = () => {
    const { getPendingList } = this.props;
    const { page } = this.state;

    getPendingList({
      isLoadMore: (page > 1),
      page,
    });
    this.setState(prevState => ({
      page: prevState.page + 1
    }));
  }

  render() {
    const {
      classes,
      isLoading,
      isLoadingMore,
      isProcessing,
      listPending,
    } = this.props;
    const {
      openDialog,
      selectedCard,
    } = this.state;

    return (
      <div className={classes.root}>
        { isProcessing && <LoadingProcess /> }

        <div className={classes.container}>
          <div className={classes.header}>
            <h3>Card Pending ({listPending.total})</h3>
          </div>

          <div className={classes.content}>
            <div className={classes.groupMonth}>
              { isLoading && <LoadingEllipsis/> }
              {
                !isLoading && listPending.data.map(card => (
                  <CardPendingItem
                    key={card.id}
                    data={card}
                    onClick={() => this.handleToggleDrawer(card)}
                  />
                ))
              }

              <NoData
                isShow={(!isLoading && !listPending.data.length)}
                text="You have no pending Shining Star Appreciation request."
                type="gem"
              />
            </div>

            { isLoadingMore && <LoadingEllipsis /> }
            {
              !isLoading && !isLoadingMore && listPending.next_page_url &&
              <BaseButton
                label="Load more"
                onClick={this.handleFetchAwards}
              />
            }
          </div>

          <AwardApproval
            openDialog={openDialog}
            toggleDrawer={() => this.handleToggleDrawer()}
            card={selectedCard}
          />
        </div>
      </div>
    );
  }
}

CardPending.propTypes = {
  classes: PropTypes.object.isRequired,
  getPendingList: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  isProcessing: PropTypes.bool,
  listPending: PropTypes.object,
  history: PropTypes.object,
  profile: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers),
)(CardPending);
