import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BaseDialog from 'components/dialogs/base-dialog';
import errorSvg from 'assets/icons/error-round.svg';
import { closeErrorAlert } from 'store/commons/actions';

import './custom.css';

const ErrorAlert = (props) => {
  const { showError, closeErrorAlert, errorMsg } = props;

  return (
    <BaseDialog open={showError} toggleDialog={closeErrorAlert}>
      <div style={{ padding: '1rem', textAlign: 'center' }}>
        <img
          id="pulseError"
          style={{ width: '5rem', margin: '1rem' }}
          src={errorSvg}
          alt="icon"
        />
        <h3 style={{ textAlign: 'center', color: '#4D4D4D' }}>
          {errorMsg}
        </h3>
      </div>
    </BaseDialog>
  );
};

ErrorAlert.defaultProps = {
  showError: false,
  errorMsg: 'Error!',
};

ErrorAlert.propTypes = {
  showError: PropTypes.bool,
  errorMsg: PropTypes.string,
  closeErrorAlert: PropTypes.func,
};

const mapStateToProps = (state) => ({
  showError: state.commons.showError,
  errorMsg: state.commons.errorMsg,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeErrorAlert,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorAlert);