import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';

const BottomDrawer = props => {
  const {
    children,
    classes,
    openDialog,
    toggleDrawer,
    fullHeight,
  } = props;

  return (
    <Drawer
      anchor="bottom"
      open={openDialog}
      onClose={toggleDrawer}
      classes={{
        root: classes.root,
        paper: classes.paper,
        paperAnchorBottom: !fullHeight
          ? classes.paperAnchorBottom
          : '',
      }}
    >
      <Fab
        onClick={toggleDrawer}
        className={classes.btnClose}
        color="secondary"
        aria-label="Close"
      >
        <div className={classes.btnIcon}>
          <CloseIcon />
        </div>
      </Fab>
      <div className="modalContainerContentOutside dFlex justStart dirColumn">{children}</div>
    </Drawer>
  );
};

BottomDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  fullHeight: PropTypes.bool,
};

export default withStyles(styles)(BottomDrawer);
