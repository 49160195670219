import React from 'react';
//import { Link } from 'react-router-dom';
import {OwnIcon} from 'helper';
import {CustomButton} from 'components';
import renderHtml from 'helper/renderHtml';

const ListingCategoryChild =({dataList,onEdit})=>{
  const checkStatus = (val) => {
    switch (val) {
    case 'active': return 'bgSent';
    case 'inactive': return 'bgDeclined';
    default: break;
    }
  };

  if (dataList.length>0) {
    return(
      dataList.map((item,index)=>{
        return(
          <div key={index.toString()+'chiled'} className='dFlex justCenter dirColumn categoryListContainer width anim'>
            <div className='dFlex justCenter dirColumn alignCenter categoryListContainerInside '>
              {/* <img src={img} className='categoryListImgBackground bgGrayF1' alt="background" /> */}
              <div className='white dFlex justCenter alignCenter categoryListTitle'>
                <label>{item.name}</label>
                <div className={`employeeState ${checkStatus(item.status)}`}>{item.status}</div>
              </div>
              <div className='dFlex justCenter alignCenter categoryListBtnEdit anim' onClick={()=>onEdit(item)}>
                <OwnIcon.IconEdit className='white categoryListBtnEditicon anim' />
              </div>
            </div>
          </div>
        );
      })
    );
  } else {
    return(null);
  }
};

const ListingCategory=({dataList,token,onOpenDrawerEdit,onOpenDrawerAdd,onCollapse,onOpenDrawerEditAwards})=>{
  if(dataList.length>0){
    return(
      dataList.map((item,index)=>{
        let childList = [];

        if(item.categories_detail){
          childList = item.categories_detail;
        }

        let tempItem={
          ...item,
          categories_detail:[],
        };

        const titleName = item.name;

        return(
          <div className='pad-10 dFlex justCenter dirColumn bgGrayF1 mar30' key={index.toString()+'main'}>
            <div className='categoryHeaderContainer'>
              <div className='dFlex justSpace alignCenter mb-12'>
                <label className='blue categoryHeaderTitle'>{titleName}</label>
                <CustomButton
                  className="customBtn"
                  title="Edit Card"
                  onClick={()=>onOpenDrawerEditAwards(tempItem)}
                />
              </div>
              <label
                className='categoryHeaderTitleBottom'
                dangerouslySetInnerHTML={renderHtml({ content: item.description})}
                style={{ whiteSpace: 'pre-wrap' }}
              />
            </div>
            
            {childList.length>0 &&
              <ListingCategoryChild
                dataList={childList}
                onEdit={onCollapse}
                token={token}
                //eslint-disable-next-line
                onEdit={onOpenDrawerEdit}
              />
            }
            {childList.length>0 &&
              <div className='dFlex justEnd alignCenter'>
                <div className='blue btnAddCategory anim' onClick={()=>onOpenDrawerAdd(tempItem)}>+ Add New Category</div>
              </div>
            }
          </div>
        );
      })
    );
  }else{
    return(null);
  }
};

ListingCategory.defaultProps={
  dataList:[],
  onEdit:()=>null,
  onCollapse:()=>null,
  onOpenDrawerEdit:()=>null,
  onOpenDrawerAdd:()=>null,
  onOpenDrawerEditAwards:()=>null,
};

export {ListingCategory};
