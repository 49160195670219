const styles = (theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  header: {
    position: 'relative',
    overflowY: 'hidden',
    backgroundColor: 'lightgrey',
    height: 230,
  },
  headerCover: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    filter: 'brightness(55%)',
    objectFit: 'cover',
  },
  headerProfile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.6rem 0',
    position: 'relative',
    zIndex: 1,
  },
  pageName: {
    display: 'flex',
    '& h3': {
      margin: '0px 4px 8px 0px',
      color: '#FFFFFF',
      fontWeight: 700,
    }
  },
  avatar: {
    width: 72,
    height: 72,
  },
  avatarSection: {
    position: 'relative',
    margin: '14px 0 6px 0',
  },
  profileInfo: {
    textAlign: 'center',
    margin: 0,
    '& h3': {
      margin: 0,
      fontWeight: 400,
      color: '#FFFFFF',
    },
    '& h6': {
      margin: 0,
      fontWeight: 700,
      color: theme.palette.text.light,
    }
  },
  btnEditGroup: {
    display: 'flex',
    position: 'absolute',
    bottom: -5,
    right: 0,
    width: '100%',
  },
  btnSmall: {
    width: 20,
    height: 20,
    minHeight: 0,
    color: '#FFF',
  },
  btnSmall2: {
    width: 20,
    height: 20,
    minHeight: 0,
    color: '#FFF',
    marginRight: 4,
  },
  btnIcon: {
    width: 12,
    height: 12,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding: '2rem',
    minHeight: 400,
    '& a': {
      width: '100%',
      textDecoration: 'none',
    }
  },
  buttonGroup: {
    display: 'flex',
    width: '100%',
    maxWidth: 400,
    justifyContent: 'space-between',
  },
  buttonMenu1: {
    width: '100%',
    marginRight: 4,
  },
  buttonMenu2: {
    width: '100%',
    marginLeft: 4,
  },
  uploadCover: {
    zIndex: 1,
    position: 'absolute',
    bottom: 8,
    left: 8,
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    color: 'red !important',
    fontSize: '.7rem',
    textAlign: 'center !important',
    textShadow: '1px 1px 1px rgba(0,0,0,.5)',
    margin: '6px 0',
  },
  error2: {
    color: 'red !important',
    fontSize: '.7rem',
    textAlign: 'center !important',
    textShadow: '1px 1px 1px rgba(0,0,0,.5)',
    margin: 0,
    marginLeft: 4,
  },

  //Profile Edit
  boxModal: {
    backgroundColor: 'white',
    borderRadius: 4,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  editContainer: {
    padding: 8,
  },
  editForm: {
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    marginTop: 8,
    marginBottom: 8,
  }
});

export default styles;
