const styles = (theme) => ({
  root: {
    padding: 0,
  },
  header: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    position: 'fixed',
    top: 0,
    left: 'unset',
    right: 'unset',
  },
  toolbar: {
    padding: '0rem .6rem',
    position: 'relative',
    maxWidth: 509,
  },
  path: {
    color: theme.palette.text.main,
  },
  icon: {
    position: 'absolute',
    right: 0
  },
});

export default styles;