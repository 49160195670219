import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardAppreciation from '../../../components/cards/card-appreciation';
import LoadingEllipsis from '../../../components/loadings/loading-ellipsis';
import BaseButton from '../../../components/buttons/base-button/BaseButton';
import CategoryFilter from './components/CategoryFilter';
import NoData from 'components/data-handler/no-data';
import dispatchers from './dispatchers';
import states from './states';
import styles from './styles';

class CardReceived extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 'all',
      page: 1,
    };
  }

  componentDidMount() {
    this.handleFetchAwards();
    this.handleFetchTotalCount();
  }

  handleFetchAwards = () => {
    const { getHistoryList } = this.props;
    const { page, tabValue } = this.state;

    getHistoryList({
      isLoadMore: page > 1,
      awardType: tabValue,
      show: 'received',
      page,
    });
    this.setState(prevState => ({
      page: prevState.page + 1,
    }));
  };

  handleFetchTotalCount = () => {
    const { getAwardCount } = this.props;
    getAwardCount({ show: 'received' });
  };

  handleCategoryFilter = val => {
    this.setState(
      {
        tabValue: val,
        page: 1,
      },
      () => {
        this.handleFetchAwards();
      }
    );
  };

  getNoDataMsg = () => {
    const { tabValue } = this.state;

    switch (tabValue) {
    case 'milestone':
      return 'You will receive a Milestone Appreciation card on your work anniversary';
    default:
      return 'You have no Appreciation card message.';
    }
  };

  render() {
    const {
      classes,
      isLoading,
      isLoadingMore,
      listReceived,
      awardCount,
    } = this.props;
    const { tabValue } = this.state;
    const { aaa, ssa, milestone } = awardCount;

    const totalAwardCount = aaa + ssa + milestone;
    const noDataMsg = this.getNoDataMsg();

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.header}>
            <h3>Card Received ({totalAwardCount})</h3>
          </div>

          <div className={classes.content}>
            <CategoryFilter
              tabValue={tabValue}
              allCount={totalAwardCount}
              aaaCount={aaa}
              ssaCount={ssa}
              milestoneCount={milestone}
              onClick={val => this.handleCategoryFilter(val)}
            />

            <div className={classes.groupMonth}>
              {isLoading && <LoadingEllipsis />}
              {!isLoading &&
                listReceived.data.map(card => (
                  <Link to={`/card/details/${card.id}`} key={card.id}>
                    <CardAppreciation data={card} />
                  </Link>
                ))}

              <NoData
                isShow={!isLoading && listReceived.data.length < 1}
                text={noDataMsg}
                type={tabValue}
              />
            </div>

            {isLoadingMore && <LoadingEllipsis />}
            {!isLoading && !isLoadingMore && listReceived.next_page_url && (
              <BaseButton label="Load more" onClick={this.handleFetchAwards} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

CardReceived.propTypes = {
  classes: PropTypes.object.isRequired,
  getHistoryList: PropTypes.func,
  getAwardCount: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  listReceived: PropTypes.object,
  awardCount: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers)
)(CardReceived);
