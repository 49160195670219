import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isPast } from 'date-fns';

import Header from '../../components/headers/Header';
import SuccessAlert from 'components/alerts/success';
import ErrorAlert from 'components/alerts/error';
import LoadingProcess from 'components/loadings/fullscreen-spin';

import { getProfile } from 'store/user/actions';
import { getConfigs } from 'store/configurations/actions';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
  }
});

class AppLayout extends Component {
  componentDidMount() {
    const {
      history,
      getProfile,
      getConfigs,
      auth,
      isLogin,
      profile,
      configs,
    } = this.props;

    const isTokenExpired = isPast(auth.access_token);

    // If user is not authorized, redirect to sign-in page
    if ((!isLogin && !auth.access_token) || isTokenExpired) {
      return history.replace('/sign-in');
    }

    // If user logged in, get API user profile data once
    if (!('name' in profile)) {
      getProfile();
    }

    // call get configurations API once
    if (!configs[0].value) {
      getConfigs();
    }
  }

  render() {
    const {
      children,
      classes,
      isProcessing,
    } = this.props;

    return (
      <div className={classes.root}>
        <Header />
        <SuccessAlert />
        <ErrorAlert />
        { isProcessing && <LoadingProcess /> }
        { children }
      </div>
    );
  }
}

AppLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object,
  auth: PropTypes.shape({
    access_token: PropTypes.string,
    token_type: PropTypes.string,
    expires_at: PropTypes.string,
  }),
  isLogin: PropTypes.bool,
  profile: PropTypes.object,
  configs: PropTypes.array,
  showErrorAlert: PropTypes.func,
  getProfile: PropTypes.func,
  getConfigs: PropTypes.func,
  isProcessing: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.user.auth,
  isLogin: state.user.isLogin,
  profile: state.user.profile,
  configs: state.configurations.data,
  isProcessing: state.commons.showLoadingProcess,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getProfile,
  getConfigs,
}, dispatch);

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AppLayout);
