const styles = theme => ({
  root: {
    padding: 12,
    '& h4': {
      textAlign: 'center',
      fontWeight: 600,
      color: theme.palette.text.dark,
      marginBottom: 12,
    },
  },
  action: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      margin: '0 4px',
    },
  },
  formControl: {
    margin: '.6rem 0',
    '& p': {
      fontWeight: 400,
      color: theme.palette.text.dark,
      marginBottom: 12,
      fontSize: 12,
    },
  },
  error: {
    margin: '0 !important',
    marginTop: '.5rem !important',
    marginBottom: '.5rem !important',
    textAlign: 'center !important',
    color: 'red !important',
    fontSize: '.8rem',
  }
});

export default styles;
