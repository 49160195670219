import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import BottomDrawer from 'components/drawers/bottom-drawer';
import SearchField from 'components/inputs/SearchField';
import Loading from 'components/loadings/loading-ellipsis';
import SearchResultItem from './components/SearchResultItem';

import dispatchers from './dispatchers';
import states from './states';
import styles from './styles';

let timer = null;

class GiveAppreciation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      isTyping: false,
      searchStr: '',
    };
  }

  handleChange = (e) => {
    const { getAllEmployees } = this.props;
    const searchStr = e.target.value;

    if (!timer) this.setState({ isTyping: true });

    this.setState({ searchStr });

    clearTimeout(timer);
    timer = setTimeout(() => {
      getAllEmployees({
        search: searchStr,
        status: 'active'
      });
      this.setState({ isTyping: false });
      timer = null;
    }, 800);
  }

  handleSelectEmployee = (person) => {
    this.setState({ searchStr: '' });
    this.props.onSelect(person);
  }

  render() {
    const {
      classes,
      employee,
      profile,
      openDialog,
      toggleDrawer,
      isLoading,
    } = this.props;
    const {
      searchStr,
      isTyping,
    } = this.state;

    return (
      <BottomDrawer
        fullHeight={true}
        openDialog={openDialog}
        toggleDrawer={toggleDrawer}
      >
        <div className={classes.title}>
          <h3>Give Appreciation</h3>
        </div>
        <div className={classes.content}>
          <div classes={classes.contentMessage}>
            <h3>Hi {profile.name}.</h3>
            <h5>Glad to have you here.</h5>
            <h5>Who do you want to give your appreciation this time?</h5>
          </div>

          <div className={classes.formControl}>
            <SearchField
              value={searchStr}
              placeholder="Enter Employee's Name"
              onChange={this.handleChange}
            />
          </div>

          <div className={classes.searchResult}>
            { (isTyping || isLoading) && <Loading /> }
            {
              employee.data.map((person, index) => {
                if (person.id !== profile.id) {
                  return (
                    <SearchResultItem
                      data={person}
                      key={index}
                      isLast={index === employee.data.length - 1}
                      onSelect={() => this.handleSelectEmployee(person)}
                    />
                  );
                }
                return '';
              })
            }
          </div>
        </div>
      </BottomDrawer>
    );
  }
}

GiveAppreciation.propTypes = {
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  onSelect: PropTypes.func,
  getAllEmployees: PropTypes.func,
  employee: PropTypes.object,
  profile: PropTypes.object,
  awardType: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers)
)(GiveAppreciation);
