import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import aaaSvg from 'assets/icons/love-dark.svg';
import ssaSvg from 'assets/icons/bintang-dark.svg';
import travelokaPng from 'assets/logos/traveloka-low.png';

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 12,
    backgroundColor: '#f9f9f9',
    '& img': {
      width: 36,
      margin: '12px 0',
      filter: 'grayscale(100%)',
    },
    '& p': {
      margin: '4px 0',
      textAlign: 'center',
      color: '#595959',
      fontSize: 14,
    }
  },
  imgCustom: {
    width: '48px !important',
  },
  allImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '12px 0',
    '& img': {
      margin: '0 4px !important',
    },
  }
});

const NoData = props => {
  const {
    classes,
    isShow,
    type,
    text,
  } = props;

  const allImage = () => (
    <div className={classes.allImg}>
      <img src={aaaSvg} alt="aaa"/>
      <img src={ssaSvg} alt="ssa"/>
      <img src={travelokaPng} alt="traveloka"/>
    </div>
  );

  const getImage = () => {
    switch (type) {
    case 'tca': return <img src={aaaSvg} alt="icon" />;
    case 'gem': return <img src={ssaSvg} alt="icon" />;
    case 'pending': return <img src={ssaSvg} alt="icon" />;
    case 'approved': return <img src={ssaSvg} alt="icon" />;
    case 'rejected': return <img src={ssaSvg} alt="icon" />;
    case 'all': return allImage();
    default: return <img className={classes.imgCustom} src={travelokaPng} alt="icon" />;
    }
  };

  if (isShow) {
    return (
      <div className={classes.root}>
        { getImage() }
        <p>{text}</p>
      </div>
    );
  } else {
    return null;
  }
};

NoData.propTypes = {
  classes: PropTypes.object.isRequired,
  isShow: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
};

export default withStyles(styles)(NoData);
