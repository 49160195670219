const styles = theme => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    textAlign: 'center',
    padding: '1.3rem 0',
    color: theme.palette.primary.main,
    '& h3': {
      margin: 0,
      fontWeight: 700,
    }
  },
  content: {
    width: '80%',
    minHeight: '90vh',
    padding: '1rem 0',
    display: 'flex',
    justifyContent: 'center',
  },
  contentList: {
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
  },
  card: {
    width: '100%',
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardDetail: {
    '& h5': {
      margin: '.4rem 0',
      fontWeight: 600,
      color: theme.palette.text.main,
    },
    '& p': {
      margin: 0,
      fontWeight: 300,
      fontSize: 12,
    }
  },
  cardActions: {
    '& a':{
      textDecoration:'none'
    }
  }
});

export default styles;
