import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '100%',
    '& hr': {
      border: `1px solid ${theme.palette.primary.main}`
    }
  },
  title: {
    color: theme.palette.primary.main,
    width: '100%',
    textAlign: 'center',
    margin: 0,
    marginTop: 15,
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 600,
  },
  subFilter: {
    '& p': {
      fontSize: 12,
    },
    '& h3': {
      fontSize: 16
    }
  },
});

class CategoryFilterHOD extends Component {
  render() {
    const {
      classes,
      tabValue,
      onClick,
      aaaCount,
      ssaCount,
      pendingCount,
      approvedCount,
      declinedCount,
    } = this.props;
  
    const tabClass = (val) => {
      if (val === tabValue) return 'blue';
      return 'gray';
    };

    return (
      <div className={classes.root}>
        <div className="dFlex justCenter alignCenter">
          <div
            className="btn-filter anim text-center"
            onClick={()=>onClick('tca')}
          >
            <h3 className="ma-0">{aaaCount}</h3>
            <p className={`my-4 ${tabClass('tca')}`}>All-Around</p>
          </div>

          <div className="summaryLine2" />

          <div
            className="btn-filter anim text-center"
            onClick={()=>onClick('gem')}
          >
            <h3 className="ma-0">{ssaCount}</h3>
            <p className={`my-4 ${tabClass('gem')}`}>Shining Star</p>
          </div>
        </div>


        { tabValue !== 'tca' &&
          <div className={classes.subFilter}>
            <hr/>
            <h3 className={classes.title}>Shining Star</h3>

            <div className="dFlex justCenter alignCenter">
              <div
                className="btn-filter anim text-center"
                onClick={()=>onClick('pending')}
              >
                <h3 className="ma-0">{pendingCount}</h3>
                <p className={`my-4 ${tabClass('pending')}`}>Pending</p>
              </div>

              <div className="summaryLine2" />

              <div
                className="btn-filter anim text-center"
                onClick={()=>onClick('approved')}
              >
                <h3 className="ma-0">{approvedCount}</h3>
                <p className={`my-4 ${tabClass('approved')}`}>Approved</p>
              </div>

              <div className="summaryLine2" />

              <div
                className="btn-filter anim text-center"
                onClick={()=>onClick('rejected')}
              >
                <h3 className="ma-0">{declinedCount}</h3>
                <p className={`my-4 ${tabClass('rejected')}`}>Declined</p>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

CategoryFilterHOD.defaultProps = {
  tabValue: 'tca',
  onClick: () => {},
  aaaCount: 0,
  ssaCount: 0,
  pendingCount: 0,
  approvedCount: 0,
  declinedCount: 0,
};

CategoryFilterHOD.propTypes = {
  classes: PropTypes.object.isRequired,
  tabValue: PropTypes.string,
  onClick: PropTypes.func,
  aaaCount: PropTypes.number,
  ssaCount: PropTypes.number,
  pendingCount: PropTypes.number,
  approvedCount: PropTypes.number,
  declinedCount: PropTypes.number,
};

export default withStyles(styles)(CategoryFilterHOD);
