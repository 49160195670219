import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {InputDateBox,SelectBox} from 'components';
import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
  drawerSummary: {
    transform: 'initial !important',
    overflow:'hidden',
    overflowY: 'hidden',
    '& .MuiPaper-root-16':{
      overflow:'hidden',
      overflowY: 'hidden',
    }
  },
  drawerHeader: {
    padding: '0',
    width: '18rem',
    display: 'flex',
    '& button': {
      marginLeft: 'auto'
    },
  },
  drawerMenu: {
    paddingRight: '2rem',
    height:'100%',
    '& a': {
      textDecoration: 'none',
    }
  },
  menuText: {
    '& span': {
      color: theme.palette.text.main,
      fontSize: '.8rem',
    }
  },
  menuIcon: {
    margin: 0,
    '& img': {
      width: '1rem',
      height: '1rem',
    },
  },
  divider: {
    margin: '1rem 0rem',
  }
});

class FilterDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
      roleList: props.roleList,
      statusList: props.statusList,
    };
  }
  
  handleApply = () => {
    const {
      toggleDrawer,
      applyFilter,
    } = this.props;
    const {
      dateFrom,
      dateTo,
      roleList,
      statusList,
    } = this.state;

    toggleDrawer();
    applyFilter({ roleList, dateFrom, dateTo, statusList });
  }

  render() {
    const {
      classes,
      isOpen,
      toggleDrawer,
    } = this.props;
    const {
      dateFrom,
      dateTo,
      roleList,
      statusList,
    } = this.state;

    return (
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={toggleDrawer} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.drawerMenu+' dFlex dirColumn justStart'} >
          <label className='blue summaryLeftPad'>Filter</label>
          <Divider className={classes.divider} />
          <div className='summaryDrawerContainer '>
            <div className='summaryLeftPad'>
              <label className="selectBoxTitle">Join Date</label>
              <InputDateBox
                title={'From'}
                value={dateFrom}
                onChangeText={val => this.setState({
                  dateFrom: val
                })}
              />
              <InputDateBox
                title={'To'}
                value={dateTo}
                onChangeText={val => this.setState({
                  dateTo: val
                })}
              />
            </div>

            <Divider className={classes.divider} />
            <div className='summaryLeftPad'>
              <SelectBox
                dataList={roleList}
                title="Position"
                onChecked={val => this.setState({
                  roleList: val
                })}
              />
            </div>

            <Divider className={classes.divider} />
            <div className='summaryLeftPad'>
              <SelectBox
                dataList={statusList}
                title="Status"
                onChecked={val => this.setState({
                  statusList: val
                })}
              />
            </div>
          </div>
        </div>
        <div
          className='bgBlue dFlex justCenter alignCenter summeryApplyBtn anim'
          onClick={this.handleApply}
        >
          <label className='white'>APPLY</label>
        </div>
      </Drawer>
    );
  }
}

FilterDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  roleList: PropTypes.array,
  statusList: PropTypes.array,
  applyFilter: PropTypes.func,
};

export default withStyles(styles)(FilterDrawer);
