const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    overflow:'hidden',
    overflowY:'auto'
  },
  paper: {
    width: '80%',
    maxWidth: '464px',
    // right: 'unset',
    // left: 'unset',
    // position:'absolute',
    margin: 'auto',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflowY: 'visible',
    marginTop: '18px',
    height:'85vh',
    '@media screen and (max-width: 414px)': {
      width: '90%',
    },
  },
  paperAnchorBottom: {
    // maxHeight: '80%',
  },
  btnClose: {
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    minHeight: 0,
    width: 30,
    height: 30,
  },
  btnIcon: {
    color: theme.palette.primary.main,
    position: 'relative',
    top: '3px',
    '& svg': {
      fontSize: 20
    }
  },
});

export default styles;
