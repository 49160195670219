import axios from 'axios';
import {
  GetRequestServer,
  PostRequestServer,
  // DeleteRequestServer,
} from 'helper';
import { baseUrl, API } from 'config/api';
import * as types from './action.types';
import * as commons from '../commons/action.types';

export const Employee =
  ({ payload, isFetching, isError, isFound }) =>
    async dispatch => {
      if (isError) {
        await dispatch({
          type: types.EMPLOYEE_DATA_ERROR,
          isFetching,
          data: payload,
          isError: payload,
          isFound,
        });
      } else {
        await dispatch({
          type: types.EMPLOYEE_DATA,
          data: payload,
          isFetching,
          isError,
          isFound,
        });
      }
    };

export const UploudEmployee = ({ token, data }, callback) => {
  let formData = new FormData();
  formData.append('data_employees', data);
  //console.log(data,'from unknow','aku');
  PostRequestServer(
    {
      apiUrl: API.GET_EMPLOYEE.apiUrl + '/import',
      baseUrl: API.GET_EMPLOYEE.baseUrl,
      token: token,
      data: formData,
    },
    (dataResponse, { isFetching, isFound, isError, onLoading }) => {
      const { data } = dataResponse;
      callback({ data, isFetching, isFound, isError, onLoading });
      //console.log(dataResponse,'resposnse',dataResponse.data);
    }
  );
};

export const DownloadEmployee = ({ token, params }, callback) => {
  axios
    .get(`${baseUrl}/api/employees/export`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
      callback(res);
    })
    .catch(err => {
      callback(err);
      // dispatch({ type: types.IS_ERROR, data: err.response.data });
    });
};

export const GetEmployee = (payload, callback) => {
  let {
    token,
    limit,
    page,
    role,
    status,
    keyword,
    dateFrom,
    dateTo,
    inputJoinDate,
  } = payload;

  role = role ? `&role=${role}` : '';
  status = status ? `&status=${status}` : '';
  let date = '';

  if (inputJoinDate) {
    dateFrom = dateFrom ? `&date_from=${dateFrom}` : '';
    dateTo = dateTo ? `&date_to=${dateTo}` : '';
    date = dateFrom + dateTo;
  }

  const query = `?&limit=${limit}&page=${page}&search=${keyword}${role}${status}${
    date ? date : ''
  }`;

  GetRequestServer(
    {
      apiUrl: API.GET_EMPLOYEE.apiUrl + query,
      baseUrl: API.GET_EMPLOYEE.baseUrl,
      token: token,
    },
    (dataResponse, { isFetching, isFound, isError }) => {
      const { data } = dataResponse;
      callback({ data, isFetching, isFound, isError });
      //console.log(dataResponse);
    }
  );
};

export const getAllEmployees = payload => {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_LOADING });
    axios
      .get(`${baseUrl}/api/employees`, {
        params: {
          role: payload && payload.position,
          search: payload && payload.search,
          page: payload && payload.page,
          status: payload && payload.status,
        },
        headers: {
          Authorization: authStr,
        },
      })
      .then(res => {
        if (payload.position === 'hod' || payload.position === 'hod,ceo') {
          dispatch({ type: types.GET_ALL_HOD, data: res.data.data });
        } else {
          dispatch({ type: types.GET_ALL, data: res.data.data });
        }
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err.response.data });
      });
  };
};

export const updateEmployee = (id, payload) => {
  return async (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_PROCESSING });
    axios.put(`${baseUrl}/api/employees/${id}`, payload, {
      headers: {
        Authorization: authStr,
        'Content-Type': 'application/json',
      },
    })
      .then(({ data }) => {
        dispatch({
          type: commons.SHOW_SUCCESS_ALERT,
          data: data.message,
        });
        return data;
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err.response.data });
        dispatch({
          type: commons.SHOW_ERROR_ALERT,
          data: err.response.data.message,
        });
      });
  };
};
