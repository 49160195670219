import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  button: {
    margin: 0,
    textTransform: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    height: 100,
    padding: '.4rem .4rem',
    background: theme.palette.primary.light,
    '&:hover': {
      background: '#CBDDEA',
    },
    '& h1': {
      fontWeight: 100,
      fontSize: '2.5rem',
      margin: '0 .5rem',
      textAlign: 'left',
      lineHeight: '2.5rem',
      color: theme.palette.primary.main,
    },
    '& h3': {
      fontWeight: 700,
      margin: '0 .5rem',
      textAlign: 'left',
      color: theme.palette.primary.main,
    },
    '& img': {
      height: '3.4rem', 
      position: 'absolute',
      right: 0,
      bottom: 2,
    }
  },
  buttonLabel: {
    position: 'absolute',
    bottom: 14,
  }
});

const MenuButton2 = (props) => {
  const {
    classes,
    fullWidth,
    count,
    label,
    icon,
  } = props;

  return (
    <Button
      className={classes.button}
      variant="contained"
      fullWidth={fullWidth}
    >
      <div className={classes.buttonLabel}>
        <h1>{count}</h1>
        <h3>{label}</h3>
      </div>
      <img src={icon} alt="icon"/>
    </Button>
  );
};

MenuButton2.propTypes = {
  classes: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.string,
  count: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
  ])
};

export default withStyles(styles)(MenuButton2);
