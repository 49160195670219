import axios from 'axios';
import {
  baseUrl,
  headers,
} from 'config/api';
import * as types from './action.types';
import * as commons from '../commons/action.types';

export const login = (accessToken) => {
  return dispatch => {
    dispatch({ type: types.IS_PROCESSING });
    axios.post(`${baseUrl}/api/auth/login`, {
      token: accessToken
    }, headers)
      .then(res => {
        dispatch({ type: types.SET_LOGIN, data: res.data.data });
        dispatch(getProfile());
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err });
      });
  };
};

export const logout = () => {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_PROCESSING });
    axios.get(`${baseUrl}/api/auth/logout`, {
      headers: {
        'Authorization': authStr,
      }
    })
      .then(res => {
        dispatch({ type: types.SET_LOGOUT, data: res.data.data });
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err });
      });
  };
};

export const getProfile = () => {
  return async (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_LOADING });
    await axios.get(`${baseUrl}/api/profile`, {
      headers: {
        'Authorization': authStr,
      }
    })
      .then(res => {
        dispatch({ type: types.SET_PROFILE, data: res.data.data });
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err });

        // Check if app is under maintenance mode
        if (err.response.data && err.response.data.data) {
          if (err.response.data.data.maintenance) {
            dispatch({
              type: commons.SET_MAINTENANCE_STATUS,
              payload: {
                status: true,
                endDate: err.response.data.data.end_time,
                message: err.response.data.message,
              }
            });
            return dispatch(logout());
          }
        }

        // Check for unauthenticated user (expired/invalid token)
        if (err.response && err.response.status === 401) {
          return dispatch(logout());
        }
      });
  };
};

export const updateProfile = (payload) => {
  return async (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;
    const formData = new FormData();

    formData.append('_method', 'put');
    payload.file && formData.append('file', payload.file);
    payload.background && formData.append('background', payload.background);

    dispatch({ type: types.IS_PROCESSING });
    await axios.post(`${baseUrl}/api/profile`, formData, {
      headers: {
        'Authorization': authStr,
      }
    })
      .then(res => {
        let successMessage;

        if (payload.file) {
          successMessage = 'You have successfully updated your profile picture';
        } else if (payload.background) {
          successMessage = 'You have successfully updated your cover picture';
        }

        dispatch({ type: types.UPDATE_PROFILE, data: res.data.data });
        dispatch({ type: commons.SHOW_SUCCESS_ALERT, data: successMessage });
      })
      .catch(err => {
        let errMessage;

        if (payload.file) {
          errMessage = 'Your photo cannot be updated, please check the image type or size and try again';
        } else if (payload.background) {
          errMessage = 'Your cover photo cannot be updated, please check the image type or size and try again';
        }

        dispatch({ type: types.IS_ERROR, data: err });
        dispatch({ type: commons.SHOW_ERROR_ALERT , data: errMessage });
      });
  };
};

export const proposeProfile = (payload) => {
  return async (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    await axios.post(`${baseUrl}/api/profile-propose`, payload, {
      headers: {
        'Authorization': authStr,
      }
    })
      .then(() => {
        let successMessage = 'You have successfully propose your profile change';
        dispatch({ type: commons.SHOW_SUCCESS_ALERT, data: successMessage });
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err });
        dispatch({ type: commons.SHOW_ERROR_ALERT , data: err.message });
      });
  };
};

// Check Self Propose Profile
export const getStatusUpdateProfile = () => {
  return async (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_PROCESSING });
    return axios.get(`${baseUrl}/api/profile-propose`, {
      headers: {
        'Authorization': authStr,
      }
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err });
        dispatch({ type: commons.SHOW_ERROR_ALERT , data: err.message });
      });
  };
};

// Check Propose Profile from other user (for role recognition team or higher)
export const getListUpdateProfile = () => {
  return async (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_PROCESSING });
    return axios.get(`${baseUrl}/api/profile-review`, {
      headers: {
        'Authorization': authStr,
      }
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err });
        dispatch({ type: commons.SHOW_ERROR_ALERT , data: err.message });
      });
  };
};

// Review Profile Update Proposal
export const reviewUpdateProfile = (id, payload) => {
  return async (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_PROCESSING });
    return axios.put(`${baseUrl}/api/profile-review/${id}`, payload, {
      headers: {
        'Authorization': authStr,
      }
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err });
        dispatch({ type: commons.SHOW_ERROR_ALERT , data: err.message });
      });
  };
};
