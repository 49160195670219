import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import CameraAlt from '@material-ui/icons/CameraAlt';

const styles = () => ({
  root: {
    '& input': {
      display: 'none',
    },
  },
  button: {
    width: 20,
    height: 20,
    minHeight: 0,
  },
  buttonIcon: {
    width: 12,
    height: 12,
  },
});

const ButtonUploadPic = props => {
  const {
    classes,
    id,
    name,
    title,
    onChange,
  } = props;

  return (
    <div title={title} className={classes.root}>
      <input
        id={id}
        type="file"
        name={name}
        accept="image/*"
        onChange={onChange}
      />
      <label htmlFor={id}>
        <Fab className={classes.button} component="span">
          <CameraAlt className={classes.buttonIcon} />
        </Fab>
      </label>
    </div>
  );
};

ButtonUploadPic.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
};

export default withStyles(styles)(ButtonUploadPic);
