const styles = (theme) => ({
  title: {
    background: theme.palette.primary.main,
    textAlign: 'center',
    padding: '1.5rem 0',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    '& h3': {
      margin: 0,
      color: '#FFFFFF',
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    textAlign: 'center',
    '& h3': {
      color: theme.palette.primary.main,
    },
    '& h5': {
      margin: 0,
      color: theme.palette.text.main,
      fontWeight: 400,
    }
  },
  contentMessage: {
    marginBottom: '1rem',
  },
  formControl: {
    margin: '.6rem 0',
  },
  searchResult: {
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    overflowY: 'auto',
    maxHeight: '200px',
    // Customize the scrollbar on category-box div element
    // Add 'display: none' on the -webkit-scrollbar class to hide it
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: '#dadada',
      bordeRadius: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#afafaf',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#9b9b9b',
    },
  }
});

export default styles;
