import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import rightArrowSvg from '../../assets/icons/right-arrow.svg';

const styles = () => ({
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: 0,
    marginBottom: '2.4rem',
    textTransform: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 400,
    padding: '1.2rem .4rem',
    '& h3': {
      fontWeight: 700,
      margin: '0 .5rem',
    },
    '& img': {
      height: '2rem',
      margin: '0 .5rem',
    }
  },
});

const MenuButton = (props) => {
  const {
    classes,
    color,
    fullWidth,
    count,
  } = props;

  return (
    <Button
      className={classes.button}
      variant="contained"
      color={color}
      fullWidth={fullWidth}
    >
      <h3>You have {count} pending cards</h3>
      <img src={rightArrowSvg} alt="icon"/>
    </Button>
  );
};

MenuButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  count: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
  ])
};

export default withStyles(styles)(MenuButton);
