import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

const styles = () => ({
  root: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D5D5D5',
    borderRadius: 2,
    '& textarea': {
      padding: '6px 10px',
      fontSize: '.9rem',
    },
  }
});

const BaseTextArea = (props) => {
  const {
    classes,
    id,
    name,
    placeholder,
    value,
    onChange,
    rows,
    maxLength,
    disabled,
  } = props;

  return (
    <TextField
      id={id}
      name={name}
      type="text"
      className={classes.root}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      multiline={true}
      fullWidth={true}
      rows={rows}
      InputProps={{ disableUnderline: true }}
      // eslint-disable-next-line
      inputProps={{ maxLength }}
      disabled={disabled}
    ></TextField>
  );
};

BaseTextArea.defaultProps = {
  rows: 4,
  maxLength: 250,
};

BaseTextArea.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  maxLength: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
};

export default withStyles(styles)(BaseTextArea);
