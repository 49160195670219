import React from 'react';
import PropTypes from 'prop-types';
//import {OwnIcon} from 'helper'

const CustomButton=({onClick,title,className})=>{
  const onClickedBtn=(event)=>{
    event.stopPropagation();
    event.preventDefault();
    if(onClick){
      onClick(event);
    }
  };

  return(
    <div className={`dFlex justCenter btnUploud  anim ${className}`} onClick={onClickedBtn}>
      <div className='btnUploudText'>{title}</div>
    </div>
  );
};

CustomButton.defaultProps={
  onClick:()=>null,
  className:''
};

CustomButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
};


export {CustomButton};
