import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardAppreciation from '../../../components/cards/card-appreciation';
import LoadingEllipsis from '../../../components/loadings/loading-ellipsis';
import BaseButton from '../../../components/buttons/base-button/BaseButton';
import CategoryFilter from './components/CategoryFilter';
import CategoryFilterHOD from './components/CategoryFilterHOD';
import NoData from 'components/data-handler/no-data';
import dispatchers from './dispatchers';
import states from './states';
import styles from './styles';

class CardSent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      tabValue: 'tca',
    };
  }

  componentDidMount() {
    this.handleFetchAwards();
    this.handleFetchTotalCount();
  }

  handleFetchAwards = () => {
    const { getHistoryList } = this.props;
    const { page, tabValue } = this.state;

    const getAwardType = () => {
      if (tabValue === 'pending') return 'gem';
      if (tabValue === 'approved') return 'gem';
      if (tabValue === 'rejected') return 'gem';
      return tabValue;
    };

    const getStatus = () => {
      if (tabValue === 'pending') return tabValue;
      if (tabValue === 'approved') return tabValue;
      if (tabValue === 'rejected') return tabValue;
      if (tabValue === 'gem') return 'pending,approved,rejected';
      if (tabValue === 'tca') return null;
      return 'approved,pending';
    };

    getHistoryList({
      isLoadMore: page > 1,
      awardType: getAwardType(),
      state: getStatus(),
      show: 'sent',
      page,
    });

    this.setState(prevState => ({
      page: prevState.page + 1,
    }));
  };

  handleFetchTotalCount = () => {
    const { getAwardCount } = this.props;
    getAwardCount({ show: 'sent' });
  };

  handleCategoryFilter = val => {
    this.setState(
      {
        tabValue: val,
        page: 1,
      },
      () => {
        this.handleFetchAwards();
      }
    );
  };

  menuPrivilege = privilege => {
    const { profile } = this.props;

    if (privilege.includes(profile.role)) return true;
    return false;
  };

  getNoDataMsg = () => {
    const { tabValue } = this.state;

    switch (tabValue) {
      case 'tca':
        return 'You can give All-Around Appreciation use a submission shortcut in ‘home’ or under the three-bar button on the top right of the platform.';
      case 'gem':
        return 'You can give Shining Star Appreciation use a submission shortcut in ‘home’ or under the three-bar button on the top right of the platform.';
      case 'pending':
        return 'There are no pending Shining Star Appreciation';
      default:
        return 'You can give Appreciation use a submission shortcut in ‘home’ or under the three-bar button on the top right of the platform.';
    }
  };

  render() {
    const {
      classes,
      isLoading,
      isLoadingMore,
      listSent,
      awardCount,
    } = this.props;
    const { tabValue } = this.state;
    const { aaa, ssa, ssa_approved, ssa_pending, ssa_rejected } = awardCount;

    const totalAwardCount = ssa + aaa;
    const canSubmitSSA = this.menuPrivilege([
      'ceo',
      'hod',
      'lead',
      'recognition_team',
    ]);
    const noDataMsg = this.getNoDataMsg();

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.header}>
            <h3>Card Sent ({totalAwardCount})</h3>
          </div>

          <div className={classes.content}>
            {!canSubmitSSA && (
              <CategoryFilter
                tabValue={tabValue}
                aaaCount={aaa}
                ssaCount={ssa}
                pendingCount={ssa_pending}
                showPendingTab={canSubmitSSA}
                menuPrivilege={this.menuPrivilege}
                onClick={val => this.handleCategoryFilter(val)}
              />
            )}

            {canSubmitSSA && (
              <CategoryFilterHOD
                tabValue={tabValue}
                aaaCount={aaa}
                ssaCount={ssa}
                pendingCount={ssa_pending}
                approvedCount={ssa_approved}
                declinedCount={ssa_rejected}
                onClick={val => this.handleCategoryFilter(val)}
              />
            )}

            <div className={classes.groupMonth}>
              {isLoading && <LoadingEllipsis />}
              {!isLoading &&
                listSent.data.map(card => (
                  <Link to={`/card/details/${card.id}`} key={card.id}>
                    <CardAppreciation data={card} />
                  </Link>
                ))}

              <NoData
                isShow={!isLoading && !listSent.data.length}
                text={noDataMsg}
                type={tabValue}
              />
            </div>

            {isLoadingMore && <LoadingEllipsis />}
            {!isLoading && !isLoadingMore && listSent.next_page_url && (
              <BaseButton label="Load more" onClick={this.handleFetchAwards} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

CardSent.propTypes = {
  classes: PropTypes.object.isRequired,
  getHistoryList: PropTypes.func,
  getAwardCount: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  profile: PropTypes.object,
  listSent: PropTypes.object,
  awardCount: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers)
)(CardSent);
