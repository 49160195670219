import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GoogleLogin } from 'react-google-login';
import { googleClientId } from 'config/api';
import { withStyles } from '@material-ui/core/styles';

// import appLogo from 'assets/logos/traveloka-logo.svg';
import Fade from '@material-ui/core/Fade';
import googleIcon from 'assets/icons/google.svg';
import LoadingProcess from 'components/loadings/fullscreen-spin';

import styles from './styles';
import dispatchers from './dispatchers';
import states from './states';
import './custom.css';

class SignIn extends Component {
  componentDidUpdate() {
    const {
      isLogin,
      profile,
      maintenance,
      showErrorAlert,
      history,
    } = this.props;

    if (maintenance.status) {
      showErrorAlert(maintenance.message);
    }

    if (isLogin && profile.id) {
      history.push('/');
    }
  }

  handleShowMaintenanceDialog = () => {
    const { maintenance, showErrorAlert } = this.props;
    if (maintenance.status) {
      showErrorAlert(maintenance.message);
    }
  }

  handleSignin = (response) => {
    const { login } = this.props;
    const { accessToken } = response;
    if (accessToken) login(accessToken);
  }

  render() {
    const { classes, isLogin, isProcessing } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.logo}>
          {/* <img src={appLogo} alt="logo"/> */}
          <Fade in={true} timeout={4000}>
            <h5>Appreciation Platform</h5>
          </Fade>
        </div>

        <Fade in={true} timeout={4000}>
          <div className={classes.title}>
            <h5>Click G button below to sign in</h5>
          </div>
        </Fade>

        <div className={classes.signIn}>
          <GoogleLogin
            clientId={googleClientId}
            render={renderProps => (
              <button id="pulse" onClick={renderProps.onClick}>
                <img src={googleIcon} alt="logo"/>
              </button>
            )}
            onSuccess={this.handleSignin}
            onFailure={this.handleSignin}
          />
        </div>
        
        { !isLogin && isProcessing && <LoadingProcess />}
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  login: PropTypes.func,
  showErrorAlert: PropTypes.func,
  isLogin: PropTypes.bool,
  isProcessing: PropTypes.bool,
  profile: PropTypes.object,
  maintenance: PropTypes.shape({
    status: PropTypes.bool,
    endDate: PropTypes.string,
    message: PropTypes.string,
  }),
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers),
)(SignIn);
