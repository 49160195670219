import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import aaaSvg from 'assets/icons/love-light.svg';
import ssaSvg from 'assets/icons/SSA-light.svg';

import renderHtml from 'helper/renderHtml';
import { imgAsset } from 'helper';

import styles from './styles';

const ListingSummary = props => {
  const {
    classes,
    dataList,
    onClick,
  } = props;

  const getAvaImg = ({ recipient_detail }) => {
    if (recipient_detail) {
      if (recipient_detail.file) {
        return recipient_detail.file.full_path;
      }
    }
    return imgAsset.noImg;
  };

  const checkStatus = (state) => {
    switch (state) {
    case 'pending': return classes.statusPending;
    case 'sent': return classes.statusSent;
    case 'rejected': return classes.statusDeclined;
    case 'approved': return classes.statusApproved;
    case 'received': return classes.statusPending;
    default: return classes.statusPending;
    }
  };

  const getDate = (date) => {
    return format(new Date(date * 1000), 'MM/DD/YY');
  };

  const getHour = (date) => {
    return format(new Date(date * 1000), 'hh:mm A');
  };

  const getStatus = (state) => {
    switch (state) {
    case 'pending': return 'Pending';
    case 'sent': return 'Sent';
    case 'rejected': return 'Declined';
    case 'approved': return 'Approved';
    case 'received': return 'Received';
    default: return state;
    }
  };

  return (
    <Fragment>
      {
        dataList.map((data, index) => (
          <div className={classes.root} key={index} onClick={() => onClick(data)}>
            <div className={classes.container}>
              <div className={classes.personAva}>
                <Avatar src={getAvaImg(data)} sizes="20" />
              </div>
              <div className={classes.cardInfo}>
                <div className={classes.personInfoName}>
                  { data.award_type === 'tca' &&
                    <div className="categoryIcon">
                      <img src={aaaSvg} alt="icon" />
                    </div>
                  }
                  { data.award_type === 'gem' &&
                    <div className="categoryIcon">
                      <img src={ssaSvg} alt="icon" />
                    </div>
                  }
                  <h5>{data.recipient_detail.name}</h5>
                </div>
                <span className={checkStatus(data.state)}>
                  {getStatus(data.state)}
                </span>
                <span
                  className={classes.cardMessage}
                  dangerouslySetInnerHTML={renderHtml({ content: data.message, isStrict: true })}
                />
              </div>
              <div className={classes.cardDate}>
                <p>{getDate(data.updated_at)}</p>
                <p>{getHour(data.updated_at)} (GMT+7)</p>
              </div>
            </div>
            {
              !(index === (dataList.length - 1))
                ? <Divider className={classes.divider} />
                : null
            }
          </div>
        ))
      }
    </Fragment>
  );
};

ListingSummary.defaultProps = {
  dataList: [],
  onClick: () => {},
};

ListingSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  dataList: PropTypes.array,
  onClick: PropTypes.func,
};

export default withStyles(styles)(ListingSummary);
