import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Switch from '@material-ui/core/Switch';
import BottomDrawer from 'components/drawers/bottom-drawer';
import BaseButton from 'components/buttons/base-button/BaseButton';
import TextArea from 'components/inputs/base-text-area';
import ClosePrompt from 'components/alerts/prompt';
import aaaSvg from 'assets/icons/all around appreciation.svg';

import states from '../../states';
import styles from './styles';

class SubmissionAAA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      isAnonymous: false,
      message: '',
      errorMsg: '',
      msgStyle: {},
      showClosePrompt: false,
      showBackPrompt: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { openSearch, toggleDrawer } = prevProps;
    const { step } = this.state;

    // Check if user press back button when on step 1,
    // handle the drawer to close & reopen the
    // search employee dialog
    if (prevState.step > 0 && step < 1) {
      toggleDrawer();
      openSearch();
      this.handleResetForm();
    }
  }

  handleResetForm = () => {
    this.setState({
      step: 1,
      isAnonymous: false,
      message: '',
      errorMsg: '',
    });
  };

  handleClose = isTrue => {
    if (isTrue) {
      this.handleResetForm();
      this.props.toggleDrawer();
    }
    this.setState({ showClosePrompt: false });
  };

  handleBackDialog = () => {
    if (this.state.step === 1) {
      this.setState({ showBackPrompt: true });
    } else {
      this.handleBack(true);
    }
  };

  handleBack = isTrue => {
    if (isTrue) {
      this.setState(prevState => ({
        step: prevState.step - 1,
      }));
    }
    this.setState({ showBackPrompt: false });
  };

  handleNext = () => {
    const { employee, onSubmit } = this.props;
    const { step, message, isAnonymous } = this.state;

    if (step === 1) {
      if (!this.validateInput(message.trim())) {
        return;
      }
    }

    if (step === 2) {
      onSubmit({
        message,
        recipientId: employee.id,
        isAnonymous,
      });
      this.handleClose(true);
      return;
    }

    this.setState(prevState => ({
      step: prevState.step + 1,
      errorMsg: '',
    }));
  };

  handleInputMessage = e => {
    this.setState({ message: e.target.value }, () => {
      const { message } = this.state;
      if (this.validateInput(message.trim())) {
        this.setState({ errorMsg: '' });
      }
    });
  };

  validateInput = message => {
    // eslint-disable-next-line
    const isForeign = /^[\s\w\d\?><;,\{\}\[\]\-_\+=!@\#\$%:."“”’`^/()~\\&\*\|\']*$/.test(
      message
    );

    if (!isForeign) {
      this.setState({
        errorMsg: 'Message can only contain roman alphabet characters!',
      });
      return false;
    }

    if (!message) {
      this.setState({ errorMsg: 'Please input the appreciation message!' });
      return false;
    }

    return true;
  };

  getMessageStyle = element => {
    if (element) {
      if (element.clientHeight > 15) {
        this.setState({
          msgStyle: { textAlign: 'left' },
        });
      } else {
        this.setState({
          msgStyle: { textAlign: 'center' },
        });
      }
    }
  };

  render() {
    const {
      classes,
      openDialog,
      fullHeight,
      selCategory,
      maxChar,
      is_anonymous,
    } = this.props;
    const {
      step,
      isAnonymous,
      message,
      errorMsg,
      msgStyle,
      showClosePrompt,
      showBackPrompt,
    } = this.state;

    return (
      <BottomDrawer
        fullHeight={fullHeight}
        openDialog={openDialog}
        toggleDrawer={() => this.setState({ showClosePrompt: true })}
      >
        <div className={classes.container}>
          <ClosePrompt
            className="marB-20"
            open={showClosePrompt}
            toggleDialog={val => this.handleClose(val)}
            text="Are you sure want to close this window?"
            subText="(All draft will be lost)"
          />

          <ClosePrompt
            className="marB-20"
            open={showBackPrompt}
            toggleDialog={val => this.handleBack(val)}
            text="Are you sure want to back to previous screen?"
            subText="(All draft will be lost)"
          />

          <div className={classes.header}>
            <img src={aaaSvg} alt="icon" />
          </div>
          {step === 1 && (
            <div className={classes.content}>
              <div className={classes.chooseCategory2}>
                <h5>Category</h5>
              </div>

              <div className={classes.wrapperCategory}>
                {
                  selCategory.map((x) => <div key={x.id} className={classes.categoryName}>
                    <h4>{x.name}</h4>
                  </div>)
                }
              </div>

              <h5>
                Please insert your appreciation message in the message box below
              </h5>
              <TextArea
                id="award-message"
                name="message"
                placeholder={`Message (Max. ${maxChar} characters)`}
                value={message}
                onChange={e => this.handleInputMessage(e)}
                maxLength={maxChar}
              />

              {!!is_anonymous && (
                <div className={classes.anonymousSwitch}>
                  <h5>Anonymously?</h5>
                  <Switch
                    value="isAnonymous"
                    color="primary"
                    checked={isAnonymous}
                    onChange={e =>
                      this.setState({
                        isAnonymous: e.target.checked,
                      })
                    }
                  />
                </div>
              )}
              {errorMsg && <p className={classes.error}>{errorMsg}</p>}
            </div>
          )}
          {step === 2 && (
            <div className={classes.content}>
              <div className={classes.chooseCategory2}>
                <h5>Category</h5>
                <div className={classes.categoryName}>
                  <h4>{selCategory.name}</h4>
                </div>
              </div>

              <h5>State your appreciation</h5>
              {message.split(/\r\n|\r|\n/g).map((value, index) => (
                <p
                  ref={this.getMessageStyle}
                  style={msgStyle}
                  key={index.toString()}
                >
                  {value}
                </p>
              ))}
            </div>
          )}
        </div>

        {step === 2 && (
          <div className={classes.actions} style={{ flexDirection: 'column' }}>
            <p>
              Please make sure your all-around appreciation card details are
              correct!
            </p>
            <BaseButton
              label="Send"
              color="primary"
              fullWidth={true}
              onClick={this.handleNext}
            />
            <BaseButton
              label="Cancel"
              color="primary"
              variant="outlined"
              fullWidth={true}
              onClick={this.handleBackDialog}
            />
          </div>
        )}
        {step !== 2 && (
          <div className={classes.actions}>
            <BaseButton
              label="Back"
              color="primary"
              onClick={this.handleBackDialog}
              style={{
                backgroundColor: '#696969',
                color: '#fff',
              }}
            />
            <BaseButton
              label="Next"
              color="primary"
              onClick={this.handleNext}
            />
          </div>
        )}
      </BottomDrawer>
    );
  }
}

SubmissionAAA.propTypes = {
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  categories: PropTypes.array,
  fullHeight: PropTypes.bool,
  openSearch: PropTypes.func,
  employee: PropTypes.object,
  onSubmit: PropTypes.func,
  selCategory: PropTypes.array,
  is_anonymous: PropTypes.number,
  maxChar: PropTypes.number,
};

export default compose(withStyles(styles), connect(states))(SubmissionAAA);
