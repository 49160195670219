import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { imgAsset } from 'helper';

import styles from './styles';

const ListingEmployee = ({
  classes,
  dataList,
  employeeEditClick,
}) => {

  const checkStatus = (val) => {
    switch (val) {
    case 'active': return classes.statusActive;
    case 'inactive': return classes.statusInactive;
    default: break;
    }
  };

  const getAvaImg = (item) => {
    if (item.file) return item.file.full_path;
    return imgAsset.noImg;
  };

  const getUserRole = (role) => {
    switch (role) {
    case 'ceo': return 'CEO';
    case 'hod': return 'HoD';
    case 'lead': return 'Team Lead';
    case 'recognition_team': return 'Recognition Team';
    case 'employee': return 'Employee';
    default: return role || 'Employee';
    }
  };

  return (
    <div>
      {
        dataList.map((data, index) => (
          <div className={classes.root} key={index}>
            <div className={classes.container}>
              <div className={classes.personAva}>
                <Avatar src={getAvaImg(data)} sizes="20" />
              </div>
              <div className={classes.personInfo}>
                <div className={classes.personInfoName}>
                  <div className={classes.personInfoNameLeft}>
                    <h5>{data.name}</h5>
                    <span className={checkStatus(data.status)}>
                      {data.status}
                    </span>
                  </div>
                </div>

                <h5 className={classes.personInfoDetail}>
                  {getUserRole(data.role)}
                  {data.department && `, ${data.department.name} Department`}
                  {data.country && `, ${data.country.name}`}
                </h5>
                <h5 className={classes.personInfoDetail}>
                  {data.email}
                </h5>
                <h5 className={classes.personInfoDetail}>
                  Join {format(data.join_date, 'MM/DD/YYYY')}
                </h5>
              </div>
              <div className={classes.rightContainer}>
                <div className={classes.personInfoDetail}>{data.user_id}</div>
                <button onClick={() => employeeEditClick(data)}>Edit</button>
              </div>
            </div>
            {
              !(index === (dataList.length - 1))
                ? <Divider className={classes.divider} />
                : null
            }
          </div>
        ))
      }
    </div>
  );
};

ListingEmployee.defaultProps = {
  dataList: [],
  onCollapse: () => {},
  employeeEditClick: () => {},
};

ListingEmployee.propTypes = {
  classes: PropTypes.object.isRequired,
  dataList: PropTypes.array,
  employeeEditClick: PropTypes.func,
};

export default withStyles(styles)(ListingEmployee);
