import axios from 'axios';
import { baseUrl } from 'config/api';
import * as types from './action.types';
import * as commons from '../commons/action.types';

export const getAwardTypes = () => {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_LOADING });
    axios.get(`${baseUrl}/api/award_types`, {
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        dispatch({ type: types.GET_AWARD_TYPES, data: res.data.data });
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err });
      });
  };
};

export const writeAwardTca = (payload) => {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_PROCESSING });
    axios.post(`${baseUrl}/api/awards/tca`, {
      message: payload.message,
      category_id: payload.categoryId,
      recipient_id: payload.recipientId,
      is_anonymous: payload.isAnonymous,
    }, {
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        dispatch({ type: types.WRITE_TCA_APPRECIATION, data: res.data.data });
        dispatch({ type: commons.SHOW_SUCCESS_ALERT, data: 'You have successfully submitted All-Around Appreciation' });
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err});
        dispatch({ type: commons.SHOW_ERROR_ALERT, data: 'There’s an error while submitting your All-Around Appreciation' });
      });
  };
};

export const writeAwardGem = (payload) => {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_PROCESSING });
    axios.post(`${baseUrl}/api/awards/gem`, {
      message: payload.message,
      recipient_id: payload.recipientId,
      approver_id: payload.approverId,
      message_hod: payload.messageToHod,
      is_anonymous: payload.isAnonymous,
    }, {
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        dispatch({ type: types.WRITE_GEM_APPRECIATION, data: res.data.data });
        dispatch({ type: commons.SHOW_SUCCESS_ALERT, data: 'You have successfully submitted Shining Star Appreciation' });
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err});
        dispatch({ type: commons.SHOW_ERROR_ALERT, data: 'There’s an error while submitting your Shining Star Appreciation' });
      });
  };
};

export const approvalAwardGem = (payload) => {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;
    const {
      awardId,
      state,
      messageApproved,
      messageRejected,
    } = payload;
    const body = {
      state,
      message_approved: state === 'approved' ? messageApproved : null,
      message_rejected: state === 'rejected' ? messageRejected : null,
    };

    dispatch({ type: types.IS_PROCESSING });
    axios.post(`${baseUrl}/api/awards/gem/approval/${awardId}`, body, {
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        const successMsg = (state === 'approved')
          ? 'You have successfully approved a shining star request'
          : 'You have successfully declined a shining star request';

        dispatch({ type: types.APPROVAL_GEM, data: res.data.data });
        dispatch({ type: commons.SHOW_SUCCESS_ALERT, data: successMsg });
        dispatch(getPendingList());
      })
      .catch(err => {
        const errorMsg = (state === 'approved')
          ? 'There’s an error occurred while approving shining star request'
          : 'There’s an error occurred while declining shining star request';

        dispatch({ type: types.IS_ERROR, data: err});
        dispatch({ type: commons.SHOW_ERROR_ALERT, data: errorMsg });
      });
  };
};

export const getDetailAward = (awardId) => {
  return async (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_LOADING });
    await axios.get(`${baseUrl}/api/awards/detail/${awardId}`, {
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        dispatch({ type: types.GET_DETAIL, data: res.data.data });
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err});
      });
  };
};

// If current user is recognition team,
// it will retrieve all pending awards,
// but if current user is not recognition team
// it will retrieve pending awards just for the current user
export const getPendingList = (payload = {}) => {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    payload.isLoadMore
      ? dispatch({ type: types.IS_LOADING_MORE })
      : dispatch({ type:types.IS_LOADING });

    axios.get(`${baseUrl}/api/awards/pending`, {
      params: {
        page: payload.page,
        date_from: payload.dateFrom || '2016-01-01',
        date_to: payload.dateTo || new Date(),
      },
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        dispatch({ type: types.GET_PENDING_LIST, data: res.data.data });
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err});
      });
  };
};

// If no award_type params inputed,
// it will fetch all TCA & GEM awards category/type.
// params.show is for determine to fetch 'sent' or 'received' awards
export const getHistoryList = (payload) => {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    payload.isLoadMore
      ? dispatch({ type: types.IS_LOADING_MORE })
      : dispatch({ type:types.IS_LOADING });
    
    axios.get(`${baseUrl}/api/awards/list`, {
      params: {
        award_type: payload.awardType,
        show: payload.show,
        state: payload.state,
        page: payload.page,
        date_from: payload.dateFrom,
        date_to: payload.dateTo,
      },
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        switch (payload.show) {
        case 'sent': {
          dispatch({ type: types.GET_SENT_LIST, data: res.data.data });
          break;
        }
        case 'received': {
          dispatch({ type: types.GET_RECEIVED_LIST, data: res.data.data });
          break;
        }
        default: {
          dispatch({ type: types.GET_SENT_LIST, data: res.data.data });
          break;
        }}
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err});
      });
  };
};

export const getAwardCount = (payload = {}) => {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    dispatch({ type: types.IS_LOADING });
    axios.get(`${baseUrl}/api/awards/summary`, {
      params: {
        show: payload.show
      },
      headers: {
        'Authorization': authStr,
        'Content-Type': 'application/json'
      },
    })
      .then(res => {
        if (payload.show) dispatch({ type: types.GET_AWARD_COUNT, data: res.data.data });
        else dispatch({ type: types.GET_AWARD_COUNT_ALL, data: res.data.data });
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err});
      });
  };
};
