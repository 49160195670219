import React from 'react';
import {OwnIcon} from 'helper'

const FileUplouder=({onFileSelect,title,accept,onFileUploud,isFetching,isProcessing,isButtonHide})=>{
  const onChangeFile=(event)=>{
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    if(event.target.files[0]){
      if(file.type!==accept){
        onFileSelect({file,isError:false,isLocal:true});
      }else{
        onFileSelect({file,isError:false});
      }
    }
  }
  const onUploud=()=>{
    if(!isFetching){
      onFileUploud()
    }
  }
  return(
    <div className='dFlex justStart dirRow alignStart'>
      <label className='btnUploud dFlex justCenter alignCenter anim'>
        <div className='btnUploudText'>{title}</div>
        <input type='file' id='searchRef' onChange={onChangeFile} accept={accept} />
      </label>
      {!isButtonHide && <OwnIcon.IconDownload className='gray btnUploudPush anim' onClick={onUploud}/>}
      {isProcessing && <label className='uploudingTextLoading'>Uploading...</label>}
    </div>
  )
}
FileUplouder.defaultProps={
  onFileSelect:()=>null,
  onFileUploud:()=>null,
  accept:'',
  isProcessing:false,
  isButtonHide:false,
}
export {FileUplouder};
