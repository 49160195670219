import axios from 'axios';
import { baseUrl } from 'config/api';
import * as types from './action.types';

export const getListBackup = (payload = {}) => {
  return (dispatch, getState) => {
    const { auth } = getState().user;
    const authStr = `${auth.token_type} ${auth.access_token}`;

    payload.isLoadMore
      ? dispatch({ type: types.IS_LOADING_MORE })
      : dispatch({ type: types.IS_LOADING });

    axios.get(`${baseUrl}/api/backup_awards`, {
      params: {
        page: payload.page,
        award_type: payload.awardType,
        date_from: payload.dateFrom || '2018-12-13',
        date_to: payload.dateTo || new Date(),
      },
      headers: {
        'Authorization': authStr,
      }
    })
      .then(res => {
        dispatch({ type: types.GET_LIST_BACKUP, data: res.data.data });
      })
      .catch(err => {
        dispatch({ type: types.IS_ERROR, data: err.response.data});
      });
  };
};
