import sanitizeHtml from 'sanitize-html-react';

function renderHtml({ content, isStrict }) {
  const options = {
    allowedTags: !isStrict
      ? [ 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
        'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
        'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'span' ]
      : [],
    allowedAttributes: {
      a: [ 'href', 'name', 'target', 'class' ],
      // We don't currently allow img itself by default, but this
      // would make sense if we did
      span: [ 'class', 'style' ]
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
    // URL schemes we permit
    allowedSchemes: [ 'http', 'https', 'ftp', 'mailto' ],
    allowedSchemesByTag: {}
  };

  return { __html: sanitizeHtml(content, options) };
}

export default renderHtml;
