import React from 'react';
export const OwnIcon={
  IconDownload:({className,onClick})=>{
    return(
      <svg className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32" fill='currentColor' stroke='currentColor' >
        <path d="M30.048 15.577c-0.001 0-0.003 0-0.004 0-0.479 0-0.868 0.389-0.868 0.868 0 0.001 0 0.003 0 0.004v-0 7.928c-0.003 2.156-1.75 3.902-3.905 3.905h-19.122c-2.156-0.003-3.902-1.75-3.905-3.905v-8.058c-0.019-0.467-0.402-0.838-0.871-0.838s-0.853 0.371-0.871 0.836l-0 0.002v8.057c0.003 3.119 2.53 5.646 5.649 5.649h19.12c3.119-0.003 5.646-2.53 5.649-5.649v-7.928c0-0.482-0.39-0.872-0.872-0.872v0zM30.048 15.577"></path>
        <path d="M15.096 23.105c0.157 0.158 0.373 0.256 0.613 0.259h0c0.001 0 0.002 0 0.003 0 0.239 0 0.456-0.099 0.61-0.258l5.539-5.539c0.158-0.158 0.255-0.376 0.255-0.617 0-0.482-0.39-0.872-0.872-0.872-0.241 0-0.459 0.098-0.617 0.255v0l-4.048 4.055v-17.683c0-0.010 0.001-0.022 0.001-0.034 0-0.482-0.39-0.872-0.872-0.872s-0.872 0.39-0.872 0.872c0 0.012 0 0.024 0.001 0.036l-0-0.002v17.681l-4.052-4.053c-0.158-0.158-0.376-0.255-0.617-0.255-0.482 0-0.872 0.39-0.872 0.872 0 0.241 0.098 0.459 0.255 0.617v0zM15.096 23.105"></path>
      </svg>
    )
  },
  IconFilter:({className,onClick})=>{
    return(
      <svg className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32" fill='currentColor' stroke='currentColor' >
        <path d="M32.569 1.423c-0.162-0.338-0.502-0.567-0.895-0.567h-30.684c-0.546 0.001-0.988 0.444-0.988 0.991 0 0.239 0.085 0.459 0.226 0.63l-0.001-0.002 11.795 14.305v13.199c0 0.001 0 0.001 0 0.002 0 0.546 0.443 0.989 0.989 0.989 0.161 0 0.312-0.038 0.446-0.106l-0.006 0.003 6.621-3.292c0.328-0.165 0.55-0.5 0.55-0.886v-0l0.013-9.908 11.801-14.305c0.141-0.17 0.226-0.389 0.226-0.629 0-0.154-0.035-0.299-0.097-0.429l0.003 0.006zM18.889 15.795c-0.14 0.169-0.226 0.389-0.227 0.628v0l-0.012 9.649-4.649 2.305v-11.953c-0-0.24-0.086-0.46-0.228-0.631l0.001 0.002-10.685-12.96h26.484zM18.889 15.795"></path>
      </svg>
    )
  },
  IconGem:({className,onClick})=>{
    return(
      <svg className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32" fill='currentColor' stroke='currentColor' >
        <path d="M25.432 11.63c0 0.002 0 0.005 0 0.007 0 0.944-0.766 1.71-1.71 1.71-0.111 0-0.22-0.011-0.325-0.031l0.011 0.002-1.4 5.030v1.8h-11.93v-1.878l-1.4-4.96c-0.096 0.019-0.207 0.030-0.32 0.030-0.948 0-1.716-0.768-1.716-1.716s0.768-1.716 1.716-1.716c0.948 0 1.716 0.768 1.716 1.716 0 0.238-0.049 0.465-0.136 0.671l0.004-0.011c1.714 0.578 4.362 1.050 5.554-0.944-0.689-0.233-1.176-0.874-1.176-1.629 0-0.948 0.768-1.716 1.716-1.716s1.716 0.768 1.716 1.716c0 0.755-0.487 1.395-1.164 1.625l-0.012 0.004c0.696 1.284 2.244 2.626 5.624 1.096-0.127-0.234-0.202-0.512-0.202-0.808 0-0.948 0.768-1.716 1.716-1.716s1.716 0.768 1.716 1.716c0 0.003 0 0.005-0 0.008v-0zM10.078 22.558h11.924v-1.626h-11.924z"></path>
      </svg>
    )
  },
  IconCategory:({className,onClick})=>{
    return(
      <svg className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32" fill='currentColor' stroke='currentColor' >
        <path d="M21,1.562H18.228q.015-.461.016-.928A.634.634,0,0,0,17.61,0H4.029A.634.634,0,0,0,3.4.634q0,.466.016.928H.634A.634.634,0,0,0,0,2.2,13.74,13.74,0,0,0,2.09,9.742a6.614,6.614,0,0,0,5.025,3.263,6.51,6.51,0,0,0,1.373,1.153v2.818H7.424a2.334,2.334,0,0,0-2.332,2.332v1.064H5.048a.634.634,0,1,0,0,1.268H16.591a.634.634,0,1,0,0-1.268h-.045V19.307a2.334,2.334,0,0,0-2.332-2.332H13.151V14.158a6.509,6.509,0,0,0,1.373-1.153,6.615,6.615,0,0,0,5.025-3.263A13.74,13.74,0,0,0,21.639,2.2.634.634,0,0,0,21,1.562ZM3.146,9.04A12.218,12.218,0,0,1,1.281,2.83h2.2A21.186,21.186,0,0,0,5.451,10.52q.254.507.529.958A6.114,6.114,0,0,1,3.146,9.04Zm15.347,0a6.114,6.114,0,0,1-2.834,2.439q.275-.451.529-.958A21.189,21.189,0,0,0,18.157,2.83h2.2A12.218,12.218,0,0,1,18.493,9.04Zm0,0"></path>
      </svg>
    )
  },
  IconCardSend:({className,onClick})=>{
    return(
      <svg className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 22.007 20.529" fill='currentColor' stroke='currentColor' >
        <path d="M21.862,1.449a.328.328,0,0,0-.355-.051L.19,11.3a.329.329,0,0,0-.006.593l6.033,2.961a.328.328,0,0,0,.341-.032l5.866-4.384-4.6,4.736a.329.329,0,0,0-.092.254L8.185,21.4a.328.328,0,0,0,.225.287.335.335,0,0,0,.1.016.328.328,0,0,0,.249-.114l3.2-3.71,3.959,1.891a.328.328,0,0,0,.455-.2l5.582-17.78a.327.327,0,0,0-.1-.345Zm0,0" transform="translate(0 -1.255)"></path>
      </svg>
    )
  },
  IconTCA:({className,onClick})=>{
    return(
      <svg className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 77.494" fill='currentColor' stroke='currentColor' >
        <path d="M100,75.135a9.1,9.1,0,0,1-10.77,8.98l-7.5,26.77v9.623H18.269v-10L10.836,84.1a9.129,9.129,0,1,1,6.732-5.466c9.127,3.079,23.218,5.589,29.558-5.027a9.134,9.134,0,1,1,5.752,0c3.7,6.837,11.95,13.98,29.932,5.835A9.135,9.135,0,1,1,100,75.135ZM18.27,133.305H81.733v-8.651H18.27Z" transform="translate(0 -55.811)"></path>
      </svg>
    )
  },
  IconTrophy:({className,onClick})=>{
    return(
      <svg className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.001 100" fill='currentColor' stroke='currentColor' >
        <path d="M97.071,7.217H84.238q.07-2.132.072-4.288A2.93,2.93,0,0,0,81.381,0H18.62a2.929,2.929,0,0,0-2.93,2.93q0,2.155.072,4.288H2.93A2.929,2.929,0,0,0,0,10.147C0,23.274,3.431,35.659,9.66,45.021c6.158,9.255,14.36,14.564,23.22,15.08a30.084,30.084,0,0,0,6.345,5.328V78.451H34.31A10.787,10.787,0,0,0,23.535,89.226v4.915h-.208a2.93,2.93,0,0,0,0,5.859H76.674a2.93,2.93,0,1,0,0-5.859h-.208V89.226A10.788,10.788,0,0,0,65.69,78.451H60.775V65.429A30.081,30.081,0,0,0,67.119,60.1c8.861-.517,17.063-5.825,23.221-15.08C96.57,35.659,100,23.274,100,10.147A2.93,2.93,0,0,0,97.071,7.217ZM14.539,41.776C9.4,34.053,6.381,23.954,5.921,13.077H16.09c1.057,13.371,4.2,25.73,9.1,35.542q1.172,2.344,2.446,4.429C22.764,51.207,18.254,47.361,14.539,41.776Zm70.923,0c-3.716,5.585-8.225,9.432-13.1,11.272q1.273-2.086,2.446-4.429c4.906-9.811,8.044-22.17,9.1-35.542H94.08C93.619,23.954,90.6,34.053,85.462,41.776Zm0,0"></path>
      </svg>
    )
  },
  IconSearch:({className,onClick})=>{
    return(
      <svg className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.997 34.178" fill='currentColor' stroke='currentColor' >
        <path d="M33.479,31.13l-8.134-8.46A13.794,13.794,0,1,0,14.783,27.6a13.651,13.651,0,0,0,7.906-2.5l8.2,8.524a1.8,1.8,0,1,0,2.594-2.5ZM14.783,3.6a10.2,10.2,0,1,1-10.2,10.2A10.211,10.211,0,0,1,14.783,3.6Z" transform="translate(-0.984)"></path>
      </svg>
    )
  },
  IconEdit:({className,onClick})=>{
    return(
      <svg className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" fill='currentColor' stroke='currentColor' >
        <path d="M0 1007.328c0 8.832 7.168 16 16 16h995.552c2.144 0 4.224-0.416 6.176-1.248s3.712-2.016 5.184-3.488c0.032-0.032 0.064-0.032 0.096-0.064 0.096-0.096 0.096-0.288 0.224-0.384 1.632-1.76 2.688-3.904 3.424-6.24 0.224-0.736 0.32-1.44 0.448-2.208 0.128-0.8 0.48-1.504 0.48-2.336 0-1.28-0.448-2.4-0.736-3.584-0.096-0.448 0.096-0.896-0.064-1.344l-101.12-310.88c-0.768-2.4-2.112-4.608-3.904-6.368l-547.744-547.776c-0.576-0.896-0.992-1.856-1.76-2.656l-119.648-119.648c-19.424-19.424-51.040-19.424-70.496 0l-167.52 167.52c-9.408 9.408-14.592 21.92-14.592 35.232s5.184 25.856 14.592 35.264l116.992 116.992c0.576 0.864 0.96 1.856 1.728 2.624l546.88 550.56c1.888 1.92 4.192 3.296 6.784 4.064l214.816 64h-885.792c-8.832-0.032-16 7.136-16 15.968zM203.584 325.152l501.984 495.552-18.464 64.064-519.84-523.296 36.32-36.32zM730.112 800l-503.936-497.44 77.984-77.984 499.424 500.736v74.688h-73.472zM823.52 700l-496.736-498.048 33.248-33.248 518.752 518.752-55.264 12.544zM735.616 832h83.968c8.832 0 16-7.168 16-16v-85.92l64.224-14.592 87.136 267.84-271.616-80.928 20.288-70.4zM32 217.856c0-4.768 1.856-9.216 5.216-12.608l167.52-167.52c6.944-6.944 18.24-6.976 25.248 0l107.904 107.904-192.736 192.736-107.904-107.904c-3.392-3.36-5.248-7.84-5.248-12.608z"></path>
      </svg>
    )
  },
  IconImage:({className,onClick})=>{
    return(
      <svg className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='currentColor' stroke='currentColor' >
        <path d="M479.942 64c0.020 0.017 0.041 0.038 0.058 0.058v383.885c-0.017 0.020-0.038 0.041-0.058 0.058h-447.885c-0.020-0.017-0.041-0.038-0.057-0.058v-383.886c0.017-0.020 0.038-0.041 0.057-0.057h447.885zM480 32h-448c-17.6 0-32 14.4-32 32v384c0 17.6 14.4 32 32 32h448c17.6 0 32-14.4 32-32v-384c0-17.6-14.4-32-32-32v0z"></path>
        <path d="M416 144c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48z"></path>
        <path d="M448 416h-384v-64l112-192 128 160h32l112-96z"></path>
      </svg>
    )
  },
  IconFile:({className,onClick})=>{
    return(
      <svg className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='currentColor' stroke='currentColor' >
        <path d="M458.903 114.538c-11.106-15.146-26.587-32.85-43.589-49.852s-34.706-32.482-49.852-43.589c-25.787-18.91-38.296-21.097-45.462-21.097h-248c-22.056 0-40 17.944-40 40v432c0 22.056 17.944 40 40 40h368c22.056 0 40-17.944 40-40v-312c0-7.166-2.186-19.675-21.097-45.462zM392.687 87.313c15.35 15.35 27.4 29.199 36.29 40.687h-76.977v-76.973c11.492 8.89 25.339 20.939 40.687 36.286zM448 472c0 4.336-3.664 8-8 8h-368c-4.336 0-8-3.664-8-8v-432c0-4.336 3.664-8 8-8 0 0 247.978-0.001 248 0v112c0 8.836 7.163 16 16 16h112v312z"></path>
        <path d="M368 416h-224c-8.836 0-16-7.163-16-16s7.164-16 16-16h224c8.837 0 16 7.163 16 16s-7.163 16-16 16z"></path>
        <path d="M368 352h-224c-8.836 0-16-7.163-16-16s7.164-16 16-16h224c8.837 0 16 7.163 16 16s-7.163 16-16 16z"></path>
        <path d="M368 288h-224c-8.836 0-16-7.163-16-16s7.164-16 16-16h224c8.837 0 16 7.163 16 16s-7.163 16-16 16z"></path>
      </svg>
    )
  },
  IconCalendar:({className,onClick,title})=>{
    return(
      <svg title={title} className={`customIcon ${className}`} onClick={onClick} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='currentColor' stroke='currentColor' >
        <path  d="M424.58 55.461h-15.425v-16.83c0-21.311-16.815-38.632-37.507-38.632-20.707 0-37.507 17.321-37.507 38.632v16.815h-38.491v-16.815c0-21.311-16.815-38.632-37.507-38.632s-37.507 17.321-37.507 38.632v16.815h-38.491v-16.815c0-21.311-16.815-38.632-37.507-38.632s-37.507 17.321-37.507 38.632v16.815h-19.695c-32.914 0-59.703 26.789-59.703 59.703v337.147c0 32.914 26.789 59.703 59.703 59.703h337.147c32.928 0 59.703-26.789 59.703-59.703v-337.147c0-32.9-26.789-59.689-59.703-59.689zM355.212 38.632c0-9.679 7.375-17.559 16.436-17.559 9.075 0 16.436 7.881 16.436 17.559v68.778c0 9.679-7.361 17.559-16.436 17.559-9.061 0-16.436-7.881-16.436-17.559v-68.778zM241.707 38.632c0-9.679 7.375-17.559 16.436-17.559s16.436 7.881 16.436 17.559v68.778c0 9.679-7.375 17.559-16.436 17.559s-16.436-7.881-16.436-17.559v-68.778zM128.186 38.632c0-9.679 7.375-17.559 16.436-17.559s16.436 7.881 16.436 17.559v68.778c0 9.679-7.375 17.559-16.436 17.559s-16.436-7.881-16.436-17.559v-68.778zM449.164 452.311c0 13.556-11.027 24.584-24.584 24.584h-337.147c-13.556 0-24.584-11.027-24.584-24.584v-309.051h386.315v309.051z"></path>
        <path d="M94.457 205.463h64.55v57.441h-64.549v-57.441z"></path>
        <path d="M180.613 205.463h64.564v57.441h-64.564v-57.441z"></path>
        <path d="M266.838 205.463h64.55v57.441h-64.549v-57.441z"></path>
        <path d="M352.993 205.463h64.564v57.441h-64.563v-57.441z"></path>
        <path d="M94.457 282.136h64.55v57.399h-64.549v-57.399z"></path>
        <path d="M180.613 282.136h64.564v57.399h-64.564v-57.399z"></path>
        <path d="M266.838 282.136h64.55v57.399h-64.549v-57.399z"></path>
        <path d="M352.993 282.136h64.564v57.399h-64.563v-57.399z"></path>
        <path d="M94.457 358.781h64.55v57.399h-64.549v-57.399z"></path>
        <path d="M180.613 358.781h64.564v57.399h-64.564v-57.399z"></path>
        <path d="M266.838 358.781h64.55v57.399h-64.549v-57.399z"></path>
        <path d="M352.993 358.781h64.564v57.399h-64.563v-57.399z"></path>
      </svg>
    )
  },
}
