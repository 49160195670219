import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';

class HomeHeader extends Component {
  render() {
    const { classes, employee } = this.props;

    return (
      <div className={classes.header}>
        <div className={classes.headerProfile}>
          <div className={classes.pageName}>
            <h3 style={{color: '#2E8ADF'}}>Employee ({employee.total})</h3>
          </div>

        </div>
      </div>
    );
  }
}

HomeHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  employee: PropTypes.object,
};

const mapStateToProps = (state) => ({
  employee: state.employee.employee,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(HomeHeader);
