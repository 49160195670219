const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    backgroundColor: '#fff',
    borderRadius: '10px 10px 0 0',
    flex: 1,
    minHeight: 0,
  },
  header: {
    textAlign: 'center',
    padding: '.6rem',
    '& img': {
      margin: '.4rem',
      width: 100,
      height: 100,
    },
    '& h3': {
      margin: 0,
      color: theme.palette.primary.main,
    },
  },
  content: {
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& h4': {
      fontWeight: 600,
      color: theme.palette.text.dark,
    },
    '& p': {
      // textAlign: 'left',
      fontWeight: 400,
      color: theme.palette.text.dark,
      fontSize: '.8rem',
      marginTop: '6px !important',
    },
    overflow: 'hidden',
    overflowY: 'auto',
    // Customize the scrollbar on category-box div element
    // Add 'display: none' on the -webkit-scrollbar class to hide it
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: '#dadada',
      bordeRadius: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#afafaf',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#9b9b9b',
    },
  },
  formControl: {
    margin: '.6rem 0',
    '& h5': {
      margin: '.2rem',
      fontWeight: 600,
      color: theme.palette.text.dark,
    },
    '& p': {
      margin: '0 1rem',
    },
  },
  formLabel: {
    fontWeight: '400 !important',
    '& h5': {
      fontWeight: '400 !important',
    },
  },
  actions: {
    backgroundColor: theme.palette.secondary.main,
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      fontWeight: 400,
      color: theme.palette.text.dark,
      fontSize: '.8rem',
    },
    '& span': {
      fontWeight: 600,
    },
  },
  error: {
    textAlign: 'center !important',
    color: 'red !important',
    fontSize: '.8rem',
    marginTop: 4,
  },
  btnBack: {
    fontSize: '.8rem',
    color: theme.palette.primary.main,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  anonymousSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default styles;
