
const styles = theme => ({
  card: {
    width: '100%',
    padding: '1rem',
    margin: '.5rem 0',
    backgroundColor: theme.palette.secondary.main,
    cursor: 'pointer',
    '& h5': {
      margin: 0,
    },
    '&:hover': {
      backgroundColor: '#E3E3E3',
    },
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.main,
  },
  cardDate: {
    fontWeight: 600,
    color: theme.palette.text.light,
  },
  awardType: {
    display: 'flex',
    alignItems: 'center',
    '& h5': {
      color: theme.palette.primary.main,
    }
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
  },
  cardName: {
    margin: '0 1rem',
  },
  cardSender: {
    color: theme.palette.primary.main,
    marginBottom: '.2rem !important',
  },
  cardReceiver: {
    display: 'flex',
    alignItems: 'center',
    '& h5': {
      color: theme.palette.text.main,
      fontWeight: 300,
    },
  },
  cardAvatarSmall: {
    width: 10,
    height: 10,
  },
  cardAction: {
    marginLeft: 'auto',
    transform: 'scale(1, 2.2)',
    fontWeight: '100',
    color: theme.palette.primary.main
  },
  imageAva: {
    margin: '0 4px',
    borderRadius: '50%',
    height: 13,
    width: 13,
  }
});

export default styles;
