import React from 'react';
import {CustomButton} from 'components';
import renderHtml from 'helper/renderHtml';

const ListingMilestone = ({dataList, onEdit, onDelete}) => {
  const handleTitle = (years) => {
    let label = '';

    switch (years % 10) {
    case 1:
      label= 'st';
      break;

    case 2:
      label= 'nd';
      break;

    case 3:
      label= 'rd';
      break;
    
    default: 
      label = 'th';
      break;
    }

    return 'Milestone '+years+label+' Year';
  };

  return (
    dataList.length > 0 && dataList.map((list, index) => (
      <div className='pad-10 dFlex justCenter dirColumn bgGrayF1 mar30' key={index.toString()+'-main'}>
        <div className='categoryHeaderContainer'>
          <div className='dFlex justSpace alignCenter mb-12'>
            <label className='blue categoryHeaderTitle'>{handleTitle(list.years)}</label>
            <CustomButton
              className="customBtn"
              title="Edit Card"
              onClick={()=>{onEdit(list);}}
            />

            <br />

            <CustomButton
              className="customBtn red"
              title="Delete"
              onClick={()=>{onDelete({...list, title: handleTitle(list.years)});}}
            />
          </div>
          <label
            className='categoryHeaderTitleBottom'
            dangerouslySetInnerHTML={renderHtml({ content: list.content})}
            style={{ whiteSpace: 'pre-wrap' }}
          />
        </div>
      </div>
    ))
  );
};

ListingMilestone.defaultProps = {
  dataList: [],
  onEdit: () => {},
  onDelete: () => {},
};

export {ListingMilestone};
