import * as types from './action.types';

const initialState = {
  isLoading: false,
  isProcessing: false,
  isSendingMaintenanceEmail: false,
  isError: {
    status: false,
    message: '',
  },
  data: [
    { name: 'video', value: '' },
    { name: 'infographics', value: '' },
    { name: 'infographics_hod', value: '' },
    { name: 'infographics_lead', value: '' },
    { name: 'infographics_recognition', value: '' },
    { name: 'infographicsMenu', value: '' },
    { name: 'title', value: '' },
    { name: 'content', value: '' },
  ],
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
  case types.IS_LOADING: {
    return {
      ...state,
      isLoading: true
    };
  }
  case types.IS_PROCESSING: {
    return {
      ...state,
      isProcessing: true,
    };
  }
  case types.IS_ERROR: {
    return {
      ...state,
      isLoading: false,
      isProcessing: false,
      isSendingMaintenanceEmail: false,
      isError: {
        status: true,
        message: action.data.message
      }
    };
  }
  case types.GET_LIST: {
    return {
      ...state,
      isLoading: false,
      data: action.data,
    };
  }
  case types.CREATE_UPDATE: {
    return {
      ...state,
      isProcessing: false,
      data: action.data,
    };
  }
  case types.SEND_MAINTENANCE_EMAIL_LOADING: {
    return {
      ...state,
      isSendingMaintenanceEmail: true,
    };
  }
  case types.SEND_MAINTENANCE_EMAIL_SUCCESS: {
    return {
      ...state,
      isSendingMaintenanceEmail: false,
    };
  }
  default:
    return state;
  }
};

export default reducers;
