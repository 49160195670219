import React,{Component} from 'react';
import {OwnIcon} from 'helper';
import { format} from 'date-fns';
import Calendar from 'react-calendar';

const month=['','January', 'February', 'March', 'Appril','May','June','July','August','September','October','November','December'];
class InputDateBox extends Component{
  constructor(props){
    super(props);
    this.state={
      isOpen: false,
      value: new Date(props.value),
      dateText: 'Please Select The Date',
    };
  }

  onChange=(event)=>{
    const {props} =this;
    event.stopPropagation();
    event.preventDefault();
    if(props.onChangeText){
      props.onChangeText(event.target.value);
    }
  }

  onCalendarChange=(value)=>{
    const newValue=format(value,'YYYY-M-DD');
    this.props.onChangeText(newValue);
    this.setState({
      value,
      dateText:format(newValue,'DD')+' '+month[format(newValue,'M')]+' '+format(newValue,'YYYY')
    });
  }

  onClickInput=(event)=>{
    const {props} =this;
    event.stopPropagation();
    event.preventDefault();
    if(props.isClicked){
      this.setState({isOpen:true});
      // if(onClick){
      //   openCalendar=true;
      // }
      //setCalendar(true);

      //console.log('klicked',openCalendar);
    }
  }

  getDateValue=()=>{
    return format(this.state.value,'DD')+' '+month[format(this.state.value,'M')]+' '+format(this.state.value,'YYYY');
  }

  render(){
    const {props,state} =this;
  
    return(
      <div className='dFlex justCenter dirColumn inputBoxContainer'>
        <label className='inputBoxTitle'>{props.title}</label>
        <div className='inputBoxInside softShadow dFlex justCenter' onClick={this.onClickInput}>

          {props.isClicked &&
            <div className='inputBox'>{props.value!==''?this.getDateValue():'Please Select Date'}</div>
          }
          {!props.isClicked &&
            <input type='text' value={props.value} className='inputBox' placeholder={props.placeholder} onChange={this.onChange} />
          }
          { props.icon &&
            props.icon
          }
          { !props.icon &&
            <OwnIcon.IconCalendar className='inputIcon' />
          }
        </div>
        { state.isOpen &&
          <div className='dFlex justCenter alignCenter callendarModal dirColumn' >
            <div className='callendarModalBackground'  onClick={()=>this.setState({isOpen:false})} ></div>
            <Calendar
              className='scale9 calendar'
              onChange={this.onCalendarChange}
              value={state.value}
            />
            <div className='scale9 calendarSelect'>{state.dateText}</div>
          </div>
        }
      </div>
    );
  }
}
InputDateBox.defaultProps={
  title:'',
  onClick:()=>null,
  onChangeText:()=>null,
  icon:null,
  value:'please fill it',
  placeholder:'please Fill it',
  isClicked:true,
};
export {InputDateBox};
