export default (state) => ({
  isLoading: state.configurations.isLoading,
  isProcessing: state.configurations.isProcessing,
  isSendingMaintenanceEmail: state.configurations.isSendingMaintenanceEmail,
  data: state.configurations.data,
  video: state.configurations.data.find(c => c.name === 'video'),
  infographics: state.configurations.data.find(c => c.name === 'infographics'),
  infographicsHod: state.configurations.data.find(c => c.name === 'infographics_hod'),
  infographicsLead: state.configurations.data.find(c => c.name === 'infographics_lead'),
  infographicsRecognition: state.configurations.data.find(c => c.name === 'infographics_recognition'),
  infographicsMenu: state.configurations.data.find(c => c.name === 'infographics_menu'),
  title: state.configurations.data.find(c => c.name === 'title'),
  content: state.configurations.data.find(c => c.name === 'content'),
  maintenanceStatus: state.configurations.data.find(c => c.name === 'maintenance_status'),
  maintenanceEnd: state.configurations.data.find(c => c.name === 'maintenance_end'),
  maintenanceMessage: state.configurations.data.find(c => c.name === 'maintenance_message'),
  profile: state.user.profile,
});
