import * as types from './action.types';

export const showSuccessAlert = (msg) => ({
  type: types.SHOW_SUCCESS_ALERT,
  data: msg,
});

export const closeSuccessAlert = () => ({
  type: types.HIDE_SUCCESS_ALERT
});

export const showErrorAlert = (msg) => ({
  type: types.SHOW_ERROR_ALERT,
  data: msg,
});

export const closeErrorAlert = () => ({
  type: types.HIDE_ERROR_ALERT
});

export const showLoadingProcess = () => ({
  type: types.SHOW_LOADING_PROCESS,
});

export const closeLoadingProcess = () => ({
  type: types.HIDE_LOADING_PROCESS,
});
