import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { format, isValid, isPast, parse } from 'date-fns';

import Input from 'components/inputs/base-input';
import TextArea from 'components/inputs/custom-text-area';
import Button from 'components/buttons/base-button/BaseButton';
import LoadingProcess from 'components/loadings/fullscreen-spin';
import ClosePrompt from 'components/alerts/prompt';

import dispatchers from './dispatchers';
import states from './states';
import styles from './styles';

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newVideo: '',
      newVideoError: false,
      newInfographics: '',
      newInfographicsError: false,
      newInfographicsHod: '',
      newInfographicsHodError: false,
      newInfographicsLead: '',
      newInfographicsLeadError: false,
      newInfographicsRecognition: '',
      newInfographicsRecognitionError: false,
      newInfographicsMenu: '',
      newInfographicsMenuError: false,
      newTitle: '',
      newTitleError: false,
      newContent: '',
      newContentError: false,
      newMaintenanceStatus: false,
      newMaintenanceEnd: '',
      newMaintenanceEndError: false,
      newMaintenanceMessage: '',
      newMaintenanceMessageError: false,
      showClosePrompt: false,
      nextLocation: '',
      dirty: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;

    this.handleGetData();

    this.unblock = history.block((nextLocation) => {
      if (this.state.dirty) {
        this.setState({
          showClosePrompt: true,
          nextLocation: nextLocation
        });
      }
      return !this.state.dirty;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.id !== prevProps.profile.id) {
      this.handleUserAuth();
    }
  }

  handleNavigate = (isTrue) => {
    const { history } = this.props;
    const { nextLocation } = this.state;

    this.setState({ dirty: false }, () => {
      if (isTrue) history.push(nextLocation.pathname);
      this.setState({ showClosePrompt: false });
    });
  }

  handleUserAuth = () => {
    const { history, profile } = this.props;
    const privilege = ['recognition_team'];
    if (!privilege.includes(profile.role)) return history.push('/');
  }

  handleGetData = () => {
    const { getConfigs } = this.props;

    getConfigs()
      .then(() => {
        const {
          video,
          infographics,
          infographicsHod,
          infographicsLead,
          infographicsMenu,
          infographicsRecognition,
          title,
          content,
          maintenanceStatus,
          maintenanceEnd,
          maintenanceMessage,
        } = this.props;

        this.setState({
          newVideo: video.value,
          newInfographics: infographics.value,
          newInfographicsHod: infographicsHod.value,
          newInfographicsLead: infographicsLead.value,
          newInfographicsRecognition: infographicsRecognition.value,
          newInfographicsMenu: infographicsMenu.value,
          newTitle: title.value,
          newContent: content.value,
          newMaintenanceStatus: maintenanceStatus.value === '0' ? false : true,
          newMaintenanceEnd: this.parseDateInput(maintenanceEnd.value),
          newMaintenanceMessage: maintenanceMessage.value,
        });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { createUpdateConfigs } = this.props;
    const {
      newVideo,
      newInfographics,
      newInfographicsHod,
      newInfographicsLead,
      newInfographicsRecognition,
      newInfographicsMenu,
      newTitle,
      newContent,
      newMaintenanceStatus,
      newMaintenanceEnd,
      newMaintenanceMessage,
    } = this.state;

    if (this.validateForm()) {
      const payload = {
        video: newVideo,
        infographics: newInfographics,
        infographicsHod: newInfographicsHod,
        infographicsLead: newInfographicsLead,
        infographicsRecognition: newInfographicsRecognition,
        infographicsMenu: newInfographicsMenu,
        title: newTitle,
        content: newContent,
        maintenanceStatus: newMaintenanceStatus,
        maintenanceEnd: newMaintenanceEnd,
        maintenanceMessage: newMaintenanceMessage,
      };

      this.resetForm();
      createUpdateConfigs(payload)
        .then(() => {
          this.handleGetData();
        });
    }
  }

  handleInput = (e, key) => {
    this.setState({
      [key]: e.target.value,
      dirty: true,
    }, () => {
      const value = this.state[key];
      this.validateInput(value, key);
    });
  }

  handleSendMaintenanceEmail = () => {
    this.props.sendMaintenanceEmail();
  }

  validateForm = () => {
    const {
      newVideo,
      newInfographics,
      newInfographicsHod,
      newInfographicsLead,
      newInfographicsRecognition,
      newInfographicsMenu,
      newTitle,
      newContent,
      newMaintenanceStatus,
      newMaintenanceEnd,
      newMaintenanceMessage,
    } = this.state;
    let valid = true;

    if (!this.validateInput(newVideo, 'newVideo')) valid = false;
    if (!this.validateInput(newInfographics, 'newInfographics')) valid = false;
    if (!this.validateInput(newInfographicsHod, 'newInfographicsHod')) valid = false;
    if (!this.validateInput(newInfographicsLead, 'newInfographicsLead')) valid = false;
    if (!this.validateInput(newInfographicsRecognition, 'newInfographicsRecognition')) valid = false;
    if (!this.validateInput(newInfographicsMenu, 'newInfographicsMenu')) valid = false;
    if (!this.validateInput(newTitle, 'newTitle')) valid = false;
    if (!this.validateInput(newContent, 'newContent')) valid = false;

    if (newMaintenanceStatus) {
      if (!this.validateInput(newMaintenanceMessage, 'newMaintenanceMessage')) valid = false;

      // Validate maintenance date
      if (!isValid(parse(newMaintenanceEnd)) || isPast(newMaintenanceEnd)) {
        this.setState({ newMaintenanceEndError: true });
        valid = false;
      } else {
        this.setState({ newMaintenanceEndError: false });
      }
    }

    return valid;
  }

  validateInput = (value, key) => {
    if (!value) {
      this.setState({ [`${key}Error`]: true });
      return false;
    } else {
      this.setState({ [`${key}Error`]: false });
      return true;
    }
  }

  resetForm = () => {
    this.setState({
      newVideoError: false,
      newInfographicsError: false,
      newInfographicsHodError: false,
      newInfographicsLeadError: false,
      newInfographicsRecognitionError: false,
      newInfographicsMenuError: false,
      newTitleError: false,
      newContentError: false,
      showClosePrompt: false,
      nextLocation: '',
      dirty: false,
    });
  }

  parseDateInput = (date) => {
    const split = date.split('-');
    if (split.length === 3) {
      return format(new Date(split[2], split[1] - 1, split[0]), 'YYYY-MM-DD');
    }
    return date;
  }

  render() {
    const { classes, isProcessing, isSendingMaintenanceEmail } = this.props;
    const {
      newVideo,
      newInfographics,
      newInfographicsHod,
      newInfographicsLead,
      newInfographicsRecognition,
      newInfographicsMenu,
      newTitle,
      newContent,
      newMaintenanceStatus,
      newMaintenanceEnd,
      newMaintenanceMessage,
      showClosePrompt,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.header}>
            <h3>Configuration</h3>
          </div>

          <ClosePrompt
            className='marB-20'
            open={showClosePrompt}
            toggleDialog={val => this.handleNavigate(val)}
            text="Are you sure want to close this window?"
            subText="(All draft will be lost)"
          />

          { isProcessing && <LoadingProcess /> }
          <form onSubmit={this.handleSubmit} className={classes.content}>
            <div className={classes.formControl}>
              <label htmlFor="url">Video url</label>
              <Input
                fullWidth={true}
                placeholder="https://"
                value={newVideo}
                onChange={e => this.handleInput(e, 'newVideo')}
              />
              { this.state.newVideoError && !newVideo &&
                <p className={classes.error}>This field is required!</p>
              }
            </div>
            
            <div className={classes.formControl}>
              <label htmlFor="urlMenu">Infographic Menu Label</label>
              <Input
                fullWidth={true}
                value={newInfographicsMenu}
                onChange={e => this.handleInput(e, 'newInfographicsMenu')}
              />
              { this.state.newInfographicsMenuError && !newInfographicsMenu &&
                <p className={classes.error}>This field is required!</p>
              }
            </div>

            <div className={classes.formControl}>
              <label htmlFor="url">Infographic url (Employee)</label>
              <Input
                fullWidth={true}
                placeholder="https://"
                value={newInfographics}
                onChange={e => this.handleInput(e, 'newInfographics')}
              />
              { this.state.newInfographicsError && !newInfographics &&
                <p className={classes.error}>This field is required!</p>
              }
            </div>

            <div className={classes.formControl}>
              <label htmlFor="url">Infographic url (HOD)</label>
              <Input
                fullWidth={true}
                placeholder="https://"
                value={newInfographicsHod}
                onChange={e => this.handleInput(e, 'newInfographicsHod')}
              />
              { this.state.newInfographicsHodError && !newInfographicsHod &&
                <p className={classes.error}>This field is required!</p>
              }
            </div>

            <div className={classes.formControl}>
              <label htmlFor="url">Infographic url (Team Lead)</label>
              <Input
                fullWidth={true}
                placeholder="https://"
                value={newInfographicsLead}
                onChange={e => this.handleInput(e, 'newInfographicsLead')}
              />
              { this.state.newInfographicsLeadError && !newInfographicsLead &&
                <p className={classes.error}>This field is required!</p>
              }
            </div>

            <div className={classes.formControl}>
              <label htmlFor="url">Infographic url (Recognition Team)</label>
              <Input
                fullWidth={true}
                placeholder="https://"
                value={newInfographicsRecognition}
                onChange={e => this.handleInput(e, 'newInfographicsRecognition')}
              />
              { this.state.newInfographicsRecognitionError && !newInfographicsRecognition &&
                <p className={classes.error}>This field is required!</p>
              }
            </div>

            <div className={classes.formControl}>
              <label htmlFor="url">Homepage block</label>
              <Input
                fullWidth={true}
                placeholder="Insert title here"
                value={newTitle}
                onChange={e => this.handleInput(e, 'newTitle')}
              />
              { this.state.newTitleError && !newTitle &&
                <p className={classes.error}>This field is required!</p>
              }
              <TextArea
                placeholder="Description"
                value={newContent}
                rows={6}
                maxLength={900}
                onChange={e => this.handleInput(e, 'newContent')}
              />
              { this.state.newContentError && !newContent &&
                <p className={classes.error}>This field is required!</p>
              }
            </div>

            <hr />

            <div className={classes.formControl}>
              <label htmlFor="maintenance">Maintenance Mode</label>

              <div className={classes.maintenanceRow}>
                <label>
                  <small>Active</small>
                </label>
                <Switch
                  checked={newMaintenanceStatus}
                  onChange={e => {
                    this.setState({
                      newMaintenanceStatus: e.target.checked,
                    });
                  }}
                  color="primary"
                />
              </div>

              <div className={classes.maintenanceRow}>
                <label>
                  <small>End Date</small>
                </label>
                <div>
                  <TextField
                    type="date"
                    value={newMaintenanceEnd}
                    onChange={e => {
                      this.setState({
                        newMaintenanceEnd: e.target.value,
                        newMaintenanceEndError: false,
                      });
                    }}
                    disabled={!newMaintenanceStatus}
                  />
                  { this.state.newMaintenanceEndError &&
                    <p className={classes.error}>Invalid date!</p>
                  }
                </div>
              </div>

              <div style={{ marginTop: 12 }}>
                <label>
                  <small>Message</small>
                </label>
                <div style={{ marginTop: 12 }}>
                  <TextArea
                    placeholder="Maintenance message"
                    value={newMaintenanceMessage}
                    rows={6}
                    maxLength={900}
                    onChange={e => this.handleInput(e, 'newMaintenanceMessage')}
                    disabled={!newMaintenanceStatus}
                  />
                  { this.state.newMaintenanceMessageError && !newMaintenanceMessage &&
                    <p className={classes.error}>This field is required!</p>
                  }
                </div>
              </div>

              <div style={{ marginTop: 12 }}>
                <Button
                  label={isSendingMaintenanceEmail ? 'Sending Email' : 'Send Maintenance Email'}
                  size="large"
                  fullWidth={true}
                  onClick={this.handleSendMaintenanceEmail}
                  disabled={isSendingMaintenanceEmail || !newMaintenanceStatus}
                />
              </div>
            </div>

            <div className={classes.formActions}>
              <Button
                type="submit"
                label="Apply"
                size="large"
                fullWidth={true}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Config.propTypes = {
  classes: PropTypes.object.isRequired,
  getConfigs: PropTypes.func,
  createUpdateConfigs: PropTypes.func,
  sendMaintenanceEmail: PropTypes.func,
  isLoading: PropTypes.bool,
  isProcessing: PropTypes.bool,
  isSendingMaintenanceEmail: PropTypes.bool,
  video: PropTypes.object,
  infographics: PropTypes.object,
  infographicsHod: PropTypes.object,
  infographicsLead: PropTypes.object,
  infographicsRecognition: PropTypes.object,
  infographicsMenu: PropTypes.object,
  title: PropTypes.object,
  content: PropTypes.object,
  maintenanceStatus: PropTypes.object,
  maintenanceEnd: PropTypes.object,
  maintenanceMessage: PropTypes.object,
  profile: PropTypes.object,
  history: PropTypes.object,
  router: PropTypes.object,
  route: PropTypes.object,
  data: PropTypes.array,
};

export default compose(
  withStyles(styles),
  connect(states, dispatchers),
)(Config);
