import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import iconHome from 'assets/icons/menu-home.svg';
import iconReceived from 'assets/icons/menu-card-received.svg';
import iconSent from 'assets/icons/menu-card-sent.svg';
import iconCategory from 'assets/icons/category.svg';
import iconSummary from 'assets/icons/menu-data-summary.svg';
import iconEmployee from 'assets/icons/menu-employee.svg';
import iconLogout from 'assets/icons/menu-logout.svg';
import iconBackup from 'assets/icons/backup-menu.svg';
import iconAppreciation from 'assets/icons/appreciation-menu.svg';
import iconPending from 'assets/icons/menu-pending-notif.svg';
import iconAAA from 'assets/icons/love-dark.svg';
import iconSSA from 'assets/icons/bintang-dark.svg';
import iconConfig from 'assets/icons/configuration.svg';
import iconInfographics from 'assets/icons/infographics.svg';
import iconProfile from 'assets/icons/man-user.svg';
import iconNotif from 'assets/icons/bintang.svg';

import { logout } from 'store/user/actions';
import styles from './styles';

class AppDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appDrawerLink: [
        { name: 'Card Sent', to: '/card/sent', icon: iconSent, privilege: 'all' },
        { name: 'Card Received', to: '/card/received', icon: iconReceived, privilege: 'all' },
        // { name: 'Pending Approval', to: '/card/pending', icon: iconPending, privilege: ['hod', 'ceo'] },
        { name: 'Data Summary', to: '/summary', icon: iconSummary, privilege: 'recognition_team' },
        { name: 'Category', to: '/category', icon: iconCategory, privilege: 'recognition_team' },
        { name: 'Employee', to: '/employee', icon: iconEmployee, privilege: 'recognition_team' },
        { name: 'Backup Data', to: '/backup', icon: iconBackup, privilege: 'recognition_team' },
        { name: 'Configuration', to: '/config', icon: iconConfig, privilege: 'recognition_team' },
        { name: 'Notification', to: '/notification', icon: iconNotif, privilege: 'all' },
      ],
      colAppreciation: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { history, isLogin } = this.props;

    if (!isLogin && prevProps.isLogin) history.push('/sign-in');
  }

  toggleCollapse = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  }

  handleHowToNavigate = () => {
    const {
      profile,
      infographics,
      infographicsHod,
      infographicsLead,
      infographicsRecognition,
    } = this.props;

    switch (profile.role) {
    case 'ceo': return window.open(infographicsHod.value, '_blank');
    case 'hod': return window.open(infographicsHod.value, '_blank');
    case 'lead': return window.open(infographicsLead.value, '_blank');
    case 'recognition_team': return window.open(infographicsRecognition.value, '_blank');
    default: return window.open(infographics.value, '_blank');
    }
  };

  menuPrivilege = (link) => {
    const { profile } = this.props;

    if (link.privilege === profile.role) return true;
    if (link.privilege.includes(profile.role)) return true;
    if (link.privilege === 'all') return true;
    return false;
  };

  render() {
    const {
      classes,
      open,
      toggleDrawer,
      logout,
      infographicsMenu,
    } = this.props;
    const {
      colAppreciation,
      appDrawerLink,
    } = this.state;

    return (
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        onClose={toggleDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={toggleDrawer} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </div>
        <List component="nav" className={classes.drawerMenu}>
          <Link to="/" onClick={toggleDrawer}>
            <ListItem button>
              <ListItemIcon className={classes.menuIcon}>
                <img src={iconHome} alt="link"/>
              </ListItemIcon>
              <ListItemText
                className={classes.menuText}
                primary="Home"
              />
            </ListItem>
          </Link>

          <Link to="/profile" onClick={toggleDrawer}>
            <ListItem button>
              <ListItemIcon className={classes.menuIcon}>
                <img src={iconProfile} alt="link"/>
              </ListItemIcon>
              <ListItemText
                className={classes.menuText}
                primary="Profile"
              />
            </ListItem>
          </Link>

          { this.menuPrivilege({ privilege: ['hod', 'ceo'] }) &&
            <Link to="/card/pending" onClick={toggleDrawer}>
              <ListItem button>
                <ListItemIcon className={classes.menuIcon}>
                  <img src={iconPending} alt="link"/>
                </ListItemIcon>
                <ListItemText
                  className={classes.menuText}
                  primary="Pending Approval"
                />
              </ListItem>
            </Link>
          }

          <ListItem button onMouseEnter={() => this.toggleCollapse('colAppreciation')}>
            <ListItemIcon className={classes.menuIcon}>
              <img src={iconAppreciation} alt="link"/>
            </ListItemIcon>
            <ListItemText
              className={classes.menuText}
              primary="Appreciation"
            />
          </ListItem>
          <Collapse
            in={colAppreciation}
            timeout="auto"
            unmountOnExit
            onMouseLeave={
              () => this.toggleCollapse('colAppreciation')
            }
          >
            <Link to="/appreciation/all-around" onClick={toggleDrawer}>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon className={classes.menuIcon}>
                    <img src={iconAAA} alt="link"/>
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuText}
                    primary="All-Around Appreciation"
                  />
                </ListItem>
              </List>
            </Link>
            { 
              this.menuPrivilege({ privilege: ['lead', 'hod', 'recognition_team', 'ceo'] }) &&
              <Link to="/appreciation/shining-star" onClick={toggleDrawer}>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon className={classes.menuIcon}>
                      <img src={iconSSA} alt="link"/>
                    </ListItemIcon>
                    <ListItemText
                      className={classes.menuText}
                      primary="Shining Star Appreciation"
                    />
                  </ListItem>
                </List>
              </Link>
            }
          </Collapse>

          { 
            appDrawerLink.map(link => this.menuPrivilege(link) ? (
              <Link to={link.to} onClick={toggleDrawer} key={link.name}>
                <ListItem button>
                  <ListItemIcon className={classes.menuIcon}>
                    <img src={link.icon} alt="link"/>
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuText}
                    primary={link.name}
                  />
                </ListItem>
              </Link> ) : null)
          }

          {
            infographicsMenu && infographicsMenu.value &&
            <ListItem button onClick={() => this.handleHowToNavigate()}>
              <ListItemIcon className={classes.menuIcon}>
                <img src={iconInfographics} alt="link"/>
              </ListItemIcon>
              <ListItemText
                className={classes.menuText}
                primary={infographicsMenu.value}
              />
            </ListItem>
          }

          <Divider className={classes.divider} />
          <ListItem onClick={logout} button>
            <ListItemIcon className={classes.menuIcon}>
              <img src={iconLogout} alt="link"/>
            </ListItemIcon>
            <ListItemText
              className={classes.menuText}
              primary="Log out"
            />
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

AppDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  logout: PropTypes.func,
  profile: PropTypes.object,
  isLogin: PropTypes.bool,
  infographics: PropTypes.object,
  infographicsHod: PropTypes.object,
  infographicsLead: PropTypes.object,
  infographicsRecognition: PropTypes.object,
  infographicsMenu: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isLogin: state.user.isLogin,
  profile: state.user.profile,
  infographics: state.configurations.data.find(c => c.name === 'infographics'),
  infographicsHod: state.configurations.data.find(c => c.name === 'infographics_hod'),
  infographicsLead: state.configurations.data.find(c => c.name === 'infographics_lead'),
  infographicsRecognition: state.configurations.data.find(c => c.name === 'infographics_recognition'),
  infographicsMenu: state.configurations.data.find(c => c.name === 'infographics_menu'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  logout,
}, dispatch);

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AppDrawer);
