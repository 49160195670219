const styles = (theme) => ({
  root: {
    backgroundColor: '#FFFFFF'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
  },
  header: {
    padding: '1.3rem 0',
    color: theme.palette.primary.main,
    '& h3': {
      margin: 0,
      fontWeight: 700,
    }
  },
  content: {
    padding: '1rem 0',
    width: '80%',
    minHeight: '90vh',
  },
  card: {
    minHeight: '8rem',
    margin: '.6rem 0',
    padding: '1rem 1rem',
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    '& img': {
      width: 120,
      marginBottom: '1rem',
    },
    '& h1': {
      margin: 0,
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    '& h3': {
      color: theme.palette.primary.main,
    },
    '& p': {
      margin: 0,
      fontWeight: 300,
      fontSize: '.9rem',
      color: theme.palette.primary.text,
      marginBottom: '1rem',
      lineHeight: '1.4rem',
      whiteSpace: 'pre-wrap',
    },
  },
  cardTitle: {
    marginBottom: '2.4rem',
  },
  cardContent: {
    marginBottom: '2.4rem',
  },
  subtitle: {
    color: theme.palette.secondary.dark + '!important',
    fontWeight: 100 + '!important',
  },
  categoryItem: {
    borderRadius: 0,
    textTransform: 'none',
    background: '#EEEEEE',
    position: 'relative',
    overflowY: 'hidden',
    padding: '.8rem',
    textAlign: 'center',
    '& h4': {
      margin: 0,
      color: '#FFFFFF',
      position: 'relative',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
      zIndex: 1,
    },
    '& img': {
      position: 'absolute',
      objectFit: 'center',
      width: '100%',
      transform: 'translateY(-30px)',
      filter: 'brightness(70%)',
    }
  },
  btnYellow: {
    backgroundColor: '#ffcc00',
    color: '#19469b',
    margin: '2px 0',
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#f6c500',
    }
  },
  label: {
    fontWeight: 600
  },
});

export default styles;
