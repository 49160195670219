import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    // margin: '.4rem 0',
    padding: '6px 10px',
    borderRadius: 2,
    '& input': {
      fontSize: '.8rem',
    },
  },
});

const CustomInput = props => {
  const { classes, fullWidth, placeholder, value, onChange, type, disabled } = props;

  return (
    <TextField
      className={classes.root}
      fullWidth={fullWidth}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      InputProps={{ disableUnderline: true }}
      type={type}
      disabled={disabled}
    />
  );
};

CustomInput.defaultProps={
  disabled: false
};

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.any,
  disabled: PropTypes.bool
};

export default withStyles(styles)(CustomInput);
