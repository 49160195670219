import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BaseDialog from 'components/dialogs/base-dialog';
import successSvg from 'assets/icons/check-mark-round.svg';
import { closeSuccessAlert } from 'store/commons/actions';

import './custom.css';

const SuccessAlert = (props) => {
  const { showSuccess, closeSuccessAlert, successMsg } = props;

  return (
    <BaseDialog open={showSuccess} toggleDialog={closeSuccessAlert}>
      <div style={{ padding: '1rem', textAlign: 'center' }}>
        <img
          id="pulseSuccess"
          style={{ width: '5rem', margin: '1rem' }}
          src={successSvg}
          alt="icon"
        />
        <h3 style={{ textAlign: 'center', color: '#4D4D4D' }}>
          {successMsg}
        </h3>
      </div>
    </BaseDialog>
  );
};

SuccessAlert.defaultProps = {
  successMsg: 'Succesful!',
  showSuccess: false,
  closeSuccessAlert: () => null,
};

SuccessAlert.propTypes = {
  successMsg: PropTypes.string,
  showSuccess: PropTypes.bool,
  closeSuccessAlert: PropTypes.func,
};

const mapStateToProps = (state) => ({
  showSuccess: state.commons.showSuccess,
  successMsg: state.commons.successMsg,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeSuccessAlert,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuccessAlert);
