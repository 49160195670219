const styles = (theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    '& a': {
      fontSize: '.8rem',
      fontWeight: 700,
      color: '#0097A7',
    }
  },
  header: {
    position: 'relative',
    overflowY: 'hidden',
  },
  headerCover: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    filter: 'brightness(70%)',
    objectFit: 'cover',
  },
  headerProfile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.6rem 0',
    position: 'relative',
    zIndex: 1,
    '& h3': {
      margin: 0,
      marginBottom: '.2rem',
      fontWeight: 400,
      color: '#FFFFFF',
    },
    '& h6': {
      margin: 0,
      marginBottom: '1rem',
      fontWeight: 700,
      color: theme.palette.text.dark,
    }
  },
  pageName: {
    '& h3': {
      color: '#FFFFFF',
      fontWeight: 700,
    }
  },
  avatar: {
    margin: '1.2rem',
    width: 72,
    height: 72,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    '& h3': {
      color: theme.palette.primary.main,
    }
  },
  card: {
    maxWidth: 380,
    minHeight: '8rem',
    margin: '.6rem 0',
    padding: '1rem 1rem',
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    '& img': {
      width: 72,
      position: 'absolute',
      top: 0,
      right: 0,
    },
    '& h1': {
      margin: 0,
      color: theme.palette.primary.main,
      fontWeight: 100,
    },
    '& h3': {
      color: theme.palette.primary.main,
    },
    '& p': {
      margin: 0,
      fontWeight: 400,
      fontSize: '.9rem',
      color: theme.palette.primary.text,
      marginBottom: '1rem',
    },
  },
  subtitle: {
    margin: 0,
    marginBottom: '1.6rem',
    color: theme.palette.text.main,
    fontWeight: 300,
    fontStyle: 'italic',
  },
  categoryItem: {
    borderRadius: 0,
    textTransform: 'none',
    background: '#EEEEEE',
    position: 'relative',
    overflowY: 'hidden',
    padding: '.8rem',
    textAlign: 'center',
    '& h4': {
      margin: 0,
      color: '#FFFFFF',
      position: 'relative',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
      zIndex: 1,
    },
    '& img': {
      position: 'absolute',
      objectFit: 'center',
      width: '100%',
      transform: 'translateY(-30px)',
      filter: 'brightness(70%)',
    }
  }
});

export default styles;
