import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = () => ({
  button: {
    textTransform: 'none',
    margin: '2px 0',
    borderRadius: 0,
    boxShadow: 'none'
  },
});

const BaseButton = (props) => {
  const {
    classes,
    type,
    variant,
    label,
    color,
    fullWidth,
    onClick,
    size,
    style,
    buttonClass,
    labelClass,
    disabled,
  } = props;

  return (
    <Button
      className={buttonClass || classes.button}
      classes={{ label: labelClass }}
      type={type}
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      onClick={onClick}
      size={size}
      style={style}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

BaseButton.defaultProps = {
  type: 'button',
  variant: 'contained',
  color: 'primary', 
  size: 'medium',
};

BaseButton.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonClass: PropTypes.string,
  labelClass: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(BaseButton);
